import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useHistory, useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useMediaQuery } from "react-responsive";
import Chip from '@mui/material/Chip';
import $ from "jquery";
import PlacesAutocomplete from "react-places-autocomplete";
import InputGroup from "react-bootstrap/InputGroup";
import TooltipMui from "@mui/material/Tooltip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import "./NewFoodListPage.css";
import { connect } from "react-redux";
import Divider from "@mui/material/Divider";
import Select from "react-select";
import {
  getCharges,
  setProductList,
  checkout,
  setCustome,
  getCustomData,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
  setCartId,
} from "../../Store";
import moment from 'moment-timezone';
import api from "../../CommonApi/axios";
// import SideBar from "../../Comman/SideBar";
import ButtonMUi from "@mui/material/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RestrictedMenuPopup from "./RestrictedMenuPopup";
import ProductSideSvg from "./ProductSideSvg";
import MenuNameCommon from "./Common/MenuNameCommon";
import SliderLeftSvg from "./Common/SliderLeftSvg";
import SliderRightSvg from "./Common/SliderRightSvg";
import CenterSilder from "./Common/CenterSilder";
import { showSuccessNotification, showSuccessNotificationCustome } from "../CommonComponet/Swal/Swal";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import Footer from "../../Comman/Footer";
import { applyFilterMeHandlers } from "../CommonComponet/jqueryUtils/jqueryUtils";
import { propertyImages } from "../CommonComponet/commonfunction";
import { colourStylesHeader } from "./Common/selectDropDown";
import { getCurrencyLabel } from "../CommonComponet/currencyUtils/currencyUtils";
import DescriptionToggleProdcut from "./DescriptionToggle";
import BranchDetailsModel from "./BranchDetailsModel";
import { googleMapsApiKey } from "../../CommonApi/googelApiKey";
import Checkbox from '@mui/material/Checkbox';
import DescriptionToggleProdcutMobile from "./DescriptionToggleProdcutMobile";
import { Link } from "material-ui-core";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


window.jquery = window.$ = $;


function NewFoodListPage(props) {

  // useEffect(() => {
  //   applyFilterMeHandlers(); 
  // }, []);

  let { search } = useLocation();
  const [Delivery, setDelivery] = React.useState(-1);
  const [Pickup, setPickup] = React.useState(-1);
  // console.log("Delivery" ,Delivery , "Pickup" ,Pickup);

  const query = new URLSearchParams(search);
  const TableNo = query.get("no");
  const sessionId = query.get("sessionId");
  const history = useHistory();

  const item_value = JSON.parse(sessionStorage.getItem("items"));
  const Delivery_detailes = JSON.parse(sessionStorage.getItem("Delivery_detailes"));
  const Pickup_detailes = JSON.parse(sessionStorage.getItem("Pickup_detailes"));
  const [BranchDetails_, setBranchDetails_] = useState("");

  useEffect(() => {
    if (Delivery_detailes) {
      const updatedItems = { ...item_value, ...Delivery_detailes };
      sessionStorage.setItem("items", JSON.stringify(updatedItems));
      sessionStorage.removeItem("Delivery_detailes");
      sessionStorage.removeItem("Pickup_detailes");
    }
  }, [Delivery_detailes]);

  useEffect(() => {
    if (Pickup_detailes) {
      const updatedItems = { ...item_value, ...Pickup_detailes };
      sessionStorage.setItem("items", JSON.stringify(updatedItems));
      sessionStorage.removeItem("Delivery_detailes");
      sessionStorage.removeItem("Pickup_detailes");
    }
  }, [Pickup_detailes]);

  const cart_id = sessionStorage.getItem("cart_id");
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const isTablet = useMediaQuery({ minWidth: 761, maxWidth: 1000 });

  const { poId } = useParams();
  const { tableId } = useParams();



  var pathname = window.location.href;
  var url = pathname.replace(/(https?:\/\/)?(www.)?/i, "");

  var urlBranchID = decodeURIComponent(poId);

  // console.log("urlBranchID", urlBranchID)
  var urlTableId = String(tableId);

  const {
    language,
    setBranchId,
    customRes,
  } = props;

  // console.log("customRes" ,customRes)

  let sessionBranchID = sessionStorage.getItem("sessionBranchID");
  let is_restricted = sessionStorage.getItem("is_restricted");
  const QrCodeScanAndRedirect = sessionStorage.getItem("QrCodeScanAndRedirect");
  // console.log("QrCodeScanAndRedirect" ,QrCodeScanAndRedirect)

  const [BranchID, setBranchID_] = useState(sessionBranchID);
  const [MenuList, SetMenuList] = useState([]);
  const [MenuName, setMenuName] = useState("");
  const [order_notes, setorder_notes] = useState("");
  const [ID_Featured, SetID_Featured] = useState("");

  // console.log(MenuList)
  let placingDate = sessionStorage.getItem("placingDate");
  let placingTime = sessionStorage.getItem("placingTime");

  useEffect(() => {
    // if(placingDate == null){
    api
      .get(`/client/order/getBranchId/${urlBranchID}`)
      .then((res) => {
        setBranchID_(res?.data?.data?._id);
        sessionStorage.setItem("sessionBranchID", res?.data?.data?._id);
        sessionStorage.setItem("BranchTimeZone", res?.data?.data?.branchTimeZone);
        // if(placingDate == null){
        const now = new Date().toLocaleString('en-US', { timeZone: res?.data?.data?.branchTimeZone });
        sessionStorage.setItem("placingDate", moment(now).format("YYYY-MM-DD"));
        sessionStorage.setItem("placingTime", moment(now).format('HH:mm'));
        // }
        // console.log("tabals", res?.data?.data);
        setMenuName(res?.data?.data);
        // setbranc_not(res?.data?.data == 0 ? true : false);
      })
      .catch((err) => {
        // console.log(err?.response?.data?.error?.message);
        if (err.response?.data?.error?.message === undefined || err.response?.data?.error?.message) {
          showSuccessNotificationCustome("We're sorry, but orders cannot be placed at this time as the branch is currently inactive.", history)
        }
      });
    // }
  }, [urlBranchID]);

  const [DayAndDate, setDayAndDate] = useState("")

  useEffect(() => {
    api
      .get(`/client/order/pickup/${BranchID}`)
      .then((res) => {
        // console.log("sss", res?.data?.data[0])
        setDayAndDate(res?.data?.data[0])
      })
      .catch((err) => {
        console.log(err?.response?.data?.error?.message);
      });
  }, [BranchID]);



  const currencyLabel = getCurrencyLabel(MenuName && MenuName?.currency);

  const [Loader, SetLoader] = useState(false);

  useEffect(() => {
    SetLoader(false)
    if (BranchDetails_?.resData?.is_restricted === false) {
      if (BranchID !== null && is_restricted === null) {
        let item = {
          branch_id: BranchID,
          type: ""
        };
        api
          .post(`/client/order/menulist`, item)
          .then((res) => {
            // console.log("tabel" , res?.data?.data)
            SetMenuList(res?.data?.data?.length > 0 ? res?.data?.data : []);
            SetLoader(res?.data?.data ? res?.data?.data?.filter(word => word?.products?.length > 0) ? true : false : false);
            // const data = res?.data?.data;
            if (!res.data.data || res?.data?.data.length == 0) {
              SetLoader(true);
              showSuccessNotificationCustome("Apologies, Currently under maintenance. Please check after sometime.", history)
            }
            const result = res?.data?.data?.filter((word) =>
              word?.is_featured ? word : ""
            );
            SetID_Featured(result?.length > 0 ? result : "");
          })
          .catch((err) => {
            SetLoader(false);
            console.log(err.response?.data?.error?.message);
          });
      }
    }
  }, [BranchID !== null && BranchDetails_?.resData?.is_restricted === false]);

  /////////////////// Dine Flow Menulist //////////////////////
  useEffect(() => {
    SetLoader(false)
    if (BranchID !== null && TableNo) {
      let item = {
        branch_id: BranchID,
        type: "dineIn"
      };
      api
        .post(`/client/order/menulist`, item)
        .then((res) => {
          // console.log("tabel" , res?.data?.data)
          SetMenuList(res?.data?.data?.length > 0 ? res?.data?.data : []);
          SetLoader(res?.data?.data ? res?.data?.data?.filter(word => word?.products?.length > 0) ? true : false : false);
          // const data = res?.data?.data;
          if (!res.data.data || res?.data?.data.length == 0) {
            SetLoader(true);
            showSuccessNotificationCustome("Apologies, Currently under maintenance. Please check after sometime.", history);
          }
          const result = res?.data?.data?.filter((word) =>
            word?.is_featured ? word : ""
          );
          SetID_Featured(result?.length > 0 ? result : "");
        })
        .catch((err) => {
          SetLoader(false);
          console.log(err.response?.data?.error?.message);
        });
    }
  }, [BranchID !== null && TableNo]);
  /////////////////// Dine Flow Menulist //////////////////////

  // console.log("MenuList" ,MenuList)

  useEffect(() => {
    SetLoader(false)
    if (placingDate !== null && is_restricted === "true") {
      const time24Hour = moment(item_value?.order_time, 'h:mm A').format('HH:mm');
      let item = {
        branch_id: BranchID,
        deliveryTime: time24Hour,
        userSelectOrderDate: moment(item_value?.order_date).format("YYYY-MM-DD"),
        placingDate: placingDate,
        placingTime: placingTime,
        type: item_value?.order_type,
      };
      // console.log("item" ,item);
      // return
      api
        .post(`/client/order/menulist1`, item)
        .then((res) => {
          // console.log("tabel" , res?.data?.data)
          SetMenuList(res?.data?.data?.length > 0 ? res?.data?.data : []);
          SetLoader(res?.data?.data ? res?.data?.data?.filter(word => word?.products?.length > 0) ? true : false : false);

          const result = res?.data?.data?.filter((word) =>
            word?.is_featured ? word : ""
          );
          SetID_Featured(result?.length > 0 ? result : "")

          if (!res.data.data || res?.data?.data.length == 0) {
            SetLoader(true);
            showSuccessNotificationCustome("Apologies, Currently under maintenance. Please check after sometime.", history)
          }
        })
        .catch((err) => {
          SetLoader(false);
          console.log(err.response?.data?.error?.message);
        });
    }
  }, [placingDate !== null && is_restricted === "true"]);
  ////// Is Restre time menu list call ////////

  ///////Redirect form qrcode /////////////////////
  useEffect(() => {
    SetLoader(false)
    // if(QrCodeScanAndRedirect){
    if (placingDate !== null && QrCodeScanAndRedirect) {

      const time24Hour = moment(item_value?.order_time, 'h:mm A').format('HH:mm');
      let item = {
        branch_id: BranchID,
        deliveryTime: time24Hour,
        userSelectOrderDate: moment(item_value?.order_date).format("YYYY-MM-DD"),
        placingDate: placingDate,
        placingTime: placingTime,
        type: item_value?.order_type
      };
      // console.log("item" ,item);
      api
        .post(`/client/order/menulist1`, item)
        .then((res) => {
          // console.log("tabel" , res?.data?.data)
          SetMenuList(res?.data?.data ? res?.data?.data : []);
          SetLoader(res?.data?.data ? res?.data?.data?.filter(word => word?.products?.length > 0) ? true : false : false);

          const result = res?.data?.data?.filter((word) =>
            word?.is_featured ? word : ""
          );
          SetID_Featured(result?.length > 0 ? result : "")

          if (!res.data.data || res?.data?.data.length === 0) {
            SetLoader(true);
            showSuccessNotificationCustome("Apologies, Currently under maintenance. Please check after sometime.", history);
          }
        })
        .catch((err) => {
          SetLoader(false);
          console.log(err.response?.data?.error?.message);
        });
    }
    // }
  }, [placingDate !== null && QrCodeScanAndRedirect]);
  ///////Redirect form qrcode /////////////////////

  useEffect(() => {
    setBranchId(BranchID);
    // getMenuList();
    GetBranchDetails();
  }, [BranchID]);



  // console.log("TableNo" ,TableNo)
  function GetBranchDetails() {
    if (!TableNo) {
      if (BranchID) {
        api.get(`/client/order/branchDetails/${BranchID}`).then((res) => {
          const tableData = res.data.data;
          // console.log("ss" ,tableData)
          setBranchDetails_(tableData);
          setDelivery(tableData?.is_delivery === true ? 0 : -1);
          setPickup(tableData?.is_pickup === true ? 1 : -1);
        }).catch((err) => {
          if (err?.response) {
            if (err?.response?.data?.error?.message === "This branch is not active.") {
              showSuccessNotificationCustome("We're sorry, but orders cannot be placed at this time as the branch is currently inactive.", history)
            }
            // console.log("It's an array");
          }
        });
      }
    }
  }


  const DaynamicMood = item_value !== null ? item_value?.order_type ? item_value?.order_type : "" : ""

  const [ID, setID] = useState(MenuList[0]?.category_name_EN ? MenuList[0]?.category_name_EN : '');
  const [Index, SetIndex] = useState(0);
  const [Mood, setMood] = useState("");
  const [show, setShow] = useState(false);
  // console.log("mood" ,Mood)
  const [Product_detaisBy_id, setProduct_detaisBy_id] = useState("");
  const [totalCost, setTotalCost] = useState(100);
  const [quantity, setQuantity] = useState(1);
  const [ProductId_, setProductId_] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [pincode, setpincode] = useState("");
  const [address_line1, setaddress_line1] = useState("");
  const [address_line2, setaddress_line2] = useState("");
  const [errorpromo, setErrorPromo] = useState(null);
  const errorDivpromo = errorpromo ? errorpromo : "";
  const [shoeMoredescription, setshoeMoredescription] = useState(false);
  const [delivery_order_date, setdelivery_order_date] = useState("");
  const [delivery_order_dateA, setdelivery_order_dateA] = useState("");
  const [delivery_order_time, setdelivery_order_time] = useState("");
  const [delivery_order_timeA, setdelivery_order_timeA] = useState("");
  const [error, setError] = useState(null);
  const errorDiv = error ? (
    <div className="text-center alert alert-danger">{error}</div>
  ) : (
    ""
  );

  const [pickup_order_date, setpickup_order_date] = useState("");
  const [pickup_order_dateA, setpickup_order_dateA] = useState("");
  const [pickup_order_time, setpickup_order_time] = useState("");
  const [pickup_order_timeA, setpickup_order_timeA] = useState("");
  const [oldindex, setOldIndex] = useState(0);


  useEffect(() => {
    if (item_value && Mood === "") {
      setMood(item_value.order_type || "");
      setaddress_line1(item_value.address_line1 || "");
      setaddress_line2(item_value.address_line2 || "");
      setdelivery_order_dateA(item_value.delivery_order_dateA || "");
      setdelivery_order_timeA(item_value.delivery_order_timeA || "");
      setpickup_order_dateA(item_value.pickup_order_dateA || "");
      setpickup_order_timeA(item_value.pickup_order_timeA || "");
    }
  }, [item_value && Mood === ""]);

  useEffect(() => {
    if (BranchDetails_?.is_delivery === false) {
      setMood("pickup");
    } if (BranchDetails_?.is_pickup === false) {
      setMood("delivery");
    }
  }, [BranchDetails_]);
  // console.log("BranchDetails_" ,BranchDetails_)


  function calculateScrollPosition(Index) {
    const headerHeight = document?.querySelector("#kt_header")?.offsetHeight;
    const activeElement = $(".book")?.eq(Index);
    if (!activeElement) return;

    const left = activeElement.position()?.left;
    const currScroll = $(".cont")?.scrollLeft();
    const contWidth = $(".cont")?.width() / 2 + 10;
    const activeOuterWidth = activeElement?.outerWidth() / 2;
    const scrollLeft = left + currScroll - contWidth + activeOuterWidth;
    $(".cont").animate({
      scrollLeft: scrollLeft,
    });
  }

  useEffect(() => {
    let scrolling = false;

    function debounce(func, wait) {
      var timeout;
      return function () {
        var context = this,
          args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
          func.apply(context, args);
        }, wait);
      };
    }

    function handleScroll() {
      if (!scrolling) {
        scrolling = true;

        const allElements = document.querySelectorAll(".scroll_section");
        const headerHeight =
          document?.querySelector("#kt_header")?.offsetHeight;

        allElements.forEach((element) => {
          if (
            element.offsetTop -
            (headerHeight + 30) +
            document.body.getBoundingClientRect().top <
            0 &&
            element.clientHeight +
            element.offsetTop +
            document.body.getBoundingClientRect().top >
            0
          ) {
            activeBook($(element).index());
          }
        });

        scrolling = false;
      }
    }

    $(window).scroll(debounce(handleScroll, 30));

    if (ID) {
      const headerHeight = document?.querySelector("#kt_header")?.offsetHeight;
      const targetOffset = $(ID)?.offset()?.top - headerHeight;
      $("html, body")?.animate({ scrollTop: targetOffset });
    }

    let oldindex = -1;

    function activeBook(Index) {
      if (oldindex !== Index) {
        $(".cont")
          .stop()
          .animate({
            scrollLeft: calculateScrollPosition(Index),
          });
      }

      setOldIndex(Index);

      $(".book").removeClass("active");
      $(".button_simpal")?.removeClass("button_");
      $(".book")?.eq(Index)?.addClass("active");
      $(".button_simpal")?.eq(Index)?.addClass("button_");
    }
  }, [ID]);

  ////////////////////////////// Drewer ////////////////////////////////
  const [showDrawer, setShowDrawer] = useState(false);

  const handleCloseDrawer = () => {
    setShowDrawer(false);
    const buttonDisplay = document.getElementById("google_translate_element");
    if (buttonDisplay) {
      buttonDisplay.style.display = 'block';
    }
  };

  const handleShowDrawer = () => {
    setShowDrawer(true);
    const buttonDisplay = document.getElementById("google_translate_element");
    if (buttonDisplay) {
      buttonDisplay.style.display = 'none';
    }
  };

  ///////////////////// /  Google Api Location  //////////

  const handleSelect = async (address) => {
    if (address !== "" && lat !== "") {
      setError(null);
    }
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    setaddress_line1(address);
    setLat(latLng.lat);
    setLng(latLng.lng);
    // src="https://maps.googleapis.com/maps/api/js?sensor=false&key=AIzaSyAELg8lK4UgbRqTP2i4Mb-vqZtEGY_Gink"

    const address_ = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${googleMapsApiKey}&libraries=places`;
    fetch(address_)
      .then((res) => res.json())
      .then((resJson) => {
        const results = resJson?.results;
        let address = results[0].address_components;
        let zipcode = address[address.length - 1].long_name;
        // let country = address[address.length - 2].long_name;
        // let state = address[address.length - 3].long_name;
        // let city = address[address.length - 4].long_name;
        getDeliveryCost(latLng.lat, latLng.lng, zipcode);
        // console.log("sas", latLng.lat, latLng.lng, zipcode);
        // setstate();
        // setcity();
        // setcountry();
        setpincode(zipcode);
      })
      .catch((error) => { });
  };

  function handleChangeAddress(address) {
    setaddress_line1(address);
    // console.log("address", address);
  }

  function getDeliveryCost(latitude, longitude, pincode) {
    setErrorPromo(null);
    const data = {
      branch_id: BranchID,
      lat: latitude.toString(),
      long: longitude.toString(),
      pincode: pincode,
      order_type: "delivery",
    };
    // console.log("data", data);
    api
      .post(`/client/order/checkForDelivery`, data)
      .then((res) => {
        setErrorPromo(null);
      })
      .catch((err) => {
        if (err.response) {
          setErrorPromo("Does not deliver this location");
        }
      });
  }

  ///////////////////// /  Google Api Location  //////////

  const handleClose = () => {
    setShow(false);
    setTotalCost(0);
    setQuantity(1);
    setProduct_detaisBy_id("");
    setOptions([])
    setSelectedAddons({})
    setIsDescriptionExpanded(false);
    setSelectOption(false)
    setSelectedItems([]);
    setTotalPriceAdditions_product(0);
    setProductId_("")
  };

  const handleShow = () => {
    setQuantity(1);
    setErrorForAdd_Order(null);
    setShow(true);
  };

  function ProducteDetailsGet(productID_) {

    // console.log("productID11" ,productID_)
    setProductId_((prevItems) => [...prevItems, { name: "", _id: productID_?._id, price: productID_?.base_price, is_active: true }]
    );
    setTotalCost(0);
    api
      .get(`/client/product/${productID_?._id}`)
      .then((res) => {
        const tableData = res.data.data;
        // console.log("ProducteDetailsGet", tableData);
        setProduct_detaisBy_id(tableData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function increment_quantity() {
    setQuantity((quan) => quan + 1);
  }

  function decrement_quantity() {
    if (quantity > 1) setQuantity((quan) => quan - 1);
  }

  function increment_quantity_CheckOut(event, item_id, quantity_) {
    event.preventDefault();

    let item = {
      item_id: item_id,
      quantity: Number(quantity_ + 1),
    };

    if (cart_id) {
      api
        .patch(`/client/cart/item/${cart_id}`, item)
        .then((res) => {
          getCartForCheckOut();
          cartCount();
          getCartForCheckOutOldOrder();
          getCartForCheckOutLatesOrder();
        })
        .catch((err) => {
          if (err.response) {
            console.log(err?.response?.data);
          }
        });
    }
  }

  function decrement_quantity_CheckOut(event, item_id, quantity_) {
    event.preventDefault();

    if (quantity_ == 1) {
      let item = {
        item_id: item_id,
      };
      api
        .delete(`/client/cart/item/${cart_id}`, { data: item })
        .then((res) => {
          getCartForCheckOut();
          cartCount();
          getCartForCheckOutOldOrder();
          getCartForCheckOutLatesOrder();
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          }
        });
    } else {
      let item = {
        item_id: item_id,
        quantity: Number(quantity_ - 1),
      };
      if (cart_id) {
        api
          .patch(`/client/cart/item/${cart_id}`, item)
          .then((res) => {
            getCartForCheckOut();
            cartCount();
            getCartForCheckOutOldOrder();
            getCartForCheckOutLatesOrder();
          })
          .catch((err) => {
            if (err.response) {
              console.log(err.response?.data);
            }
          });
      }
    }
  }

  const [selectedAddons, setSelectedAddons] = useState({});
  const [totalPrice, setTotalPrice] = useState(Product_detaisBy_id?.base_price * quantity);
  const [isAddToCartDisabled, setIsAddToCartDisabled] = useState(true);
  const [options, setOptions] = useState([]);
  const [SelectOption, setSelectOption] = useState(false)

  const [selectedItems, setSelectedItems] = useState([]);
  const [totalPriceAdditions_product, setTotalPriceAdditions_product] = useState(0);

  // console.log("ProductId_", ProductId_);
  // console.log("totalPriceAdditions_product" ,totalPriceAdditions_product);


  const handleCheckboxChange = (item, checked) => {

    // console.log("sss" ,item, checked)
    if (checked) {
      // Add item to selected items
      setProductId_((prevItems) => [...prevItems, item]);
      setSelectedItems((prevItems) => [...prevItems, item]);
      // Update total price
      setTotalPriceAdditions_product((prevTotal) => Number(prevTotal) + Number(item.price));
    } else {
      // Remove item from selected items
      setProductId_((prevItems) => prevItems?.filter((i) => i._id !== item._id));
      setSelectedItems((prevItems) => prevItems?.filter((i) => i._id !== item._id))
      // Update total price
      setTotalPriceAdditions_product((prevTotal) => Number(prevTotal) - Number(item.price));
    }
  };

  const handleChange = (addonIndex, itemIndex, isChecked, price, item) => {

    // console.log("sasa" ,addonIndex, itemIndex, isChecked, price, item)
    setSelectedAddons((prevState) => {
      const newSelectedAddons = { ...prevState };
      let newOptions = [...options];

      if (isChecked) {
        if (Product_detaisBy_id.options[addonIndex].multiple_choices) {
          // Multiple choices: add item to the selectedAddons and options
          if (!newSelectedAddons[addonIndex]) newSelectedAddons[addonIndex] = [];
          if (!newSelectedAddons[addonIndex].includes(itemIndex)) {
            newSelectedAddons[addonIndex].push(itemIndex);
          }

          // Add to options if not already present
          if (!newOptions.some(opt => opt.addonIndex === addonIndex && opt.itemIndex === itemIndex)) {
            newOptions.push({ addonIndex, itemIndex, price, item });
          }
        } else {
          // Single choice: replace existing selection
          newSelectedAddons[addonIndex] = [itemIndex];

          // Remove previously selected option
          newOptions = newOptions.filter(opt => opt.addonIndex !== addonIndex);

          // Add new selection to options
          newOptions.push({ addonIndex, itemIndex, price, item });
        }
      } else {
        if (Product_detaisBy_id.options[addonIndex].multiple_choices) {
          // Multiple choices: remove item from selectedAddons and options
          newSelectedAddons[addonIndex] = newSelectedAddons[addonIndex].filter(index => index !== itemIndex);

          // Remove from options
          newOptions = newOptions.filter(opt => !(opt.addonIndex === addonIndex && opt.itemIndex === itemIndex));
        } else {
          // Single choice: remove item and clear selection
          delete newSelectedAddons[addonIndex];

          // Remove from options
          newOptions = newOptions.filter(opt => !(opt.addonIndex === addonIndex && opt.itemIndex === itemIndex));
        }
      }

      setOptions(newOptions);
      return newSelectedAddons;
    });
  };

  useEffect(() => {
    let newTotalPrice = Number(Product_detaisBy_id?.base_price * quantity) + Number(totalPriceAdditions_product);
    let allRequiredSelected = true;
    if (!TableNo) {

      const filteredResponse = Product_detaisBy_id?.options?.map(category => ({
        ...category,
        items: category?.items?.filter(item => item?.is_active)
      }));

      // console.log("filteredResponse", filteredResponse)

      const check = filteredResponse?.filter(item => item?.items?.length > 0)
      // console.log("check", check)
      if (check?.length > 0) {
        check && check?.forEach((addon, addonIndex) => {

          // console.log("addon", addon?.is_required);
          // console.log("sele", !selectedAddons[addonIndex]?.length);
          if (addon?.is_required && !selectedAddons[addonIndex]?.length) {
            allRequiredSelected = false;
          }
          selectedAddons[addonIndex]?.forEach((itemIndex) => {
            newTotalPrice += parseFloat(addon.items[itemIndex].price * quantity);
            // console.log("price", parseFloat(addon.items[itemIndex].price * quantity))
          });
        });
        setTotalPrice(newTotalPrice);
        setIsAddToCartDisabled(!allRequiredSelected);
        setSelectOption(true)
      }
      else if (check?.length === 0) {
        setTotalPrice(newTotalPrice);
        setIsAddToCartDisabled(false);
        setSelectOption(false)
      }
    } else {
      setTotalPrice(newTotalPrice);
      setIsAddToCartDisabled(false);
    }
  }, [!TableNo, selectedAddons, quantity, Product_detaisBy_id, selectedItems?.length]);

  // console.log("isAddToCartDisabled", isAddToCartDisabled);
  // console.log("selectedAddons", selectedAddons);

  const [errorForAdd_Order, setErrorForAdd_Order] = useState(null);
  const errorDivAdd_Order = errorForAdd_Order && (
    <div className="text-center alert alert-danger">{errorForAdd_Order}</div>
  )

  // function Add_Order() {
  //   setErrorForAdd_Order(null);

  //   // let item = new FormData();

  //   // if (cart_id) {
  //   //   item.append("cart_id", cart_id);
  //   // }
  //   // if (BranchID) {
  //   //   item.append("branch_id", BranchID);
  //   // }
  //   // if (quantity) {
  //   //   item.append("quantity", Number(quantity));
  //   // }
  //   // if (ProductId_) {
  //   //   item.append("product_id", ProductId_);
  //   // }
  //   // if (orderedItems.length > 0) {
  //   //   item.append("custom_property", JSON.stringify(orderedItems));
  //   // }
  //   // if (TableNo) {
  //   //   item.append("table_number", Number(TableNo));
  //   // }

  //   // if (options?.length > 0) {
  //   //   // Convert prices to numbers and then stringify
  //   //   const updatedOptions = options.map(main => ({
  //   //     ...main,
  //   //     price: Number(main.price), // Convert price to a number
  //   //     item: {
  //   //       ...main.item,
  //   //       price: Number(main.item.price), // Convert item price to a number
  //   //       quantity : Number(1)
  //   //     }
  //   //   }));  

  //   //   const abc = updatedOptions?.map((main) => main?.item)
  //   //     item.append("options", abc);
  //   //     // console.log("abc" ,abc);
  //   // }

  //   let item = {};

  //   if (cart_id) {
  //     item["cart_id"] = cart_id;
  //   }
  //   if (BranchID) {
  //     item["branch_id"] = BranchID;
  //   }
  //   if (quantity) {
  //     item["quantity"] = Number(quantity);
  //   }
  //   if (ProductId_) {
  //     item["product_id"] = ProductId_;
  //   }
  //   if (TableNo) {
  //     item["table_number"] = TableNo;
  //   }

  //   if (options?.length > 0) {
  //     // Convert prices to numbers and then stringify
  //     const updatedOptions = options.map(main => ({
  //       ...main,
  //       price: Number(main.price), // Convert price to a number
  //       item: {
  //         ...main.item,
  //         price: Number(main.item.price), // Convert item price to a number
  //         quantity: Number(1)
  //       }
  //     }));
  //     item["options"] = updatedOptions?.map((main) => main?.item);
  //   }


  //   // console.log("krunal", Object.fromEntries(item.entries()));
  //   // console.log("JSONitem", JSON.stringify(item));
  //   // return;
  //   // console.log("krunal", Object.fromEntries(orderedItems.entries()));

  //   api
  //     .post(`/client/cart/add`, JSON.stringify(item), {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       }
  //     })
  //     .then((res) => {
  //       setErrorForAdd_Order(null);
  //       handleClose()
  //       setQuantity(1);
  //       setProduct_detaisBy_id("");
  //       if (res?.data?.data?.status === "success") {
  //         sessionStorage.setItem("cart_id", res.data.data._id);
  //       }
  //       setProduct_detaisBy_id("");
  //       getCartForCheckOut();
  //       getCartForCheckOutOldOrder();
  //       getCartForCheckOutLatesOrder();
  //       if (cartCount_ >= 1) {
  //         cartCount();
  //       } else {
  //         api
  //           .get(`/client/cart/itemCount/${res.data.data._id}`)
  //           .then((res) => {
  //             setCartCount_(res.data.data);
  //             getCartForCheckOut();
  //             getCartForCheckOutOldOrder();
  //             getCartForCheckOutLatesOrder();
  //             // const tableData = res.data.data;
  //             // console.log("tableData", res.data.data);
  //           })
  //           .catch((err) => {
  //             console.log(err.response?.data?.error?.message);
  //           });
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response) {
  //         setErrorForAdd_Order(err?.response?.data?.error?.message);
  //       }
  //     });
  // }

  // function Add_Order() {
  //   setErrorForAdd_Order(null);

  //   ProductId_?.forEach((product , index) => {

  //   let item = {};

  //   if (cart_id) {
  //     item["cart_id"] = cart_id;
  //   }

  //   if (BranchID) {
  //     item["branch_id"] = BranchID;
  //   }
  //   if (quantity) {
  //     item["quantity"] = Number(quantity);
  //   }

  //   if (TableNo) {
  //     item["table_number"] = TableNo;
  //   }

  //   item["product_id"] = product._id; // Use the _id from the current product

  //     // Convert prices to numbers and then stringify
  //     // Add options only for the first product
  //     if (index === 0 && options?.length > 0) {
  //       const updatedOptions = options.map(main => ({
  //         ...main,
  //         price: Number(main.price), // Convert price to a number
  //         item: {
  //           ...main.item,
  //           price: Number(main.item.price), // Convert item price to a number
  //           quantity: Number(1)
  //         }
  //       }));
  //       item["options"] = updatedOptions.map(main => main.item);
  //     }

  //     // console.log("JSONitem", item);

  //     api
  //       .post(`/client/cart/add`, JSON.stringify(item), {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         }
  //       })
  //       .then((res) => {
  //         setErrorForAdd_Order(null);
  //         handleClose();
  //         setQuantity(1);
  //         setProduct_detaisBy_id("");
  //         if (res?.data?.data?.status === "success") {
  //           sessionStorage.setItem("cart_id", res.data.data._id);
  //         }
  //         setProduct_detaisBy_id("");
  //         getCartForCheckOut();
  //         getCartForCheckOutOldOrder();
  //         getCartForCheckOutLatesOrder();
  //         if (cartCount_ >= 1) {
  //           cartCount();
  //         } else {
  //           api
  //             .get(`/client/cart/itemCount/${res.data.data._id}`)
  //             .then((res) => {
  //               setCartCount_(res.data.data);
  //               getCartForCheckOut();
  //               getCartForCheckOutOldOrder();
  //               getCartForCheckOutLatesOrder();
  //             })
  //             .catch((err) => {
  //               console.log(err.response?.data?.error?.message);
  //             });
  //         }
  //       })
  //       .catch((err) => {
  //         if (err.response) {
  //           setErrorForAdd_Order(err?.response?.data?.error?.message);
  //         }
  //       });
  //   });
  // }

  // async function Add_Order() {
  //   setErrorForAdd_Order(null);

  //   // Function to make API calls
  //   const makeApiCall = async (item) => {
  //     try {
  //       const res = await api.post(`/client/cart/add`, JSON.stringify(item), {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         }
  //       });

  //       // Handle the response
  //       if (res?.data?.data?.status === "success") {
  //         const newCartId = res.data.data._id;
  //         sessionStorage.setItem("cart_id", newCartId);
  //         return newCartId;
  //       } else {
  //         throw new Error('Failed to add order');
  //       }
  //     } catch (err) {
  //       if (err.response) {
  //         setErrorForAdd_Order(err?.response?.data?.error?.message);
  //       }
  //       throw err;
  //     }
  //   };

  //   // First API call to get or create cart_id
  //   let cart_id = sessionStorage.getItem("cart_id");
  //   let newCartId;

  //   if (!cart_id) {
  //     const firstProduct = ProductId_[0];
  //     let firstItem = {
  //       product_id: firstProduct._id,
  //     };

  //     if (BranchID) {
  //       firstItem["branch_id"] = BranchID;
  //     }
  //     if (quantity) {
  //       firstItem["quantity"] = Number(quantity);
  //     }
  //     if (TableNo) {
  //       firstItem["table_number"] = TableNo;
  //     }
  //     if (options?.length > 0) {
  //     const optionsToSend = options.map(main => ({
  //         ...main,
  //         price: Number(main.price), // Convert price to a number
  //         item: {
  //           ...main.item,
  //           price: Number(main.item.price), // Convert item price to a number
  //           quantity: Number(1)
  //         }
  //       })).map(main => main.item);
  //       firstItem["options"] = optionsToSend;
  //     }

  //     try {
  //       newCartId = await makeApiCall(firstItem);
  //       cart_id = newCartId; // Update cart_id for further use
  //     } catch (err) {
  //       console.error('Error in first API call:', err);
  //       return;
  //     }
  //   }

  //   // Loop through ProductId_ and make API calls
  //   for (let product of ProductId_) {
  //     let item = {
  //       product_id: product._id,
  //     };

  //     if (cart_id) {
  //       item["cart_id"] = cart_id;
  //     }
  //     if (BranchID) {
  //       item["branch_id"] = BranchID;
  //     }
  //     if (quantity) {
  //       item["quantity"] = Number(quantity);
  //     }
  //     if (TableNo) {
  //       item["table_number"] = TableNo;
  //     }
  //     try {
  //       await makeApiCall(item);
  //     } catch (err) {
  //       console.error('Error in API call:', err);
  //       // Optionally handle errors for each product here
  //     }
  //   }

  //   // Additional processing after all API calls
  //   setErrorForAdd_Order(null);
  //   handleClose();
  //   setQuantity(1);
  //   setProduct_detaisBy_id("");
  //   getCartForCheckOut();
  //   getCartForCheckOutOldOrder();
  //   getCartForCheckOutLatesOrder();
  //   if (cartCount_ >= 1) {
  //     cartCount();
  //   } else {
  //     try {
  //       const res = await api.get(`/client/cart/itemCount/${cart_id}`);
  //       setCartCount_(res.data.data);
  //       getCartForCheckOut();
  //       getCartForCheckOutOldOrder();
  //       getCartForCheckOutLatesOrder();
  //     } catch (err) {
  //       console.error('Error getting cart item count:', err);
  //     }
  //   }
  // }

  const [LoadingAddOrder, setLoadingAddOrder] = useState(false);

  // console.log("ProductId_" ,ProductId_)
  async function Add_Order() {
    setLoadingAddOrder(true);
    setErrorForAdd_Order(null);

    // Function to make API calls
    const makeApiCall = async (item) => {
      try {

        const res = await api.post(`/client/cart/add`, JSON.stringify(item), {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        // Handle the response
        if (res?.data?.data?.status === "success") {
          const newCartId = res.data.data._id;
          sessionStorage.setItem("cart_id", newCartId);
          return newCartId;
        } else {
          // throw new Error('Failed to add order');
        }
      } catch (err) {
        if (err.response) {
          setErrorForAdd_Order(err?.response?.data?.error?.message);
        }
        throw err;
      }
    };

    // First API call to get or create cart_id
    let cart_id = sessionStorage.getItem("cart_id");
    let newCartId;
    let firstProductAdded = false;

    // Check if there are products to add
    if (!cart_id) {
      const firstProduct = ProductId_[0];
      let firstItem = {
        product_id: firstProduct._id,
        product_price : Number(firstProduct?.price)
      };

      if (BranchID) {
        firstItem["branch_id"] = BranchID;
      }
      if (quantity) {
        firstItem["quantity"] = Number(quantity);
      }
      if (TableNo) {
        firstItem["table_number"] = TableNo;
      }
      if (options?.length > 0) {
        const optionsToSend = options.map(main => ({
          ...main,
          price: Number(main.price), // Convert price to a number
          item: {
            ...main.item,
            price: Number(main.item.price), // Convert item price to a number
            quantity: Number(1)
          }
        })).map(main => main.item);
        firstItem["options"] = optionsToSend;
      }

      try {
        console.log('Sending item:1', firstItem); // Log the item before sending
        // return
        newCartId = await makeApiCall(firstItem);
        cart_id = newCartId; // Update cart_id for further use
        firstProductAdded = true; // Mark the first product as added
      } catch (err) {
        console.error('Error in first API call:', err);
        setLoadingAddOrder(false); // End loading
        return;
      }
    }

    // Loop through ProductId_ and make API calls
    for (let i = firstProductAdded ? 1 : 0; i < ProductId_.length; i++) {
      let product = ProductId_[i];

      console.log("product" ,product)
      let item = {
        product_id: product?._id,
        product_price : Number(product?.price)
      };
      if (cart_id) {
        item["cart_id"] = cart_id;
      }
      if (BranchID) {
        item["branch_id"] = BranchID;
      }
      if (quantity) {
        item["quantity"] = Number(quantity);
      }
      if (TableNo) {
        item["table_number"] = TableNo;
      }

      // / Only add options for the first item if not already added
      if (!firstProductAdded && i === 0 && options?.length > 0) {
        const optionsToSend = options.map(main => ({
          ...main,
          price: Number(main.price), // Convert price to a number
          item: {
            ...main.item,
            price: Number(main.item.price), // Convert item price to a number
            quantity: Number(1)
          }
        })).map(main => main.item);
        item["options"] = optionsToSend;
      }
      try {
        console.log('Sending item:2', item); // Log the item before sending
        // return
        await makeApiCall(item);
      } catch (err) {
        console.error('Error in API call:', err);
        // Optionally handle errors for each product here
      }
    }

    // Additional processing after all API calls
    setErrorForAdd_Order(null);
    handleClose();
    setQuantity(1);
    setProduct_detaisBy_id("");
    getCartForCheckOut();
    getCartForCheckOutOldOrder();
    getCartForCheckOutLatesOrder();
    if (cartCount_ >= 1) {
      cartCount();
    } else {
      try {
        const res = await api.get(`/client/cart/itemCount/${cart_id}`);
        setCartCount_(res.data.data);
        getCartForCheckOut();
        getCartForCheckOutOldOrder();
        getCartForCheckOutLatesOrder();
      } catch (err) {
        console.error('Error getting cart item count:', err);
      }
    }
    setLoadingAddOrder(false); // End loading
  }






  const [errorCheckOut, setErrorCheckOut] = useState(null);
  const errorDivCheckOut = errorCheckOut && (
    <div className="text-center alert alert-danger">{errorCheckOut}</div>
  )

  const [ErrorCheckOutTimeComper, setErrorCheckOutTimeComper] = useState([]);
  const [ErrorSendOrderTimeDine, setErrorSendOrderTimeDine] = useState([]);
  // console.log("ErrorCheckOutTimeComper" ,ErrorCheckOutTimeComper)

  const [errorSendOrder, setErrorSendOrder] = useState(null);
  const errorDivSendOrder = errorSendOrder && (
    <div className="text-center alert alert-danger">{errorSendOrder}</div>
  )

  const [GetCartForCheckout, setGetCartForCheckout] = useState("");
  const [GetCartForCheckoutOldOrder, setGetCartForCheckoutOldOrder] = useState("");
  const [GetCartForCheckoutLatesOrder, setGetCartForCheckoutLatesOrder] = useState("");
  const [FinalAmount, setFinalAmount] = useState("");
  const [CancelOrder, setCancelOrder] = useState("");

  // console.log('CancelOrder' ,CancelOrder)
  // console.log('GetCartForCheckoutLatesOrder' , GetCartForCheckoutLatesOrder)

  function Check_Out_detailes(event) {
    event.preventDefault();

    if (GetCartForCheckout == "" || GetCartForCheckout?.items?.length == 0) {
      setErrorCheckOut("Your Cart is Empty!");
      return;
    } else {
      if (Mood == "") {
        setErrorCheckOut("Please select preferred method of order");
        return;
      } else {
        if (Mood === "delivery") {
          if (item_value != null && item_value?.order_type === "delivery") {
            setErrorCheckOut(null);
            let item = new FormData();
            item.append("order_type", "delivery");

            item.append("PreTime", PreTime);
            if (cart_id) {
              item.append("cart_id", cart_id);
            }
            if (address_line1 ? address_line1 : item_value?.address_line1) {
              item.append(
                "address_line1",
                String(address_line1)
                  ? String(address_line1)
                  : String(item_value?.address_line1)
              );
            }
            if (address_line2 ? address_line2 : item_value?.address_line2) {
              item.append(
                "address_line2",
                String(address_line2)
                  ? String(address_line2)
                  : String(item_value?.address_line2)
              );
            }
            if (lat ? lat : item_value?.lat) {
              item.append(
                "lat",
                String(lat) ? String(lat) : String(item_value?.lat)
              );
            }
            if (lng ? lng : item_value?.lng) {
              item.append(
                "long",
                String(lng) ? String(lng) : String(item_value?.lng)
              );
            }
            if (
              delivery_order_date ? delivery_order_date : item_value?.order_date
            ) {
              item.append(
                "order_date",
                delivery_order_date
                  ? moment(delivery_order_date).format()
                  : item_value?.order_date
              );
            }
            // "2023-02-11T02:11:51",
            if (
              delivery_order_time ? delivery_order_time : item_value?.order_time
            ) {
              item.append(
                "order_time",
                delivery_order_time
                  ? String(delivery_order_time)
                  : String(item_value?.order_time)
              );
            }
            if (pincode ? pincode : item_value?.pincode) {
              item.append(
                "pincode",
                String(pincode) ? String(pincode) : String(item_value?.pincode)
              );
            }
            if (GetCartForCheckout?.total_amount_without_Discount) {
              item.append(
                "total_amount",
                GetCartForCheckout?.total_amount_without_Discount
              );
            }
            item.append("placingDate", placingDate);
            item.append("placingTime", placingTime);


            // console.log("DeliverypData" ,DeliverypData)
            // console.log("krunal", Object.fromEntries(item.entries()));
            // return
            // console.log("krunal", Object.fromEntries(orderedItems.entries()));

            api
              .post(`/client/cart/processedForCheckOut/${cart_id}`, item)
              .then((res) => {
                let DeliverypData = {
                  PreTime: PreTime,
                  order_type: "delivery",
                  cart_id: cart_id,
                  order_date: delivery_order_date
                    ? moment(delivery_order_date).format()
                    : item_value?.order_date,
                  delivery_order_dateA: delivery_order_dateA
                    ? delivery_order_dateA
                    : item_value?.delivery_order_dateA,
                  order_time: delivery_order_time
                    ? delivery_order_time
                    : item_value?.order_time,
                  delivery_order_timeA: delivery_order_timeA
                    ? delivery_order_timeA
                    : item_value?.delivery_order_timeA,
                  total_amount:
                    GetCartForCheckout?.total_amount_without_Discount,
                  address_line1: address_line1
                    ? address_line1
                    : item_value?.address_line1,
                  address_line2: address_line2
                    ? address_line2
                    : item_value?.address_line2,
                  lat: String(lat) ? String(lat) : String(item_value?.lat),
                  lng: String(lng) ? String(lng) : String(item_value?.lng),
                  pincode: String(pincode)
                    ? String(pincode)
                    : String(item_value?.pincode),
                };
                sessionStorage.setItem("items", JSON.stringify(DeliverypData));
                setErrorCheckOut(null);
                setQuantity(1);
                setProduct_detaisBy_id("");
                history.push(`/${urlBranchID}/cartpage`);
                // window.location.reload(true);
              })
              .catch((err) => {
                if (err.response) {
                  var errorDivCheckOut = err?.response?.data?.error?.message;
                  setErrorCheckOut("");
                  setErrorCheckOutTimeComper([]);
                  if (typeof errorDivCheckOut === "string") {
                    setErrorCheckOut(err?.response?.data?.error?.message);
                    // console.log("It's a string");
                  } else if (Array.isArray(errorDivCheckOut)) {
                    setErrorCheckOut("")
                    setErrorCheckOutTimeComper(err?.response?.data?.error?.message ? err?.response?.data?.error?.message : [])
                    // console.log("It's an array");
                  } else {
                    console.log("It's something else");
                  }
                }
              });
          } else {
            if (address_line1 == "") {
              setErrorCheckOut("Please select delivery address");
              return;
            } else if (lat == "" && lng == "") {
              setErrorCheckOut("Please select delivery address");
              return;
            } else if (delivery_order_date == "") {
              setErrorCheckOut("Please select order date");
              return;
            } else if (delivery_order_time == "") {
              setErrorCheckOut("Please select order time");
              return;
            } else {
              setErrorCheckOut(null);
              let item = new FormData();
              item.append("order_type", "delivery");

              item.append("PreTime", PreTime);
              if (cart_id) {
                item.append("cart_id", cart_id);
              }
              if (address_line1) {
                item.append("address_line1", String(address_line1));
              }
              if (address_line2) {
                item.append("address_line2", String(address_line2));
              }
              if (lat) {
                item.append("lat", String(lat));
              }
              if (lng) {
                item.append("long", String(lng));
              }
              if (delivery_order_date) {
                item.append("order_date", moment(delivery_order_date).format());
              }
              // "2023-02-11T02:11:51",
              if (delivery_order_time) {
                item.append("order_time", String(delivery_order_time));
              }
              if (pincode) {
                item.append("pincode", String(pincode));
              }
              if (GetCartForCheckout?.total_amount_without_Discount) {
                item.append(
                  "total_amount",
                  GetCartForCheckout?.total_amount_without_Discount
                );
              }
              item.append("placingDate", placingDate);
              item.append("placingTime", placingTime);

              // console.log("krunal", Object.fromEntries(item.entries()));
              // console.log("krunal", Object.fromEntries(orderedItems.entries()));

              api
                .post(`/client/cart/processedForCheckOut/${cart_id}`, item)
                .then((res) => {
                  let DeliverypData = {
                    PreTime: PreTime,
                    order_type: "delivery",
                    cart_id: cart_id,
                    order_date: moment(delivery_order_date).format(),
                    delivery_order_dateA: delivery_order_dateA,
                    order_time: delivery_order_time,
                    delivery_order_timeA: delivery_order_timeA,
                    total_amount:
                      GetCartForCheckout?.total_amount_without_Discount,
                    address_line1: address_line1,
                    address_line2: address_line2,
                    lat: String(lat),
                    lng: String(lng),
                    pincode: String(pincode),
                  };
                  sessionStorage.setItem(
                    "items",
                    JSON.stringify(DeliverypData)
                  );
                  setErrorCheckOut(null);
                  setQuantity(1);
                  setProduct_detaisBy_id("");
                  history.push(`/${urlBranchID}/cartpage`);
                  // window.location.reload(true);
                })
                .catch((err) => {
                  if (err.response) {
                    var errorDivCheckOut = err?.response?.data?.error?.message;
                    setErrorCheckOut("");
                    setErrorCheckOutTimeComper([]);
                    if (typeof errorDivCheckOut === "string") {
                      setErrorCheckOut(err?.response?.data?.error?.message);
                      // console.log("It's a string");
                    } else if (Array.isArray(errorDivCheckOut)) {
                      setErrorCheckOut("")
                      setErrorCheckOutTimeComper(err?.response?.data?.error?.message ? err?.response?.data?.error?.message : [])
                      // console.log("It's an array");
                    } else {
                      console.log("It's something else");
                    }

                  }
                });
            }
          }
        } else if (Mood === "pickup") {
          if (item_value != null && item_value?.order_type === "pickup") {
            setErrorCheckOut(null);

            let item = new FormData();
            item.append("order_type", "pickup");
            item.append("PreTime", PreTime);

            if (cart_id) {
              item.append("cart_id", cart_id);
            }

            if (
              pickup_order_date ? pickup_order_date : item_value?.order_date
            ) {
              item.append(
                "order_date",
                pickup_order_date
                  ? moment(pickup_order_date).format()
                  : item_value?.order_date
              );
            }
            // "2023-02-11T02:11:51",
            if (
              pickup_order_time ? pickup_order_time : item_value?.order_time
            ) {
              item.append(
                "order_time",
                String(pickup_order_time)
                  ? String(pickup_order_time)
                  : item_value?.order_time
              );
            }
            if (GetCartForCheckout?.total_amount_without_Discount) {
              item.append(
                "total_amount",
                GetCartForCheckout?.total_amount_without_Discount
              );
            }

            item.append("placingDate", placingDate);
            item.append("placingTime", placingTime);

            // console.log("krunal", Object.fromEntries(item.entries()));
            // return
            // console.log("krunal", Object.fromEntries(orderedItems.entries()));

            api
              .post(`/client/cart/processedForCheckOut/${cart_id}`, item)
              .then((res) => {
                let PickupData = {
                  PreTime: PreTime,
                  order_type: "pickup",
                  cart_id: cart_id,
                  order_date: pickup_order_date
                    ? moment(pickup_order_date).format()
                    : item_value?.order_date,
                  pickup_order_dateA: pickup_order_dateA
                    ? pickup_order_dateA
                    : item_value?.pickup_order_dateA,
                  order_time: String(pickup_order_time)
                    ? String(pickup_order_time)
                    : item_value?.order_time,
                  pickup_order_timeA: pickup_order_timeA
                    ? pickup_order_timeA
                    : item_value?.pickup_order_timeA,
                  total_amount:
                    GetCartForCheckout?.total_amount_without_Discount,
                };
                sessionStorage.setItem("items", JSON.stringify(PickupData));
                setErrorCheckOut(null);
                setQuantity(1);
                setProduct_detaisBy_id("");
                history.push(`/${urlBranchID}/cartpage`);
                // window.location.reload(true);
              })
              .catch((err) => {
                if (err.response) {
                  setErrorCheckOut(err?.response?.data?.error?.message);
                  var errorDivCheckOut = err?.response?.data?.error?.message;
                  setErrorCheckOut("");
                  setErrorCheckOutTimeComper([]);
                  if (typeof errorDivCheckOut === "string") {
                    setErrorCheckOut(err?.response?.data?.error?.message);
                    // console.log("It's a string");
                  } else if (Array.isArray(errorDivCheckOut)) {
                    setErrorCheckOut("")
                    setErrorCheckOutTimeComper(err?.response?.data?.error?.message ? err?.response?.data?.error?.message : [])
                    // console.log("It's an array");
                  } else {
                    console.log("It's something else");
                  }

                }
              });
          } else {
            if (pickup_order_date == "") {
              setErrorCheckOut("Please select order date");
              return;
            } else if (pickup_order_time == "") {
              setErrorCheckOut("Please select order time");
              return;
            } else {
              setErrorCheckOut(null);

              let item = new FormData();
              item.append("order_type", "pickup");
              item.append("PreTime", PreTime);

              if (cart_id) {
                item.append("cart_id", cart_id);
              }

              if (pickup_order_date) {
                item.append("order_date", moment(pickup_order_date).format());
              }
              // "2023-02-11T02:11:51",
              if (pickup_order_time) {
                item.append("order_time", String(pickup_order_time));
              }
              if (GetCartForCheckout?.total_amount_without_Discount) {
                item.append("total_amount", GetCartForCheckout?.total_amount_without_Discount);
              }
              item.append("placingDate", placingDate);
              item.append("placingTime", placingTime);
              // console.log("krunal", Object.fromEntries(item.entries()));
              // return
              // console.log("krunal", Object.fromEntries(orderedItems.entries()));

              api
                .post(`/client/cart/processedForCheckOut/${cart_id}`, item)
                .then((res) => {
                  let PickupData = {
                    PreTime: PreTime,
                    order_type: "pickup",
                    cart_id: cart_id,
                    order_date: moment(pickup_order_date).format(),
                    pickup_order_dateA: pickup_order_dateA,
                    order_time: pickup_order_time,
                    pickup_order_timeA: pickup_order_timeA,
                    total_amount:
                      GetCartForCheckout?.total_amount_without_Discount,
                  };
                  sessionStorage.setItem("items", JSON.stringify(PickupData));
                  setErrorCheckOut(null);
                  setQuantity(1);
                  setProduct_detaisBy_id("");
                  history.push(`/${urlBranchID}/cartpage`);
                  //  window.location.reload(true);
                })
                .catch((err) => {
                  if (err.response) {
                    var errorDivCheckOut = err?.response?.data?.error?.message;
                    setErrorCheckOut("");
                    setErrorCheckOutTimeComper([]);
                    if (typeof errorDivCheckOut === "string") {
                      setErrorCheckOut(err?.response?.data?.error?.message);
                      // console.log("It's a string");
                    } else if (Array.isArray(errorDivCheckOut)) {
                      setErrorCheckOut("")
                      setErrorCheckOutTimeComper(err?.response?.data?.error?.message ? err?.response?.data?.error?.message : [])
                      // console.log("It's an array");
                    } else {
                      console.log("It's something else");
                    }
                  }
                });
            }
          }
        }
      }
    }
  }


  function send_Order(event) {
    event.preventDefault();
    setErrorSendOrder(null);


    const item = new FormData();

    if (cart_id) {
      item.append("cart_id", cart_id);
    }
    if (order_notes) {
      item.append("order_notes", order_notes);
    }
    item.append("order_date", moment().format());
    item.append("order_time", moment().format("LT"));

    // let item = {
    //   "cart_id"  : cart_id
    // }

    // console.log(Object.fromEntries(item.entries()));

    api
      .post(`/client/order/addDineInOrder`, item)
      .then((res) => {
        setErrorSendOrder(null);
        let DineInData = {
          PreTime: 15,
        };
        sessionStorage.setItem("DineInData", JSON.stringify(DineInData));
        showSuccessNotification("Order sent to kitchen.")
        setShowDrawer(false);
        setorder_notes("");
        sessionStorage.removeItem("cart_id");
        // getCartForCheckOutLatesOrder();
        setGetCartForCheckoutLatesOrder("");
        setCartCount_("");
        getCartForCheckOutOldOrder();
      })
      .catch((err) => {
        if (err.response) {
          setErrorSendOrderTimeDine([]);
          if (Array.isArray(err?.response?.data?.error?.message)) {
            setShowDrawer(true);
            setErrorSendOrderTimeDine(err?.response?.data?.error?.message ? err?.response?.data?.error?.message : [])
            // console.log("It's an array");
          }

        }
      });
  }

  useEffect(() => {
    setCartId(cart_id);
  }, []);

  useEffect(() => {
    cartCount();
    getCartForCheckOut();
  }, [cart_id !== null]);

  useEffect(() => {
    getCartForCheckOutOldOrder();
  }, [TableNo, BranchID]);

  useEffect(() => {
    getCartForCheckOutLatesOrder();
  }, [cart_id !== null, TableNo]);

  useEffect(() => {
    // tableStatusUpdate();
    sessionCheck();
  }, [BranchID]);

  const [cartCount_, setCartCount_] = useState("");

  function cartCount() {
    if (cart_id !== null) {
      api
        .get(`/client/cart/itemCount/${cart_id}`)
        .then((res) => {
          setCartCount_(res.data.data);
          if (res.data.data === 0) {
            sessionStorage.removeItem("cart_id");
          }
          // const tableData = res.data.data;
          // console.log("tableData", res.data.data);
        })
        .catch((err) => {
          console.log(err.response?.data?.error?.message);
        });
    }
  }

  function getCartForCheckOut() {
    setErrorCheckOut(null);
    if (cart_id != null) {
      api
        .get(`/client/cart/getCart/${cart_id}`)
        .then((res) => {
          const tabel = res.data.data
          tabel?.items?.forEach(item => {
            item.IsComppare = false; // You can set the value to whatever you want
          });
          setGetCartForCheckout(tabel);
          // console.log(tabel)
          // Now, jsonData will have the "IsCheck" key added to each item object within the "items" array
          // const tableData = res.data.data;

        })
        .catch((err) => {
          console.log(err.response?.data?.error?.message);
        });
    }
  }

  // console.log("BranchID" ,BranchID)

  function getCartForCheckOutOldOrder() {
    // setErrorCheckOut(null)

    let url = `/client/cart/getOrderForDineIn/${BranchID}/${TableNo}`;
    if (BranchID && TableNo) {
      // console.log("url" ,url)
      api
        .get(`/client/cart/getOrderForDineIn/${BranchID}/${TableNo}`)
        .then((res) => {
          // console.log("dsfsd", res?.data?.data.length > 0 ? res?.data?.data?.filter((word) => word.orderItemDetails?.length == 0 ? word : "") : "");
          setGetCartForCheckoutOldOrder(res?.data?.data ? res?.data?.data : "");
          setCancelOrder(
            res?.data?.data.length > 0
              ? res?.data?.data?.filter((word) =>
                word.orderItemDetails?.length == 0 ? word : ""
              )
              : ""
          );
          setFinalAmount(res?.data);
        })
        .catch((err) => {
          console.log(err.response?.data);
        });
    }
  }

  // console.log("FinalAmount" ,FinalAmount)

  function getCartForCheckOutLatesOrder() {
    // setErrorCheckOut(null)
    if (cart_id) {
      api
        .get(`/client/cart/getCartForDineIn/${cart_id}`)
        .then((res) => {
          setGetCartForCheckoutLatesOrder(res?.data);
        })
        .catch((err) => {
          console.log(err.response?.data?.error?.message);
        });
    }
  }

  const [DineInTimeData_, setDineInTimeData_] = useState("");

  const [sessionCheck_, setsessionCheck_] = useState("");

  function sessionCheck() {
    if (BranchID && TableNo) {
      api
        .get(`/client/order/sessionCheck/${TableNo}/${sessionId}`)
        .then((res) => {
          const tableData = res?.data?.data?.check;
          setsessionCheck_(tableData ? tableData : "");
          // console.log("sessionCheck",tableData);
          setDineInTimeData_(
            moment(tableData?.dine_in_start).tz(tableData?.timezone).format('h:mm A')
            // moment(res?.data?.data?.check?.dine_in_start).format("h:mm a")
          );
        })
        .catch((err) => {
          // console.log("err.response?.data?.error?.message" ,err.response?.data?.error?.message)
          setsessionCheck_(err.response?.data?.error?.message);
        });
    }
  }

  const [PreTime, setPreTime] = useState("25");
  const [branchTime, setbranchTime] = useState([]);
  const [branchTimePickup, setbranchTimePickup] = useState([]);
  const [DeliveryDays, setDeliveryDays] = useState([]);
  const [DeliveryTimeList, setDeliveryTimeList] = useState([]);
  const [PickupTimeList, setPickupTimeList] = useState([]);
  const [PickupDays, setPickupDays] = useState([]);
  const [IndexForDelievryDate, setIndexForDelievryDate] = useState("");
  const [IndexForPickupDate, setIndexForPickupDate] = useState("");

  useEffect(() => {
    if (!TableNo) {
      if (Delivery === 0 && Mood === "delivery") {
        let item = {
          type: "delivery",
          branch_id: BranchID,
          language: language.toUpperCase(),
        };
        // console.log("delivery1" , item)
        api
          .post(`/client/order/branchtimefilter`, item)
          .then((res) => {
            // setbranchTime(res.data.data);
            setDeliveryDays(res.data.data?.find(item => Array.isArray(item.dayList)) ? res.data.data?.find(item => Array.isArray(item.dayList)) : []);
            const dayList = res?.data?.data?.find(item => Array.isArray(item.timeList)) ? res?.data?.data?.find(item => Array.isArray(item.timeList)) : [];
            const timeList = dayList.timeList;
            // console.log('timeList' ,timeList) 
            setDeliveryTimeList(timeList ? timeList : []);
            // console.log("delivery_data", res.data.data);
          })
          .catch((err) => {
            if (err.response) {
              console.log(err.response?.data?.error?.message);
            }
          });
      }
    }
  }, [Delivery === 0 && Mood === "delivery"]);

  useEffect(() => {
    if (!TableNo) {
      if (Delivery === -1 && Pickup == 1) {
        let item = {
          type: "pickup",
          branch_id: BranchID,
          language: language.toUpperCase(),
        };
        api
          .post(`/client/order/branchtimefilter`, item)
          .then((res) => {
            // setbranchTimePickup(res.data.data);
            setPickupDays(res.data.data?.find(item => Array.isArray(item.dayList)) ? res.data.data?.find(item => Array.isArray(item.dayList)) : []);
            const dayList = res?.data?.data?.find(item => Array.isArray(item.timeList)) ? res?.data?.data?.find(item => Array.isArray(item.timeList)) : [];
            const timeList = dayList.timeList;
            // console.log('daylist' ,res.data.data?.find(item => Array.isArray(item.dayList))) 
            setPickupTimeList(timeList ? timeList : []);
            // console.log("pickTime", res.data.data);
          })
          .catch((err) => {
            if (err.response) {
              console.log(err.response?.data?.error?.message);
            }
          });
      } else {
        if (Mood === "pickup") {
          let item = {
            type: "pickup",
            branch_id: BranchID,
            language: language.toUpperCase(),
          };
          api
            .post(`/client/order/branchtimefilter`, item)
            .then((res) => {
              // setbranchTimePickup(res.data.data);
              setPickupDays(res.data.data?.find(item => Array.isArray(item.dayList)) ? res.data.data?.find(item => Array.isArray(item.dayList)) : []);
              const dayList = res?.data?.data?.find(item => Array.isArray(item.timeList)) ? res?.data?.data?.find(item => Array.isArray(item.timeList)) : [];
              const timeList = dayList.timeList;
              // console.log('daylist' ,res.data.data?.find(item => Array.isArray(item.dayList))) 
              setPickupTimeList(timeList ? timeList : []);
              // console.log("pickTime", res.data.data);
            })
            .catch((err) => {
              if (err.response) {
                console.log(err.response?.data?.error?.message);
              }
            });
        }
      }
    }
  }, [Pickup === 1 && Mood === "pickup"]);

  // console.log("Mood" , Mood)


  // Helper function for rendering the description with "Show more" toggle
  function DescriptionToggle({ text, description }) {
    return text?.length > 100 ? (
      <div style={{ cursor: "pointer" }} onClick={() => setshoeMoredescription(!shoeMoredescription)}>
        {shoeMoredescription
          ? `${description}... See less`
          : `${description?.substring(0, 150)}... Show more`}
      </div>
    ) : (
      <div>{description}</div>
    );
  }

  // Helper function for rendering the disclaimer
  function renderDisclaimer(language, main) {
    return (
      <React.Fragment>
        <strong style={{ color: "black", fontWeight: 600 }}>
          {main?.category_disclaimer_EN ? "Disclaimer:" : ""}
        </strong> {main?.category_disclaimer_EN}
      </React.Fragment>
    );
  }

  const selectOption = {
    menuPosition: "fixed",
    isSearchable: false,
    inputFocusOnClick: false,
    menuPortalTarget: document.body,
    styles: colourStylesHeader,
    placeholder: "Select",
  };

  const backgroundImageUrl = MenuList?.length > 0 ? (MenuList[0]?.image ? MenuList[0].image : '') : '';

  const [ModelShow, setModelShow] = useState(false);

  const branchDetailsModelClose = () => setModelShow(false);
  const branchDetailsModelShow = () => setModelShow(true);

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const truncateText = (text, length) => {
    return text?.length > length ?
      text?.substring(0, length)
      : text;
  };


  const categories = [
    {
      name: 'Pizza',
      items: [
        {
          name: 'Margherita',
          image: '/assets/media/avatars/margherita.jpg',
          price: 15,
        },
        {
          name: 'Pepperoni',
          image: '/assets/media/avatars/pepperoni.jpg',
          price: 18,
        },
        // Add more pizza items here
      ],
    },
    {
      name: 'Thai',
      items: [
        {
          name: 'Green Curry',
          image: '/assets/media/avatars/green_curry.jpg',
          price: 20,
        },
        {
          name: 'Pad Thai',
          image: '/assets/media/avatars/pad_thai.jpg',
          price: 17,
        },
        // Add more Thai items here
      ],
    },
    // Add more categories here if needed
  ];

  return (
    <React.Fragment>
      <CommonHeader customRes={customRes} />
      {sessionCheck_ === "" || sessionCheck_?.dine_in_start ? (
        <div style={{ backgroundColor: "#F0EEED", overflowX: "clip" }}>
          <ToastContainer />
          <React.Fragment>
            <div style={{ overflow: "hidden" }} className="jIxqqQ">
              <span className="Span_image">
                {!backgroundImageUrl ? (
                  <div className="default_background"></div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: 'hidden',
                      background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${backgroundImageUrl}) center center no-repeat`,
                      backgroundSize: 'cover',
                    }}
                  ></div>
                )}
              </span>
              <div className="sc-86d55903-0 bBoPTF">
                <div className="sc-d832d68-0 jcVWLl">
                  <div className="sc-86d55903-0 sc-93a5572f-0 sc-fdb88f63-0 louJwp dJztCp iEcYKf">
                    {BranchDetails_ &&
                      <div className="sc-86d55903-0 sc-78573766-0 cfQmWr VyRky" offset="">
                        <img style={{ background: customRes?.logo_url === undefined && "#fff" }} src={customRes?.logo_url || "/assets/media/logos/Logo_Dark.png"} className="venue-header-inner" alt="icon" />
                        <h1 className="venue-name">{BranchDetails_ && BranchDetails_?.resData?.branch_name_EN}</h1>
                        <p className="venue-contact ng-binding">
                          <LocationOnIcon style={{ fontSize: "large" }} />
                          {BranchDetails_ ? BranchDetails_?.resData?.address : ''}{' '}
                          <PhoneEnabledIcon style={{ fontSize: "large" }} />
                          +{BranchDetails_ ? BranchDetails_?.resData?.phone_number : ''}{' '}

                        </p>
                        <button
                          style={{ marginTop: "-5px" }}
                          className="btn btn-sm btn-outline-light"
                          onClick={branchDetailsModelShow}
                        >
                          More
                        </button>
                        {/* {MenuName && <MenuNameCommon MenuName={MenuName} />} */}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <Card
              id="kt_header"
              style={{
                border: 0,
                position: "sticky",
                top: 0,
                zIndex: 1,
                padding: 0,
                backgroundColor: "rgb(255 255 255)",
                borderRadius: 0,
                height: 62,
                borderBottom: "1px solid rgb(233, 233, 233)",
              }}
            >
              <Row>
                <Col lg={2} md={2} sm={2}></Col>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  lg={8}
                  md={8}
                  xs={12}
                  sm={8}
                >
                  <div
                    className="cont"
                    style={{
                      overflow: "auto",
                    }}
                  >
                    <ul
                      style={{ display: "table-row" }}
                      className="book-list"
                      id="book-list"
                    >
                      {MenuList.map((item, index) => (
                        <>
                          {((item.featured_products && item.featured_products.length > 0) || (item.products && item.products.length > 0)) &&
                            <li
                              key={index}
                              data-id={`${item.category_name_EN && item.category_name_EN
                                .replace(
                                  /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                                  ""
                                )
                                .replace(/ /g, "")}_${index}`}
                              className="book mx-2"
                              style={{ display: "table-cell", padding: "13px" }}
                              onClick={() => {
                                setID(
                                  `#${item.category_name_EN && item.category_name_EN
                                    .replace(
                                      /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
                                      ""
                                    )
                                    .replace(/ /g, "")}_${index}`
                                );
                                SetIndex(index);
                              }}
                            >
                              <p
                                type="button"
                                key={index}
                                className={item.category_name_EN ? "nav-link button_simpal Button_name" : ''}
                                style={{ textDecoration: "unset" }}
                              >
                                {item?.category_name_EN
                                  && item?.category_name_EN}
                              </p>
                            </li>
                          }</>))}
                    </ul>
                  </div>
                </Col>

                <Col
                  className="text-start"
                  lg={2}
                  md={2}
                  sm={2}
                  style={{ marginTop: 15 }}
                >
                  {isMobile || isTablet ? (
                    ""
                  ) : cartCount_ != "" ?
                    (
                      <React.Fragment>
                        <div>
                          <button
                            onClick={handleShowDrawer}
                            data-testid="cart-button"
                            className="sc-2fc13418-0 cwDcuM"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="sc-86d55903-0 sc-93a5572f-0 sc-2fc13418-1 bKQrMI dJztCp bdegrl">
                              <svg
                                version="1.1"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                                className="sc-194a41c1-0 cQvspK"
                                data-testid="shoppingBag"
                                fill="white"
                              >
                                <title>Shopping Bag</title>
                                <path d="M19 7H16V6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6V7H5C4.44772 7 4 7.44772 4 8V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V8C20 7.44772 19.5523 7 19 7ZM10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6V7H10V6ZM18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V9H8V10C8 10.5523 8.44772 11 9 11C9.55228 11 10 10.5523 10 10V9H14V10C14 10.5523 14.4477 11 15 11C15.5523 11 16 10.5523 16 10V9H18V19Z" />
                              </svg>
                              {cartCount_}
                            </div>
                          </button>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div
                          style={{ justifyContent: "start" }}
                          className="sc-86d55903-0 sc-93a5572f-0 sc-2fc13418-1 bKQrMI dJztCp bdegrl"
                        >
                          <svg
                            onClick={handleShowDrawer}
                            version="1.1"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            className="sc-194a41c1-0"
                            data-testid="shoppingBag"
                            fill="black"
                            style={{
                              height: "28px",
                              width: "28px",
                              marginRight: "25px",
                              cursor: "pointer",
                            }}
                          >
                            <title>Shopping Bag</title>
                            <path d="M19 7H16V6C16 3.79086 14.2091 2 12 2C9.79086 2 8 3.79086 8 6V7H5C4.44772 7 4 7.44772 4 8V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V8C20 7.44772 19.5523 7 19 7ZM10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6V7H10V6ZM18 19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V9H8V10C8 10.5523 8.44772 11 9 11C9.55228 11 10 10.5523 10 10V9H14V10C14 10.5523 14.4477 11 15 11C15.5523 11 16 10.5523 16 10V9H18V19Z" />
                          </svg>
                        </div>
                      </React.Fragment>
                    )}
                </Col>
              </Row>
            </Card>
            <React.Fragment>
              <div className="cproduct">
                {MenuList?.length > 0 && MenuList?.map((main, index) => (
                  main?.is_featured === true ?
                    <React.Fragment>
                      {(main.featured_products && main.featured_products.length > 0) &&
                        <Card
                          style={{ overflow: "hidden", backgroundColor: "white" }}
                          id={`${main?.category_name_EN && main?.category_name_EN
                            .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
                            .replace(/ /g, "")}_${index}`}
                          key={index}
                          className="jvlusT jXIdmS mb-20 card_common_ scroll_section"
                        >
                          <Container>
                            <br />
                            <br />
                            <br />
                            <Row className="text-center">
                              <Col>
                                <h2 className="Popular_this_week">
                                  {ID_Featured[0]?.category_name_EN
                                    && ID_Featured[0]?.category_name_EN}
                                </h2>
                                <svg
                                  viewBox="0 0 167 17"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="sc-194a41c1-0 cecmhh"
                                  data-testid="lineAscending"
                                  width="24px"
                                  height="24px"
                                >
                                  <title>Line Ascending</title>
                                  <path d="M0 10.79L30.42 7.91L43.7 6.59L164.5 0L167 6.3L56.21 13.3L48.69 12.47L36.19 14.57H28.81L23.81 15.79L0.49 16.21L0 10.79Z" />
                                </svg>
                              </Col>
                            </Row>
                            <br />
                            <br />
                            <Container
                              className="mt-3"
                              style={{ marginBottom: "40px" }}
                            >
                              <Row style={{ marginBottom: "100px" }}>
                                <Col lg={1} md={1} xs={1} sm={1}>
                                  {!isMobile && (
                                    <SliderLeftSvg />
                                  )}
                                </Col>
                                <Col lg={10} md={10} xs={10} sm={10}>
                                  <CenterSilder currencyLabel={currencyLabel} ID_Featured={ID_Featured} handleShow={handleShow}
                                    ProducteDetailsGet={ProducteDetailsGet} language={language} />
                                </Col>
                                <Col lg={1} md={1} xs={1} sm={1}>
                                  {!isMobile && <SliderRightSvg />}
                                </Col>
                              </Row>
                            </Container>
                          </Container>
                        </Card>
                      }
                    </React.Fragment> :
                    <React.Fragment>
                      {(main.products && main.products.length > 0) &&
                        <Card
                          id={`${main?.category_name_EN && main?.category_name_EN
                            .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
                            .replace(/ /g, "")}_${index}`}
                          key={index}
                          style={{ backgroundColor: main?.category_color ? main?.category_color : "rgb(255, 160, 203)" }}
                          className="jvlusT jXIdmS mb-20 card_common_ scroll_section"
                        >
                          {isMobile ? (
                            <Row
                              style={{ marginBottom: "100px" }}
                              className="justify-content-sm-start mx-0"
                            >
                              <br />
                              <br />
                              <Col className="text-center">
                                <h2 className="Popular_this_week">
                                  {main?.category_name_EN
                                    && main?.category_name_EN}
                                </h2>
                                <svg
                                  viewBox="0 0 167 17"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="sc-194a41c1-0 cecmhh"
                                  data-testid="lineAscending"
                                  width="24px"
                                  height="24px"
                                >
                                  <title>Line Ascending</title>
                                  <path d="M0 10.79L30.42 7.91L43.7 6.59L164.5 0L167 6.3L56.21 13.3L48.69 12.47L36.19 14.57H28.81L23.81 15.79L0.49 16.21L0 10.79Z" />
                                </svg>
                                <Row>
                                  <Col lg={3} md={3} xs={12} sm={12}></Col>
                                  <Col lg={6} md={6} xs={12} sm={12} className="text-start">
                                    <div className="discription_">
                                      {(
                                        <DescriptionToggle text={main?.category_description_EN} description={main?.category_description_EN} />
                                      )}
                                    </div>
                                    <div className="discription_">
                                      <span>
                                        {renderDisclaimer(language, main)}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col lg={3} md={3} xs={12} sm={12}></Col>
                                </Row>
                              </Col>
                              <br />
                              {main?.products?.length > 0 ? (
                                main?.products?.map((mainPro, index) => (
                                  <Col className="mt-4" xs={12} md={12} lg={6} key={index}>
                                    <Card
                                      onClick={() => {
                                        handleShow();
                                        ProducteDetailsGet(mainPro);
                                      }}
                                      style={{ height: "100%", width: "100%" }}
                                      className="my-1 card_common_1"
                                    >
                                      <Row style={{ height: "122px" }}>
                                        <Col xs={6} lg={6} md={6}>
                                          <div className="container_mobile_view image-box">
                                            <div className="img-container">
                                              <img
                                                variant="top"
                                                loading="lazy"
                                                height={250}
                                                alt="Product_image"
                                                src={mainPro?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs={6} lg={6} md={6}>
                                          <div
                                            className="slider_title_listview"
                                            style={{ height: "30px", padding: mainPro?.description_EN === undefined && "11px 0px 0px 0px" }}
                                          >
                                            {mainPro?.name_EN
                                              && mainPro?.name_EN}
                                          </div>

                                          <DescriptionToggleProdcutMobile
                                            description={mainPro?.description_EN}
                                          />
                                          <div className="slider_title_listview bHlsoG">
                                            {`${currencyLabel} ${Number(mainPro?.base_price).toFixed(2)}`}
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                ))
                              ) : (
                                <Row className="text-center mt-10">
                                  <Col>
                                    <Card
                                      className="card_common_"
                                      style={{
                                        marginTop: 14,
                                        marginLeft: 20,
                                      }}
                                    >
                                      <h5 className="no_product_available">
                                        No product available
                                      </h5>
                                    </Card>
                                  </Col>
                                </Row>
                              )}
                            </Row>
                          ) : (
                            <Row style={{ marginBottom: "100px" }}>
                              <Col lg={2} md={1} xs={1}>
                                {!isMobile && (
                                  <ProductSideSvg color={main?.category_color} />
                                )}
                              </Col>
                              <Col lg={8} md={10} xs={10}>
                                <br />
                                <br />
                                <Col className="text-center">
                                  <h2 className="Popular_this_week">
                                    {main?.category_name_EN
                                      && main?.category_name_EN}
                                  </h2>
                                  <svg
                                    viewBox="0 0 167 17"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sc-194a41c1-0 cecmhh"
                                    data-testid="lineAscending"
                                    width="24px"
                                    height="24px"
                                  >
                                    <title>Line Ascending</title>
                                    <path d="M0 10.79L30.42 7.91L43.7 6.59L164.5 0L167 6.3L56.21 13.3L48.69 12.47L36.19 14.57H28.81L23.81 15.79L0.49 16.21L0 10.79Z" />
                                  </svg>
                                  <Row>
                                    <Col lg={3} md={1} xs={12}></Col>
                                    <Col
                                      lg={6}
                                      md={10}
                                      xs={12}
                                      className="text-start"
                                    >
                                      <div className="discription_">
                                        <DescriptionToggle text={main?.category_description_EN} description={main?.category_description_EN} />
                                      </div>
                                      <div className="discription_">
                                        <span>
                                          {renderDisclaimer(language, main)}
                                        </span>
                                      </div>
                                    </Col>

                                    <Col lg={3} md={1} xs={12}></Col>
                                  </Row>
                                </Col>
                                <br />
                                <br />
                                <Row>
                                  {main?.products?.length > 0 ? (
                                    main?.products?.map((mainPro, index) => (
                                      <Col
                                        lg={4}
                                        md={6}
                                        xs={12}
                                        key={index}
                                        style={{
                                          padding: 20,
                                        }}
                                      >
                                        <Card
                                          style={{
                                            backgrounColor:
                                              "rgb(255, 255, 255)",
                                            width: "100%",
                                            margin: "0 auto",
                                          }}
                                          className="card_common_1 pointer"
                                          onClick={() => {
                                            handleShow();
                                            ProducteDetailsGet(mainPro);
                                          }}
                                        >
                                          <div className="container_gride_view image-box ">
                                            <div className="img-container ">
                                              <img
                                                variant="top"
                                                loading="lazy"
                                                height={250}
                                                alt="Product_image"
                                                src={mainPro?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"
                                                }
                                              />
                                            </div>
                                          </div>
                                          <Card.Body>
                                            <Card
                                              style={{
                                                // height: 35,
                                                border: 0
                                              }}
                                            >
                                              <Card.Title className="slider_title">
                                                <p
                                                  fontWeight="bold"
                                                  className="sc-7000b91a-0 jKvBNZ"
                                                >
                                                  <span className="sc-57498d00-0 hMTMzH">
                                                    {mainPro?.name_EN
                                                      && mainPro?.name_EN}
                                                  </span>
                                                </p>

                                                <span>
                                                  <DescriptionToggleProdcut
                                                    description={mainPro?.description_EN}
                                                  />
                                                </span>
                                              </Card.Title>
                                            </Card>
                                            <Card
                                              className="jvlusT dJztCp mt-2"
                                              style={{ height: 22, border: 0 }}
                                            >
                                              <Card.Text className="bHlsoG">
                                                {`${currencyLabel} ${Number(mainPro?.base_price).toFixed(2)}`}{" "}
                                              </Card.Text>
                                            </Card>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    ))
                                  ) : (
                                    <React.Fragment>
                                      <Card className="card_common_">
                                        <Row className="text-center">
                                          <Col style={{ padding: 15 }}>
                                            <h4 className="no_product_available">
                                              {" "}
                                              No product available
                                            </h4>
                                          </Col>
                                        </Row>
                                      </Card>
                                    </React.Fragment>
                                  )}
                                </Row>
                              </Col>
                              <Col lg={2} md={1} xs={1}></Col>
                            </Row>
                          )}
                        </Card>
                      }
                    </React.Fragment>
                ))}
              </div>
            </React.Fragment>
          </React.Fragment>

          <Modal
            scrollable={true}
            style={{ overflow: "hidden" }}
            centered
            show={show}
            onHide={handleClose}
            dialogClassName="modal-product-details"
          >
            {Product_detaisBy_id !== "" && (
              <React.Fragment>
                {/* <Modal.Header closeButton style={{ borderBottom: "3px solid #dee2e6" }}>
                  <Modal.Title
                    className="Model_header_name"
                    style={{
                      marginBottom: "5px",
                      marginTop: "6px",
                      marginLeft: "auto",
                    }}
                  >
                    {Product_detaisBy_id?.name_EN && Product_detaisBy_id?.name_EN}
                  </Modal.Title>
                </Modal.Header> */}
                <Card className="card_common_ custom-modal-body" style={{ overflowY: "auto" }}>
                  {!TableNo && SelectOption &&
                    <div className="sc-eb6526a0-0 kNLGCO">
                      <p fontWeight="bold" color="#4C4C4C" className="sc-7000b91a-0 dqObtv">
                        Select Options
                      </p>
                    </div>
                  }

                  <div className="sc-d614dbc0-0 hpdzON ">
                    <button onClick={handleClose} type="button" aria-label="Close" className="sc-81503d4-0 btn-close">
                    </button>
                  </div>

                  <div className="container_Pop_po">
                    <div className="img-container">
                      <img
                        variant="top"
                        loading="lazy"
                        height={250}
                        alt="Product_image"
                        src={Product_detaisBy_id?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
                      />
                    </div>
                  </div>
                  <div className="sc-86d55903-0 dxHGJE"
                  // style={{ marginTop: "-16px" }}
                  >
                    <div className="sc-86d55903-0 bjjsup">
                      <div className="sc-86d55903-0 bhHZiE">
                        <svg
                          width={40}
                          height={71}
                          viewBox="0 0 40 71"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                        >
                          <path d="M-0.169746 70.1709L-0.169748 0.489086L39.6484 0.48909L-0.169746 70.1709Z" fill="#FF6900" />
                        </svg>
                      </div>
                      <div className="sc-86d55903-0 sc-93a5572f-0 cbBucT dJztCp">
                        <h2 className="sc-87747fd2-1 hSRyOb">
                          <span className="sc-86d55903-0 eVQLUW">
                            {Product_detaisBy_id?.name_EN && Product_detaisBy_id?.name_EN} - {currencyLabel} {Number(Product_detaisBy_id?.base_price).toFixed(2)}
                          </span>
                        </h2>
                      </div>
                      <div color="white" className="sc-7000b91a-0 cZXofG">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: isDescriptionExpanded
                              ? Product_detaisBy_id?.description_EN
                              : truncateText(Product_detaisBy_id?.description_EN, 100)
                          }}
                        />{' '}
                        {Product_detaisBy_id?.description_EN === undefined ? "" :
                          <Link
                            style={{ cursor: "pointer", textDecoration: "underline" }}
                            onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                          >
                            {isDescriptionExpanded ? 'Show Less' : 'Show More'}
                          </Link>}
                      </div>
                      <div
                        style={{ overflowX: isMobile ? "auto" : "hidden" }}
                        className=" d-flex m-1 mb-2 justify-content-center"
                      >
                        {Product_detaisBy_id?.properties &&
                          Product_detaisBy_id?.properties.map((item, index) => (
                            <div style={{ width: "70px" }} key={index}>
                              <img
                                className="mx-4"
                                loading="lazy"
                                alt="Product_properties"
                                src={propertyImages[item] || "/assets/media/my/wedding-dinner.png"}
                                style={{ height: "25px", color: "#fff" }}
                              />
                              <p className="mb-1 text-center" style={{ fontSize: "0.70rem", color: "#fff" }}>
                                {item}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  {!TableNo && Product_detaisBy_id?.options?.length > 0 &&
                    Product_detaisBy_id?.options?.map(category => ({
                      ...category,
                      items: category?.items?.filter(item => item?.is_active)
                    }))?.filter(item => item?.items?.length > 0)?.map((addon, addonIndex) => (
                      <React.Fragment key={addonIndex}>
                        <Container>
                          <Row className="my-2 mb-3">
                            <Col className="Add-on-title" lg={12} xs={12} md={12}>
                              {addon?.title}{" "}
                              {addon?.is_required && <Chip className="chip_" label="Required" />}
                            </Col>
                          </Row>
                          {addon?.items?.length > 0 &&
                            addon?.items
                              ?.filter((word) => word.is_active)
                              ?.map((item, itemIndex) => (
                                <Row key={itemIndex}>
                                  <Col className="Add-on-title_key" lg={8} xs={8} md={8}>
                                    <Checkbox
                                      checked={
                                        selectedAddons[addonIndex]?.includes(itemIndex) || false
                                      }
                                      onChange={(e) =>
                                        handleChange(
                                          addonIndex,
                                          itemIndex,
                                          e.target.checked,
                                          item.price,
                                          item
                                        )
                                      }
                                      sx={{
                                        color: "#D0D5DD",
                                        padding: "2px",
                                        "&.Mui-checked": {
                                          color: customRes?.button_colour,
                                        },
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 22,
                                        },
                                      }}
                                    />{" "}
                                    {item.name}
                                  </Col>
                                  <Col className="Add-on-title_value" lg={4} xs={4} md={4}>
                                    {`${currencyLabel} ${Number(item?.price || 0).toFixed(2)}`}
                                  </Col>
                                </Row>
                              ))}
                        </Container>
                        <div className="mb-2" />
                      </React.Fragment>
                    ))}

                  <Container >
                    <Row className="text-center">
                      {Product_detaisBy_id?.title_for_additional_products &&
                        <Col lg={12} xs={12} md={12}>
                          <h2 className="make_a_meal">{Product_detaisBy_id?.title_for_additional_products || ""}</h2>
                          <svg
                            viewBox="0 0 167 17"
                            xmlns="http://www.w3.org/2000/svg"
                            className="sc-194a41c1-0 cecmhh"
                            data-testid="lineAscending"
                            width="24px"
                            height="24px"
                          >
                            <title>Line Ascending</title>
                            <path d="M0 10.79L30.42 7.91L43.7 6.59L164.5 0L167 6.3L56.21 13.3L48.69 12.47L36.19 14.57H28.81L23.81 15.79L0.49 16.21L0 10.79Z" />
                          </svg>
                        </Col>}
                      {Product_detaisBy_id?.description_for_additional_products &&
                        <Col className="make_a_meal_discription" lg={12} xs={12} md={12}>
                          {Product_detaisBy_id?.description_for_additional_products || ""}
                        </Col>}
                    </Row>
                    {/* {console.log("Product_detaisBy_id" ,Product_detaisBy_id)} */}
                    {!TableNo && Product_detaisBy_id?.additional_products?.length > 0 &&
                      Product_detaisBy_id?.additional_products?.map(category => ({
                        ...category,
                        product_list: category?.product_list?.filter(item => item?.is_active)
                      }))?.filter(item => item?.product_list?.length > 0)?.map((category, categoryIndex) => (
                        <React.Fragment key={categoryIndex}>
                          <Row className="mt-3">
                            <div className="meal_title mb-1" >
                              {category.title}
                            </div>
                            {category?.description &&
                            <div className="meal_discription" >
                              {category?.description || ""}
                            </div>}
                          </Row>
                          {category?.product_list?.map((item, itemIndex) => (
                            <Row className="mt-3" key={itemIndex}>
                              <Col className="Add-on-title_key" lg={8} xs={8} md={8}>
                                <Checkbox
                                  checked={selectedItems?.some((i) => i._id === item._id)}
                                  onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                                  sx={{
                                    color: "#D0D5DD",
                                    padding: "2px",
                                    "&.Mui-checked": {
                                      color: customRes?.button_colour,
                                    },
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 22,
                                    },
                                  }}
                                />{" "}
                                <img
                                  variant="top"
                                  loading="lazy"
                                  alt="meal_image"
                                  className="meal_image"
                                  src={item.image_url && item.image_url !== null ? item?.image_url : "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
                                />
                                <span className="meal_name">{item?.name}</span>
                              </Col>
                              <Col className="Add-on-title_value mt-4" lg={4} xs={4} md={4}>
                                {`+ ${currencyLabel} ${Number(item?.price || 0).toFixed(2)}`}
                              </Col>
                            </Row>
                          ))}
                        </React.Fragment>
                      ))}

                  </Container>
                  <div className="mb-2" />
                </Card>
                <Modal.Footer style={{ padding: "5px" }}>
                  <Container>
                    <Row className="mb-2">
                      <Col lg={8} xs={8} md={8} className="text-start">
                        <span className="qut_price">
                          Qty :
                          <RemoveCircleOutlineIcon
                            className="pointer"
                            onClick={decrement_quantity}
                            style={{
                              marginRight: "12px",
                              marginLeft: "12px",
                              color: "rgba(48, 50, 59, 0.73)",
                            }}
                          />
                          &nbsp;
                          <button
                            style={{ border: 0, marginLeft: "-11px" }}
                            className="btn btn-outline"
                          >
                            {quantity}
                          </button>{" "}
                          &nbsp;
                          <AddCircleOutlineIcon
                            className="pointer"
                            onClick={increment_quantity}
                            style={{
                              marginLeft: "0px",
                              color: "rgba(48, 50, 59, 0.73)",
                            }}
                          />
                        </span>
                      </Col>
                      <Col lg={4} xs={4} md={4} className="text-end mt-2 qut_price notranslate">
                        {currencyLabel} {totalPrice.toFixed(2)}
                      </Col>
                    </Row>
                    {errorDivAdd_Order &&
                      <div className="col-md-12 mt-3">
                        {errorDivAdd_Order}
                      </div>}
                    {LoadingAddOrder ? 
                    <Box sx={{ display: 'flex' , justifyContent : 'center' ,alignItems :"center"}}>
                    <CircularProgress />
                        </Box> :
                    <Button
                      style={{
                        backgroundColor: isAddToCartDisabled ? "rgb(233, 233, 233)" : customRes?.button_colour ?? "#dc3545",
                        fontWeight: "bolder",
                        width: "100%",
                        cursor: "pointer",
                        height: 40,
                        fontSize: 19
                      }}
                      className="text-center custom_hover"
                      onClick={() => Add_Order()}
                      disabled={isAddToCartDisabled}
                    >
                      <strong
                        style={{
                          color: isAddToCartDisabled ? "rgb(185, 185, 185)" : customRes?.non_highlighted_text ?? "white",
                        }}
                      >Add To Cart </strong>
                    </Button> }
                  </Container>
                </Modal.Footer>
              </React.Fragment>
            )}
          </Modal>

          <Offcanvas
            style={{
              width: isMobile ? "360px" : "510px",
            }}
            placement="end"
            show={showDrawer}
            onHide={handleCloseDrawer}
          >
            {TableNo ? (
              <React.Fragment>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title
                    // style={{ marginLeft: isMobile ? "22px" : "100px" }}
                    className="drawer_titel_for_dine"
                  >
                    {`Your Order`}
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Divider></Divider>
                <Divider></Divider>
                <Divider></Divider>

                <Offcanvas.Body
                  style={{ overflowY: isMobile ? "auto" : "hideen" }}
                >
                  <Row style={{ padding: "0px 0px 0px 10px" }}>
                    <Col>
                      <h6
                        className="Table_no_"
                        style={{
                          margin: "-2px auto 26px -8px",
                          fontSize: "23px",
                        }}
                      >
                        Table No - {TableNo}
                      </h6>
                    </Col>
                  </Row>
                  <Row
                    style={{ padding: "11px 0px 2px 8px", marginTop: "-25px" }}
                  >
                    <Col
                      lg={8}
                      md={8}
                      xs={8}
                      style={{
                        backgroundColor: customRes?.button_colour ?? "#dc3545",
                        padding: "12px 0px 2px 14px",
                      }}
                    >
                      {DineInTimeData_ ? (
                        <h6
                          className="dine_nin_no"
                          style={{
                            color: customRes?.non_highlighted_text ?? "white",
                          }}
                        >
                          Dine-in start at {DineInTimeData_}
                        </h6>
                      ) : (
                        <h6
                          className="dine_nin_no"
                          style={{
                            color: customRes?.non_highlighted_text ?? "white",
                          }}
                        >
                          Dine-in
                        </h6>
                      )}
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      xs={4}
                      style={{
                        backgroundColor: customRes?.button_colour ?? "#dc3545",
                        padding: "12px 5px 2px 0px",
                      }}
                      className="text-end"
                    >
                      <h6
                        className="Table_no_"
                        style={{
                          color: customRes?.non_highlighted_text ?? "white",
                        }}
                      >
                        {currencyLabel}{" "}
                        {Number(
                          FinalAmount?.final_amount
                            ? FinalAmount?.final_amount
                            : 0
                        ).toFixed(2)}
                      </h6>
                    </Col>
                  </Row>
                  {GetCartForCheckoutLatesOrder ? (
                    <React.Fragment>
                      <Row style={{ margin: "-9px 0px -4px -5px" }}>
                        <div className="Items_Your">
                          {"Your New Order"}
                        </div>
                      </Row>
                      <Row
                        style={{
                          padding: "0px 5px 6px 8px",
                        }}
                      >
                        {GetCartForCheckoutLatesOrder ? (
                          GetCartForCheckoutLatesOrder?.items?.length > 0 ? (
                            GetCartForCheckoutLatesOrder?.items?.map(
                              (main, index) => (
                                <React.Fragment key={index}>
                                  <Col
                                    lg={12}
                                    md={12}
                                    xs={12}
                                    className="product_Name my-2"
                                  >
                                    {main?.name_EN
                                      && main?.name_EN}
                                  </Col>
                                  <Col lg={4} md={4} xs={4} className="mt-1">
                                    <div className="product_Name_Chf">
                                      {currencyLabel}{" "}
                                      <span className="product_Name_Chf">
                                        {Number(main?.base_price).toFixed(2)}
                                      </span>
                                    </div>
                                  </Col>

                                  <Col
                                    lg={8}
                                    md={8}
                                    xs={8}
                                    className="text-end mt-1"
                                  >
                                    <span className="qut_price_chf">
                                      <RemoveCircleIcon
                                        className="pointer"
                                        onClick={(e) =>
                                          decrement_quantity_CheckOut(
                                            e,
                                            main?._id,
                                            main?.quantity
                                          )
                                        }
                                        style={{
                                          marginRight: "6px",
                                          color: "rgba(48, 50, 59, 0.73)",
                                        }}
                                      />
                                      &nbsp;
                                      {main?.quantity}
                                      &nbsp;
                                      <AddCircleIcon
                                        className="pointer"
                                        onClick={(e) =>
                                          increment_quantity_CheckOut(
                                            e,
                                            main?._id,
                                            main?.quantity
                                          )
                                        }
                                        style={{
                                          marginLeft: "6px",
                                          color: "rgba(48, 50, 59, 0.73)",
                                        }}
                                      />
                                      &nbsp;&nbsp; {currencyLabel}{" "}
                                      <span className="">
                                        {Number(main?.total_price).toFixed(2)}
                                      </span>
                                    </span>
                                  </Col>
                                  <div >
                                    {ErrorSendOrderTimeDine?.length > 0 ? ErrorSendOrderTimeDine?.includes(main?.product_id)
                                      ?
                                      <div style={{ marginBottom: "12px" }}>
                                        <ErrorOutlineIcon style={{ color: "#facea8" }} />
                                        <span className="Check_error_compare mx-2" >
                                          {"This item is not available"}
                                        </span>
                                      </div>
                                      : "" : ""}
                                  </div>
                                  <br />
                                </React.Fragment>
                              )
                            )
                          ) : (
                            <React.Fragment>
                              <Row className="text-center">
                                <Col style={{ padding: 50 }}>
                                  <img
                                    src="/assets/media/avatars/Clip path group.png"
                                    alt="Empty_cart"
                                    loading="lazy"
                                    height="55"
                                  />
                                  <span className="Empty_cart mx-3">
                                    {" "}
                                    Empty Cart
                                  </span>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                        ) : (
                          ""
                        )}
                      </Row>
                      {GetCartForCheckoutLatesOrder?.items?.length > 0 ?
                        GetCartForCheckoutLatesOrder?.order_notes_active ==
                          true ? (
                          <Row className="mt-4">
                            <InputGroup>
                              <InputGroup.Text>
                                <img
                                  alt="Logo"
                                  loading="lazy"
                                  src="/assets/media/my/notes.png"
                                  style={{
                                    height: "50px",
                                  }}
                                />
                              </InputGroup.Text>

                              <Form.Control
                                as="textarea"
                                style={{
                                  color: "#5a5a5a",
                                  borderRadius: "2px",
                                  border: "1px solid rgb(212 209 209)",
                                }}
                                placeholder={"Order Notes"}
                                aria-label="Order Comments"
                                value={order_notes}
                                onChange={(e) => setorder_notes(e.target.value)}
                              />
                            </InputGroup>
                          </Row>
                        ) : null : null}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Row style={{ margin: "-9px 0px -4px -5px" }}>
                        <div className="Items_Your">
                          {"Your New Order"}
                        </div>
                      </Row>
                      <Row className="text-center">
                        <Col style={{ padding: 50 }}>
                          <img
                            loading="lazy"
                            src="/assets/media/avatars/Clip path group.png"
                            alt="Empty_cart"
                            height="55"
                          />
                          <span className="Empty_cart mx-3"> Empty Cart</span>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}

                  <Row className="my-3">
                    <Col>
                      {GetCartForCheckoutLatesOrder?.items?.length > 0 && (
                        GetCartForCheckoutOldOrder?.length > 0 && (
                          <React.Fragment>
                            <div className="col-md-12 mt-3">
                              {" "}
                              {errorDivSendOrder}{" "}
                            </div>
                            <Button
                              variant="dark"
                              type="submit"
                              size="lg"
                              onClick={(e) => send_Order(e)}
                              style={{
                                width: "100%",
                                backgroundColor:
                                  customRes?.button_colour ?? "#dc3545",
                                border: 0,
                                marginBottom: "10px",
                              }}
                              className="custom_hover"
                            >
                              <strong
                                style={{
                                  color:
                                    customRes?.non_highlighted_text ?? "white",
                                  fontFamily: "'Calibri', sans-serif",
                                  letterSpacing: "1.1px",
                                }}
                              >
                                {"Send order"}
                              </strong>
                            </Button>
                          </React.Fragment>
                        ))}
                    </Col>
                  </Row>

                  <br />

                  <Container>
                    <Row style={{ backgroundColor: "rgb(243, 244, 246)" }}>
                      {GetCartForCheckoutOldOrder?.length > 0
                        && GetCartForCheckoutOldOrder?.map((main, index) => (
                          <React.Fragment key={index}>
                            <Col
                              style={{ padding: "5px 0px 9px 12px" }}
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              {main?.orderItemDetails?.length > 0 && (
                                <div className="Items_Added">
                                  Order no.{main?.order_no} {moment(main?.created_at).tz(FinalAmount?.branchData?.branchTimeZone).format('h:mm A')}
                                  {/* {console.log("FinalAmount" ,FinalAmount?.branchData?.branchTimeZone)} */}
                                  {/* {moment(main?.created_at).format("h:mm a")} */}
                                </div>
                              )}
                            </Col>
                            {main?.orderItemDetails?.length > 0
                              && main?.orderItemDetails?.map(
                                (main2, index) => (
                                  <React.Fragment>
                                    <Row key={index} className="my-1">
                                      <Col lg={1} md={1} xs={1}>
                                        <div className="text-start check_out_div">
                                          <img
                                            loading="lazy"
                                            src="/assets/media/avatars/Check.svg"
                                            alt="check"
                                            className="Check_"
                                          />
                                        </div>
                                      </Col>
                                      <Col lg={11} md={11} xs={11}>
                                        <div className="product_Name_table">
                                          {main2?.name_EN
                                            && main2?.name_EN}


                                          {main2?.is_pickup === true &&
                                            <span className='mx-1'>
                                              <img alt="" loading='lazy' style={{ height: 17 }} src="/assets/media/avatars/take-away1 1.png" />
                                            </span>}
                                          {main2?.is_custom === true &&
                                            <span className='mx-1'>
                                              <img alt="" loading='lazy' style={{ height: 19 }} src="/assets/media/avatars/CustomDish.png" />
                                            </span>
                                          }
                                        </div>
                                        <Row>
                                          <Col lg={5} md={5} xs={5}>
                                            <div className="tabel_chf">
                                              {currencyLabel}{" "}
                                              {Number(
                                                main2?.base_price
                                              ).toFixed(2)}
                                            </div>
                                          </Col>
                                          <Col
                                            lg={7}
                                            md={7}
                                            xs={7}
                                            className="tabel_chf_count text-center"
                                          >
                                            x{main2?.quantity}{" "}
                                            &nbsp;&nbsp;&nbsp; {currencyLabel}{" "}
                                            {Number(
                                              main2?.total_price
                                            ).toFixed(2)}
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Container>
                                      <hr
                                        style={{
                                          border: "1px solid black",
                                        }}
                                      />
                                    </Container>
                                  </React.Fragment>
                                )
                              )
                            }
                          </React.Fragment>
                        ))
                      }
                    </Row>
                    {CancelOrder?.length > 0 ? (
                      <Row
                        style={{ backgroundColor: "rgb(243, 244, 246)" }}
                        className="my-4"
                      >
                        <Col
                          className="cancelled"
                          style={{ padding: "5px 0px 9px 12px" }}
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          This order has been cancelled.
                        </Col>
                        <Container>
                          <hr
                            style={{
                              border: "1px solid black",
                              margin: "10px 0px 3px 0px",
                            }}
                          />
                        </Container>
                        {CancelOrder?.map((main, index) => (
                          <Col key={index} lg={12} md={12} xs={12}>
                            <div
                              className="Items_Added_Canceeel"
                              style={{ marginBottom: 15 }}
                            >
                              Order no.{main?.order_no}{" "}
                              {moment(main?.created_at).format("h:mm a")}
                            </div>
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      ""
                    )}
                  </Container>
                </Offcanvas.Body>
                <Container className="my-3">
                  {GetCartForCheckoutLatesOrder?.items?.length > 0 ? (
                    <Row>
                      <Col>
                        <Container>
                          {GetCartForCheckoutOldOrder?.length > 0 ? (
                            ""
                          ) : (
                            <React.Fragment>
                              <div className="col-md-12 mt-3">
                                {errorDivSendOrder}{" "}
                              </div>
                              <Button
                                variant="dark"
                                type="submit"
                                size="lg"
                                // disabled={GetCartForCheckoutLatesOrder?.items?.length > 0 ? false  : true}
                                onClick={(e) => send_Order(e)}
                                style={{
                                  width: "100%",
                                  backgroundColor:
                                    customRes?.button_colour ?? "#dc3545",
                                  border: 0,
                                  marginBottom: "10px",
                                }}
                                className="custom_hover"
                              >
                                <strong
                                  style={{
                                    color:
                                      customRes?.non_highlighted_text ??
                                      "white",
                                    fontFamily: "'Calibri', sans-serif",
                                    letterSpacing: "1.1px",
                                  }}
                                >
                                  {"Send order"}
                                </strong>
                              </Button>
                              <Button
                                variant="dark"
                                type="submit"
                                size="lg"
                                // disabled={GetCartForCheckoutLatesOrder?.items?.length > 0 ? true  : false}
                                // onClick={(e) => errorDivpromo  ? "" : Check_Out_detailes(e)}
                                onClick={(e) => {
                                  history.push(`/${urlBranchID}/cartpage?no=${TableNo}&sessionId=${sessionId}`)
                                  // window.location.reload(true);
                                }
                                }
                                style={{
                                  width: "100%",
                                  backgroundColor:
                                    customRes?.button_colour ?? "#dc3545",
                                  border: 0,
                                  marginBottom: "10px",
                                }}
                                className="custom_hover"
                              >
                                <strong
                                  style={{
                                    color:
                                      customRes?.non_highlighted_text ??
                                      "white",
                                    fontFamily: "'Calibri', sans-serif",
                                    letterSpacing: "1.1px",
                                  }}
                                >
                                  {"Pay"}{" "}
                                  - {currencyLabel}{" "}
                                  {(Number(FinalAmount?.remaining_amount) || 0).toFixed(2)}
                                </strong>
                              </Button>
                            </React.Fragment>
                          )}

                          {GetCartForCheckoutOldOrder?.length > 0 ? (
                            <Button
                              variant="dark"
                              type="submit"
                              size="lg"
                              // disabled={GetCartForCheckoutLatesOrder?.items?.length > 0 ? true  : false}
                              // onClick={(e) => errorDivpromo  ? "" : Check_Out_detailes(e)}
                              onClick={(e) => {
                                history.push(`/${urlBranchID}/cartpage?no=${TableNo}&sessionId=${sessionId}`)
                                //  window.location.reload(true);
                              }
                              }
                              // onClick={(e) => send_Order(e)}
                              style={{
                                width: "100%",
                                backgroundColor:
                                  customRes?.button_colour ?? "#dc3545",
                                border: 0,
                                marginBottom: "10px",
                              }}
                              className="custom_hover"
                            >
                              <strong
                                style={{
                                  color:
                                    customRes?.non_highlighted_text ?? "white",
                                  fontFamily: "'Calibri', sans-serif",
                                  letterSpacing: "1.1px",
                                }}
                              >
                                {"Pay"}{" "}
                                - {currencyLabel}{" "}
                                {Number(FinalAmount?.remaining_amount || 0).toFixed(2)
                                }
                              </strong>
                            </Button>
                          ) : null}
                        </Container>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {GetCartForCheckoutOldOrder?.length > 0 ? (
                    GetCartForCheckoutLatesOrder?.items?.length > 0 ? (
                      ""
                    ) : (
                      <Row>
                        <Col>
                          <Container>
                            <div className="col-md-12 mt-3">
                              {" "}
                              {errorDivSendOrder}{" "}
                            </div>
                            {GetCartForCheckoutOldOrder?.length > 0 ? (
                              ""
                            ) : (
                              <React.Fragment>
                                <Button
                                  variant="dark"
                                  type="submit"
                                  size="lg"
                                  onClick={(e) => send_Order(e)}
                                  style={{
                                    width: "100%",
                                    backgroundColor:
                                      customRes?.button_colour ?? "#dc3545",
                                    border: 0,
                                    marginBottom: "10px",
                                  }}
                                  className="custom_hover"
                                >
                                  <strong
                                    style={{
                                      color:
                                        customRes?.non_highlighted_text ??
                                        "white",
                                      fontFamily: "'Calibri', sans-serif",
                                      letterSpacing: "1.1px",
                                    }}
                                  >
                                    {"Send order"}
                                  </strong>
                                </Button>
                                <Button
                                  variant="dark"
                                  type="submit"
                                  size="lg"
                                  // disabled={GetCartForCheckoutLatesOrder?.items?.length > 0 ? true  : false}
                                  // onClick={(e) => errorDivpromo  ? "" : Check_Out_detailes(e)}
                                  onClick={(e) => {
                                    history.push(`/${urlBranchID}/cartpage?no=${TableNo}&sessionId=${sessionId}`)
                                    // window.location.reload(true);
                                  }
                                  }
                                  // onClick={(e) => send_Order(e)}
                                  style={{
                                    width: "100%",
                                    backgroundColor:
                                      customRes?.button_colour ?? "#dc3545",
                                    border: 0,
                                    marginBottom: "10px",
                                  }}
                                  className="custom_hover"
                                >
                                  <strong
                                    style={{
                                      color:
                                        customRes?.non_highlighted_text ??
                                        "white",
                                      fontFamily: "'Calibri', sans-serif",
                                      letterSpacing: "1.1px",
                                    }}
                                  >
                                    {"Pay"}{" "}
                                    - {currencyLabel}{" "}
                                    {Number(FinalAmount?.remaining_amount || 0).toFixed(2)}
                                  </strong>
                                </Button>
                              </React.Fragment>
                            )}

                            {GetCartForCheckoutOldOrder?.length > 0 ? (
                              <Button
                                variant="dark"
                                type="submit"
                                size="lg"
                                onClick={(e) => {
                                  history.push(`/${urlBranchID}/cartpage?no=${TableNo}&sessionId=${sessionId}`)
                                  // window.location.reload(true);
                                }
                                }
                                style={{
                                  width: "100%",
                                  backgroundColor:
                                    customRes?.button_colour ?? "#dc3545",
                                  border: 0,
                                  marginBottom: "10px",
                                }}
                                className="custom_hover"
                              >
                                <strong
                                  style={{
                                    color:
                                      customRes?.non_highlighted_text ??
                                      "white",
                                    fontFamily: "'Calibri', sans-serif",
                                    letterSpacing: "1.1px",
                                  }}
                                >

                                  Pay - {currencyLabel} {Number(FinalAmount?.remaining_amount || 0).toFixed(2)}
                                </strong>
                              </Button>
                            ) : null}
                          </Container>
                        </Col>
                      </Row>
                    )
                  ) : (
                    ""
                  )}
                </Container>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title
                    className="drawer_titel"
                  >
                    {"Your Order"}
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Divider></Divider>
                <Divider></Divider>
                <Divider></Divider>

                <Offcanvas.Body
                  style={{ overflowY: isMobile ? "auto" : "hideen" }}
                >
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <h4 className="Perfection_Method mb-4">
                        {"How do you want to get your food?"}
                      </h4>
                    </Col>
                    <Col lg={12} md={12} xs={12} className="text-center">
                      <ButtonGroup
                        style={{ width: "inherit" }}
                        variant="outlined"
                        aria-label="outlined button group"
                        className="mb-2"
                      >
                        {BranchDetails_?.is_delivery && (
                          <ButtonMUi
                            className={Mood === "delivery" ? "button_name1_delivery" : "button_name1"}
                            style={{
                              width: "inherit",
                              color: Mood === "delivery" ? customRes?.non_highlighted_text : "",
                              backgroundColor: Mood === "delivery" ? customRes?.button_colour : "",
                            }}
                            disabled={is_restricted === "true" || QrCodeScanAndRedirect === "true" ? true : false}
                            onClick={() => {
                              setErrorCheckOut(null);
                              setMood("delivery");
                              setErrorPromo(null);
                              setErrorCheckOutTimeComper([]);
                            }}
                          >
                            Delivery
                          </ButtonMUi>
                        )}

                        {BranchDetails_?.is_pickup && (
                          <ButtonMUi
                            className={Mood === "pickup" ? "button_name1_Pickup" : "button_name1"}
                            style={{
                              width: "inherit",
                              borderColor: Mood === "pickup" ? "#445a82" : "",
                              color: Mood === "pickup" ? customRes?.non_highlighted_text : "",
                              backgroundColor: Mood === "pickup" ? customRes?.button_colour : "",
                            }}

                            disabled={is_restricted === "true" || QrCodeScanAndRedirect === "true" ? true : false}
                            onClick={() => {
                              setErrorCheckOut(null);
                              setMood("pickup");
                              setErrorPromo(null);
                              setErrorCheckOutTimeComper([]);
                            }}
                          >
                            Pickup
                          </ButtonMUi>
                        )}
                        {BranchDetails_?.is_delivery === false && BranchDetails_?.is_pickup === false && (
                          <Row className="text-center ccc">
                            <Col>
                              Apologies, We aren’t providing delivery or pick up at the moment. We will start accepting orders after some time until then enjoy your meal at the restaurant.
                            </Col>
                          </Row>
                        )}
                      </ButtonGroup>
                    </Col>
                    {Mood && (
                      <Row style={{ marginBottom: 20 }}>
                        <Col className="Prepraed_Time pt-2" lg={12} md={12} xs={12}>
                          {Mood === "delivery"
                            ? "Est Delivery : 50 - 70m"
                            : Mood === "pickup" ? "Ready for pick up: 20 - 30m" : ""}
                        </Col>
                      </Row>
                    )}
                    {Mood === "delivery" && (
                      <Col lg={12} md={12} xs={12}>
                        <Form>
                          <Form.Group className="mb-3">
                            <>
                              <PlacesAutocomplete
                                value={address_line1}
                                onChange={handleChangeAddress}
                                onSelect={handleSelect}
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading,
                                }) => (
                                  <div>
                                    <Form.Group>
                                      <InputGroup className="mb-3">
                                        <Form.Control
                                          id="inlineFormInputGroupUsername"
                                          style={{
                                            width: "83%",
                                            height: "45px",
                                            color: "#5a5a5a",
                                            borderRadius: "2px",
                                            border: "1px solid #ededed",
                                          }}
                                          {...getInputProps({
                                            placeholder: "Add your address  ... ",
                                            className: "location-search-input",
                                          })}
                                          disabled={is_restricted === "true" || QrCodeScanAndRedirect === "true" ? true : false}
                                        />
                                        <TooltipMui title="Current Location">
                                          <InputGroup.Text>
                                            <LocationOnIcon />
                                          </InputGroup.Text>
                                        </TooltipMui>
                                      </InputGroup>
                                    </Form.Group>

                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion, index) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                            backgroundColor: "#C2DEDC",
                                            cursor: "pointer",
                                          }
                                          : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                        return (
                                          <div
                                            key={index}
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style,
                                              }
                                            )}
                                          >
                                            <div
                                              // onKeyDown={onKeyDown}
                                              value={address_line1}
                                              onClick={handleChangeAddress}
                                              onSelect={handleSelect}
                                            >
                                              {suggestion.description}
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                              {errorDivpromo && (
                                <div
                                  className="text-start my-2"
                                  style={{
                                    color: "#FF0000",
                                    alignSelf: "start",
                                    fontSize: 14,
                                    marginLeft: 10,
                                  }}
                                >
                                  {errorDivpromo}
                                </div>
                              )}
                            </>
                          </Form.Group>
                          <Form.Group className="mb-1">
                            <Form.Control
                              type="text"
                              style={{
                                width: "100%",
                                height: "45px",
                                color: "#5a5a5a",
                                borderRadius: "2px",
                                border: "1px solid #ededed",
                              }}
                              disabled={is_restricted === "true" || QrCodeScanAndRedirect === "true" ? true : false}
                              placeholder={
                                "Address line"
                              }
                              value={address_line2}
                              onChange={(e) => setaddress_line2(e.target.value)}
                            />
                          </Form.Group>
                        </Form>
                      </Col>
                    )}
                  </Row>

                  {Mood && (
                    Mood === "delivery" ? (
                      <React.Fragment>
                        <br />
                        <Row>
                          <Col lg={12} md={12} xs={12}>
                            <h4 className="Perfection_Method">
                              {"Delivery Date & Time"}
                            </h4>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "12px" }}>
                          <Col
                            className="text-start Select_date_time"
                            lg={5}
                            md={5}
                            xs={5}
                          >
                            {"Select Date"}
                          </Col>
                          <Col lg={7} md={7} xs={7}>
                            <Select
                              {...selectOption}
                              isDisabled={is_restricted === "true" || QrCodeScanAndRedirect === "true" ? true : false}
                              value={delivery_order_dateA}
                              options={
                                DeliveryDays?.dayList &&
                                DeliveryDays?.dayList?.map((day) => ({
                                  label: day.label,
                                  value: day.label,
                                  id: day.id
                                }))
                              }
                              onChange={(opt) => {
                                const index = DeliveryDays?.dayList?.findIndex(day => day.label === opt.label);

                                if (opt.value.includes("TODAY")) {
                                  setdelivery_order_date(
                                    moment().format()
                                  );
                                } else if (opt.value.includes("TOMMORROW")) {
                                  const tomorrowDate = moment().add(1, 'day');
                                  setdelivery_order_date(
                                    tomorrowDate.format()
                                  );
                                } else {
                                  setdelivery_order_date(
                                    moment(opt.value).format()
                                  );
                                }
                                //  Number(opt.id - 1)
                                // console.log("opt" ,opt?.id)
                                setIndexForDelievryDate(index)
                                setdelivery_order_dateA(opt);
                                setdelivery_order_time("");
                                setdelivery_order_timeA("");

                              }}
                            />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "12px" }}>
                          <Col
                            className="text-start Select_date_time"
                            lg={5}
                            md={5}
                            xs={5}
                          >
                            {"Select Time"}
                          </Col>
                          <Col lg={7} md={7} xs={7}>
                            <Select
                              isDisabled={is_restricted === "true" || QrCodeScanAndRedirect === "true" ? true : false}
                              {...selectOption}
                              options={
                                DeliveryTimeList[IndexForDelievryDate] &&
                                DeliveryTimeList[IndexForDelievryDate]?.map((time) => ({
                                  label: time,
                                  value: time,
                                }))
                              }
                              onChange={(opt) => {
                                setdelivery_order_time(opt.label);
                                setdelivery_order_timeA(opt);
                              }}
                              value={delivery_order_timeA}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Row style={{ marginTop: "10px" }}>
                          <Col lg={12} md={12} xs={12}>
                            <h4 className="Perfection_Method">
                              {"Pickup Date & Time"}
                            </h4>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "12px" }}>
                          <Col
                            className="text-start Select_date_time"
                            lg={5}
                            md={5}
                            xs={5}
                          >
                            {"Select Date"}
                          </Col>
                          <Col lg={7} md={7} xs={7}>
                            <Select
                              {...selectOption}
                              options={
                                PickupDays?.dayList &&
                                PickupDays?.dayList?.map((day) => ({
                                  label: day.label,
                                  value: day.label,
                                  id: day.id
                                }))
                              }
                              isDisabled={is_restricted === "true" || QrCodeScanAndRedirect === "true" ? true : false}
                              value={pickup_order_dateA}
                              onChange={(opt) => {
                                const index = PickupDays?.dayList?.findIndex(day => day.label === opt.label);

                                if (opt.value.includes("TODAY")) {
                                  setpickup_order_date(
                                    moment().format()
                                  );
                                } else if (opt.value.includes("TOMMORROW")) {
                                  const tomorrowDate = moment().add(1, 'day');
                                  setpickup_order_date(
                                    tomorrowDate.format()
                                  );
                                } else {
                                  setpickup_order_date(
                                    moment(opt.value).format()
                                  );
                                }
                                setIndexForPickupDate(index);
                                setpickup_order_time("");
                                setpickup_order_timeA("");
                                setpickup_order_dateA(opt);

                              }}
                            />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "12px" }}>
                          <Col
                            className="text-start Select_date_time"
                            lg={5}
                            md={5}
                            xs={5}
                          >
                            {"Select Time"}
                          </Col>
                          <Col lg={7} md={7} xs={7}>
                            <Select
                              isDisabled={is_restricted === "true" || QrCodeScanAndRedirect === "true" ? true : false}
                              {...selectOption}
                              options={
                                PickupTimeList[IndexForPickupDate] &&
                                PickupTimeList[IndexForPickupDate]?.map((time) => ({
                                  label: time,
                                  value: time,
                                }))
                              }
                              value={pickup_order_timeA}
                              onChange={(opt) => {
                                setpickup_order_time(opt.label);
                                setpickup_order_timeA(opt);
                              }}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )
                  )}


                  <hr></hr>
                  {GetCartForCheckout ? (
                    <React.Fragment>
                      <div>
                        <Row className="mb-3" style={{ marginLeft: "2px" }}>
                          <div className="Items_Added">
                            {"Your items"}
                          </div>
                        </Row>
                        <Row
                          style={{
                            padding: "0px 5px 6px 7px",
                          }}
                        >
                          {GetCartForCheckout ? (
                            GetCartForCheckout?.items?.length > 0 ? (
                              GetCartForCheckout?.items?.map((main, index) => (
                                <React.Fragment key={index}>
                                  <Col
                                    lg={7}
                                    md={7}
                                    xs={7}
                                  >
                                    <div className="product_Name mb-2">
                                      {main?.name_EN
                                        && main?.name_EN}
                                    </div>
                                    {main?.options?.length > 0 && main?.options?.map((main22, index22) => (
                                      <div className="options_" key={index22}>
                                        + {main22?.name}
                                      </div>
                                    ))}

                                    <div >
                                      {ErrorCheckOutTimeComper?.length > 0 && ErrorCheckOutTimeComper?.includes(main?.product_id)
                                        &&
                                        <React.Fragment>
                                          <ErrorOutlineIcon style={{ color: "#facea8" }} />
                                          <span className="Check_error_compare mx-1" >
                                            {"This item is not available"}
                                          </span>
                                        </React.Fragment>
                                      }
                                    </div>
                                  </Col>
                                  <Col
                                    lg={5}
                                    md={5}
                                    xs={5}
                                    className="text-end"
                                  >
                                    <span className="qut_price">
                                      <RemoveCircleOutlineIcon
                                        className="pointer"
                                        onClick={(e) =>
                                          decrement_quantity_CheckOut(
                                            e,
                                            main?._id,
                                            main?.quantity
                                          )
                                        }
                                        style={{
                                          marginRight: "12px",
                                          marginLeft: "12px",
                                          color: "rgba(48, 50, 59, 0.73)",
                                        }}
                                      />
                                      &nbsp;
                                      <button
                                        style={{
                                          border: 0,
                                          marginLeft: "-11px",
                                        }}
                                        className="btn btn-outline"
                                      >
                                        {main?.quantity}
                                      </button>{" "}
                                      &nbsp;
                                      <AddCircleOutlineIcon
                                        className="pointer"
                                        onClick={(e) =>
                                          increment_quantity_CheckOut(
                                            e,
                                            main?._id,
                                            main?.quantity
                                          )
                                        }
                                        style={{
                                          marginLeft: "0px",
                                          color: "rgba(48, 50, 59, 0.73)",
                                        }}
                                      />
                                    </span>
                                    <div className="product_price12 notranslate">
                                      {currencyLabel}{" "}
                                      <span className="price_amout">
                                        {Number(main?.total_price).toFixed(2)}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col lg={12} md={12} xs={12}> <hr className="mb-4" /></Col>
                                  <br />
                                </React.Fragment>
                              ))
                            ) : (
                              <Row>
                                <Col lg={12} sm={12} md={12} xs={12} className="No_any_item">
                                  No items in a cart
                                </Col>
                              </Row>
                            )
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </Offcanvas.Body>
                <Container>
                  <Row>
                    <Col>
                      <Container>
                        <div className="col-md-12 mt-3">
                          {errorDivCheckOut}
                        </div>
                        {/* } */}
                        <Button
                          variant="dark"
                          disabled={errorDivpromo ? true : false}
                          type="submit"
                          size="lg"
                          onClick={(e) =>
                            errorDivpromo ? "" : Check_Out_detailes(e)
                          }
                          style={{
                            width: "100%",
                            backgroundColor:
                              customRes?.button_colour ?? "#dc3545",
                            border: 0,
                            marginBottom: "10px",
                          }}
                          className="custom_hover"
                        >
                          <strong
                            style={{
                              color: customRes?.non_highlighted_text ?? "white",
                            }}

                          >
                            {"Checkout"}{" "}
                            <span className="notranslate">
                              - {currencyLabel}{" "}
                              {Number(GetCartForCheckout?.total_amount_without_Discount || 0).toFixed(2)}
                            </span>
                          </strong>
                        </Button>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </React.Fragment>
            )}
          </Offcanvas>

          {isMobile && (
            <Card className="mobile_view_cart">
              <div
                style={{
                  position: "fixed",
                  bottom: 0,
                  zIndex: 1,
                  width: "100%",
                  padding: 0,
                  backgroundColor: customRes?.button_colour ?? "#dc3545",
                }}
                className="container"
              >
                <button
                  className="sc-cce0dd53-0 buTYIR"
                  onClick={handleShowDrawer}
                  style={{
                    backgroundColor: customRes?.button_colour ?? "#dc3545",
                  }}
                  type="button"
                >
                  <span className="sc-86d55903-0 sc-93a5572f-0 eoyNDU kbtvSC">
                    {cartCount_ > 0 ? (
                      <div className="sc-86d55903-0 sc-93a5572f-0 kOJnvv dGLkIz">
                        <p
                          position="absolute"
                          display="block"
                          texttransform="uppercase"
                          fontWeight="bold"
                          variant={1}
                          className="sc-455a01f7-0 gBiwdM"
                          style={{
                            opacity: 1,
                            transform: "none",
                            color: customRes?.non_highlighted_text ?? "white",
                          }}
                        >
                          {cartCount_}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <span
                      display="flex"
                      width="100%"
                      fontWeight="bold"
                      className="sc-455a01f7-0 jYBevi"
                      style={{
                        color: customRes?.non_highlighted_text ?? "white",
                      }}
                    >
                      {"VIEW ORDER"}
                    </span>
                    <span className="sc-86d55903-0 sc-93a5572f-0 jrRXku dJztCp">
                      {TableNo ? (
                        <span
                          style={{
                            color: customRes?.non_highlighted_text ?? "white",
                          }}
                          display="flex"
                          fontWeight="bold"
                          className="sc-455a01f7-0 enpebl"
                        >
                          {currencyLabel}{" "}
                          {FinalAmount?.final_amount
                            ? Number(FinalAmount?.final_amount).toFixed(2)
                              ? Number(FinalAmount?.final_amount).toFixed(2)
                              : Number(0).toFixed(2)
                            : Number(0).toFixed(2)}
                        </span>
                      ) : (
                        <span
                          style={{
                            color: customRes?.non_highlighted_text ?? "white",
                          }}
                          display="flex"
                          fontWeight="bold"
                          className="sc-455a01f7-0 enpebl"
                        >
                          {currencyLabel}{" "}
                          {GetCartForCheckout?.total_amount_without_Discount
                            ? Number(
                              GetCartForCheckout?.total_amount_without_Discount
                            ).toFixed(2)
                              ? Number(
                                GetCartForCheckout?.total_amount_without_Discount
                              ).toFixed(2)
                              : Number(0).toFixed(2)
                            : Number(0).toFixed(2)}
                        </span>
                      )}
                    </span>
                  </span>
                </button>
              </div>
            </Card>
          )}

          {isTablet && (
            <Card className="mobile_view_cart">
              <div className="sc-8f3dc05-0 gQEuSb">
                <button
                  onClick={handleShowDrawer}
                  style={{
                    backgroundColor: customRes?.button_colour ?? "#dc3545",
                  }}
                  className="sc-cce0dd53-0 buTYIR"
                  type="button"
                  data-projection-id={1}
                >
                  <span className="sc-86d55903-0 sc-93a5572f-0 eoyNDU kbtvSC">
                    {cartCount_ > 0 ? (
                      <div className="sc-86d55903-0 sc-93a5572f-0 kOJnvv dGLkIz">
                        <p
                          position="absolute"
                          display="block"
                          texttransform="uppercase"
                          fontWeight="bold"
                          variant={1}
                          className="sc-455a01f7-0 gBiwdM"
                          data-projection-id={2}
                          style={{
                            opacity: 1,
                            transform: "none",
                            color: customRes?.non_highlighted_text ?? "white",
                          }}
                        >
                          {cartCount_}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <span
                      display="flex"
                      width="100%"
                      fontWeight="bold"
                      className="sc-455a01f7-0 jYBevi"
                      style={{
                        color: customRes?.non_highlighted_text ?? "white",
                      }}
                    >
                      {"VIEW ORDER"}
                    </span>
                    <span className="sc-86d55903-0 sc-93a5572f-0 jrRXku dJztCp">
                      {TableNo ? (
                        <span
                          style={{
                            color: customRes?.non_highlighted_text ?? "white",
                          }}
                          display="flex"
                          fontWeight="bold"
                          className="sc-455a01f7-0 enpebl"
                        >
                          {currencyLabel}{" "}
                          {FinalAmount?.final_amount
                            ? Number(FinalAmount?.final_amount).toFixed(2)
                              ? Number(FinalAmount?.final_amount).toFixed(2)
                              : Number(0).toFixed(2)
                            : Number(0).toFixed(2)}
                        </span>
                      ) : (
                        <span
                          style={{
                            color: customRes?.non_highlighted_text ?? "white",
                          }}
                          display="flex"
                          fontWeight="bold"
                          className="sc-455a01f7-0 enpebl"
                        >
                          {currencyLabel}{" "}
                          {GetCartForCheckout?.total_amount_without_Discount
                            ? Number(
                              GetCartForCheckout?.total_amount_without_Discount
                            ).toFixed(2)
                              ? Number(
                                GetCartForCheckout?.total_amount_without_Discount
                              ).toFixed(2)
                              : Number(0).toFixed(2)
                            : Number(0).toFixed(2)}
                        </span>
                      )}
                    </span>
                  </span>
                </button>
              </div>

              <Row
                style={{
                  position: "fixed",
                  bottom: 0,
                  zIndex: 1,
                  width: "102%",
                  padding: 0,
                  backgroundColor: "#FFF",
                }}
              >
                <Col md={12}>
                  <div className="row mt-3 mb-3">
                    <div className="col text-center">
                      <button
                        style={{
                          backgroundColor:
                            customRes?.button_colour ?? "#dc3545",
                          border: 0,
                        }}
                        onClick={handleShowDrawer}
                        className="add_itm_to_continues_button text-center custom_hover"
                      >
                        <strong
                          style={{
                            color: customRes?.non_highlighted_text ?? "white",
                          }}
                        >
                          {"VIEW ORDER"}{" "}
                          ({cartCount_})
                        </strong>
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          )}
        </div>
      ) : sessionCheck_ === "Your session is expired, please try again." && (
        <Row
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh", backgroundColor: "rgb(235 234 234)" }}
        >
          <Col className="text-center">
            <img
              loading="lazy"
              className="mb-4"
              src={"/assets/media/avatars/Frame 49.png"}
              style={{ height: "100px", width: "120px" }}
              alt="logo"
            />
            <h4 className="dine_in_secc">
              {"Your dine-in session was closed."}
            </h4>
            <h6 className="text-center dine_in_Qr">
              {"To dine in again, please scan the QR Code."}
            </h6>
          </Col>
        </Row>
      )}
      <Footer />
      <BranchDetailsModel DayAndDate={DayAndDate} customRes={customRes} BranchDetails_={BranchDetails_} ModelShow={ModelShow} branchDetailsModelClose={branchDetailsModelClose} />
      <RestrictedMenuPopup BranchDetails_={BranchDetails_} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  branchdetailsbyid: state.branch.branchdetailsbyid,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  cartId: state.cart.cartId,
  cartCountData: state.cart.cartCountData,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCartId: (id) => dispatch(setCartId(id)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewFoodListPage);