import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import "../HomePage/hover.css";
import InputGroup from "react-bootstrap/InputGroup";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import "./cartpage1.css";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "animate.css";
import {
  getCharges,
  setProductList,
  checkout,
  setCustome,
  getCustomData,
  setCheckoutData,
  checkOrderNotes,
  setOrderCostData,
  getOrderCost,
  setDeliveryError,
  setLanguage,
  getBranchDetails,
  setBranchPrimaryLanguage,
  getMenuList,
  setBranchId,
  setType,
  setBranchName,
} from "../../Store";
import api from "../../CommonApi/axios";
import moment from 'moment-timezone';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { useMediaQuery } from "react-responsive";
import $ from "jquery";
import { showSuccessNotification } from "../CommonComponet/Swal/Swal";
import { showToastCommon } from "../CommonComponet/Toastify/Toastify";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import RightSideSvg from "./CommonPage/RightSideSvg";
import LeftSideSvg from "./CommonPage/LeftSideSvg";
import Footer from "../../Comman/Footer";
import { getCurrencyLabel } from "../CommonComponet/currencyUtils/currencyUtils";
import { applyFilterMeHandlers } from "../../Comman/jqueryUtils";
import { validateEmail } from "../../Comman/Commonfunction/commonfunction";
window.jquery = window.$ = $;

function NewCartPage(props) {
  let history = useHistory();
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const TableNo = query.get("no");
  const sessionId = query.get("sessionId");

  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     window.scrollTo(0, 0); // Scrolls to the top of the page
  //   });
  //   return () => {
  //     unlisten();
  //   };
  // }, [history]);


  useEffect(() => {
    applyFilterMeHandlers()
  }, [])

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const isTablet = useMediaQuery({ minWidth: 761, maxWidth: 988 });

  const {
    setProductList,
    productList,
    menuList,
    checkout,
    getCharges,
    charges,
    setCheckoutData,
    branchId,
    type,
    checkOrderNotes,
    showordernote,
    setOrderCostData,
    getOrderCost,
    deliverycosterror,
    setDeliveryError,
    deliverycost,
    getCustomData,
    setLanguage,
    language,
    getBranchDetails,
    branchLanguageData,
    branchPrimaryLanguage,
    setBranchPrimaryLanguage,
    getMenuList,
    setCustome,
    branchName,
    setBranchId,
    setType,
    setBranchName,
    branchdeliverysettingsdataData,
    branchdetailsbyid,
    customRes,
    branchDineInTimeData,
  } = props;

  let DineInData = JSON.parse(sessionStorage.getItem("DineInData"))
    ? JSON.parse(sessionStorage.getItem("DineInData"))
    : { PreTime: 15 };
  // console.log("as" ,DineInData)

  const cart_id = sessionStorage.getItem("cart_id");
  const { poId } = useParams();
  // const { tableId } = useParams();

  var BranchName_ = decodeURIComponent(poId);

  const [cartList_, setCartList_] = useState([]);
  const [CartProductItem_, setCartProductItem_] = useState("");
  const [FinalAmout_BranchDetails, setFinalAmout_BranchDetails] = useState("");
  const [paymentmethod, setpaymentmethod] = useState(false);

  let sessionBranchID = sessionStorage.getItem("sessionBranchID");
  const [BranchID, setBranchID] = useState(sessionBranchID);
  const [Currency, setCurrency] = useState("")

  useEffect(() => {
    api
      .get(`/client/order/getBranchId/${BranchName_}`)
      .then((res) => {
        setBranchID(res?.data?.data?._id);
        sessionStorage.setItem("sessionBranchID", res?.data?.data?._id);
        setCurrency(res?.data?.data?.currency)
      })
      .catch((err) => {
        // console.log(err.response?.data?.error?.message);
      });
  }, [BranchName_]);

  function increment_quantity(event, item_id, quantity_) {
    event.preventDefault();

    let item = {
      item_id: item_id,
      quantity: Number(quantity_ + 1),
    };

    if (cart_id) {
      api
        .patch(`/client/cart/item/${cart_id}`, item)
        .then((res) => {
          getCartList();
          // setpromocode_discount(res.data.data);
        })
        .catch((err) => {
          if (err.response) {
          }
        });
    }
  }

  function decrement_quantity(event, item_id, quantity_) {
    event.preventDefault();

    if (quantity_ === 1) {
      let item = {
        item_id: item_id,
      };
      api
        .delete(`/client/cart/item/${cart_id}`, { data: item })
        .then((res) => {
          getCartList();
        })
        .catch((err) => {
          if (err.response) {
            // console.log(err.response);
          }
        });
    } else {
      let item = {
        item_id: item_id,
        quantity: Number(quantity_ - 1),
      };
      if (cart_id) {
        api
          .patch(`/client/cart/item/${cart_id}`, item)
          .then((res) => {
            getCartList();
            // setpromocode_discount(res.data.data);
          })
          .catch((err) => {
            if (err.response) {
            }
          });
      }
    }
  }

  const [orderedItems, setOrderedItems] = useState([]);
  const [totalCost, setTotalCost] = useState(0);

  const handleChange = (item, event) => {
    if (event.target.checked) {
      setOrderedItems((cartItem) => [
        ...cartItem,
        {
          pos_id: item.pos_id,
          price: item.price,
          name_EN: item.name_EN,
          name_DE: item.name_DE,
          name_FR: item.name_FR,
          name_IT: item.name_IT,
          _id: item._id,
        },
      ]);
      setTotalCost((total) => total + parseInt(item.price));
      // add item to orderedItems array
    } else {
      // remove item from orderedItems array
      setOrderedItems((cartItem) =>
        cartItem.filter((i) => i.name_EN !== item.name_EN)
      );
      setTotalCost((total) => total - parseInt(item.price));
    }
  };

  /////////////////// Get cart List ///////////////////////

  useEffect(() => {
    getCartList();
  }, []);

  useEffect(() => {
    getCartForDine();
  }, [BranchID, TableNo]);

  useEffect(() => {
    setType(cartList_?.order_type === "delivery" ? "delivery" : "pickup");
    checkOrderNotes();
  }, []);


  const currencyLabel = getCurrencyLabel(Currency);

  function getCartList() {
    if (cart_id) {
      api
        .get(`/client/cart/${cart_id}`)
        .then((res) => {
          const tableData = res.data.data;
          setCartList_(tableData);
          setpromocode(tableData?.promo_code);
          setCartProductItem_(
            tableData?.items.length > 0 ? tableData?.items : ""
          );
          if (tableData?.items.length <= 0) {
            sessionStorage.removeItem("cart_id");
          }
          if (
            tableData.couponError ===
            "order amount is not sufficient for this coupon."
          ) {
            showToastCommon("order amount is not sufficient for this coupon.")
          }
          if (
            Number(
              Number(tableData?.total_amount) +
              Number(totalCost ? totalCost : 0)
            ).toFixed(2) === 0
          ) {
            setpaymentmethod("COD");
          }
          if (tableData?.items[0]?.payment_method_online && !tableData?.items[0]?.payment_method_cash) {
            setpaymentmethod('ONLINE');
          } else if (!tableData?.items[0]?.payment_method_online && tableData?.items[0]?.payment_method_cash) {
            setpaymentmethod('COD');
          }
        })
        .catch((err) => {
          showToastCommon(err?.response?.data?.error?.message)
        });
    }
  }

  const scrollRef = useRef();

  useEffect(() => {
    if (promocode === "") {
      if (scrollRef.current) {
        window.scrollTo(0, 0);
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [cartList_]);

  const [DisplayDineI, setDisplayDineIn] = useState(false);

  function getCartForDine() {
    const url = `/client/cart/getOrderForDineIn/${BranchID}/${TableNo}`;
    if (BranchID && TableNo) {
      api
        .get(url)
        .then((res) => {
          const tableData = res.data.data;
          setFinalAmout_BranchDetails(res?.data);
          setDisplayDineIn(tableData?.length > 0 ? false : true)
          // console.log("kkkk", res?.data)
          if (
            res?.data?.remaining_amount === 0 ||
            res?.data?.remaining_amount < 0
          ) {
            setpaymentmethod("COD");
          }
          setCartList_(tableData?.length > 0 ? tableData : []);
          setpromocode(res?.data?.promo_code_id);
          setCartProductItem_(
            tableData?.items.length > 0 ? tableData?.items : ""
          );
          if (tableData?.items.length <= 0) {
            sessionStorage.removeItem("cart_id");
          }
          if (
            tableData.couponError ===
            "order amount is not sufficient for this coupon."
          ) {
            showToastCommon("order amount is not sufficient for this coupon.")
          }
        })
        .catch((err) => {
          showToastCommon(err?.response?.data?.error?.message)
        });
    }
  }

  /////////////////// Get cart List ///////////////////////

  /////////////////////// Promo code //////////////////////

  const [promocode, setpromocode] = useState("");
  const [errorpromo, setErrorPromo] = useState(null);
  const errorDivpromo = errorpromo && (
    <div className="text-center alert alert-danger">{errorpromo}</div>
  )
  const [ApplyPromocodeLoader, serApplyPromocodeLoader] = useState(false);

  function Check_Promocode(event) {
    event.preventDefault();
    setErrorPromo(null);
    serApplyPromocodeLoader(true);

    if (TableNo) {
      let item = {
        branch_id: BranchID,
        promoCode: promocode,
      };
      // console.log("ds" ,item);
      api
        .post(`/client/order/promoCodeForDineIn/${TableNo}`, item)
        .then((res) => {
          serApplyPromocodeLoader(false);
          getCartForDine();
          setpaymentmethod(false);
          setcheckOutEroor(null);
          showSuccessNotification("Promo code applied successfully.")
          setTimeout(() => {
            getCartForDine();
          }, 1000);
          // setpromocode("");
        })
        .catch((err) => {
          if (err.response) {
            setpaymentmethod(false);
            serApplyPromocodeLoader(false);
            setcheckOutEroor(null);
            setErrorPromo(err?.response?.data?.error?.message);
            setTimeout(() => {
              setErrorPromo(null);
            }, 6000);
          }
        });
    } else {
      let item = {
        promo_code: promocode,
      };
      if (cart_id) {
        api
          .post(`/client/cart/promoCode/${cart_id}`, item)
          .then((res) => {
            serApplyPromocodeLoader(false);
            setpaymentmethod(false);
            setcheckOutEroor(null);
            showSuccessNotification("Promo code applied successfully.")
            getCartList();
          })
          .catch((err) => {
            if (err.response) {
              serApplyPromocodeLoader(false);
              setcheckOutEroor(null);
              setpaymentmethod(false);
              setErrorPromo(err?.response?.data?.error?.message);
              setTimeout(() => {
                setErrorPromo(null);
              }, 6000);
            }
          });
      }
    }
  }

  const [RemovePromocodeLoader, serRemovePromocodeLoader] = useState(false);

  function Remove_Promocode(event) {
    event.preventDefault();
    setErrorPromo(null);
    serRemovePromocodeLoader(true);
    // let item = {
    //   promo_code: promocode,
    // };
    if (TableNo) {
      let item = {
        branch_id: BranchID,
      };
      api
        .delete(`/client/order/promoCode/${TableNo}`, { data: item })
        .then((res) => {
          getCartForDine();
          setpromocode("");
          serRemovePromocodeLoader(false);
          setpaymentmethod(false);
          setcheckOutEroor(null);
        })
        .catch((err) => {
          if (err.response) {
            serRemovePromocodeLoader(false);
            setcheckOutEroor(null);
            setErrorPromo(err.response?.data?.error?.message);
            setTimeout(() => {
              setErrorPromo(null);
            }, 4000);
          }
        });
    } else {
      if (cart_id) {
        api
          .delete(`/client/cart/promoCode/${cart_id}`)
          .then((res) => {
            getCartList();
            setpromocode("");
            setcheckOutEroor(null);
            serRemovePromocodeLoader(false);
            setpaymentmethod(false);
          })
          .catch((err) => {
            if (err.response) {
              serRemovePromocodeLoader(false);
              setcheckOutEroor(null);
              setErrorPromo(err.response?.data?.error?.message);
              setTimeout(() => {
                setErrorPromo(null);
              }, 4000);
            }
          });
      }
    }
  }


  // console.log("paymentmethod" ,paymentmethod)
  /////////////////////// Promo code /////////////////////

  const [loader, setloader] = useState(false);
  const [Terms_and_conditions, setTerms_and_conditions] = useState(false);
  const [order_notes, setorder_notes] = useState("");
  const [full_name, setfull_name] = useState("");
  const [email, setemail] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [want_to_recieve_offers, setwant_to_recieve_offers] = useState(true)

  const [checkOutEroor, setcheckOutEroor] = useState(null);
  const checkOutEroorDiv = checkOutEroor && (
    <div className="text-center alert alert-danger">{checkOutEroor}</div>
  )
  // console.log("paymentmethod", paymentmethod)

  useEffect(() => {
    // Check the available payment options and set the default payment method
    // if(CartProductItem_[0]?.payment_method_online && CartProductItem_[0]?.payment_method_cash){
    //   setpaymentmethod(false);
    // }
    if (CartProductItem_[0]?.payment_method_online && !CartProductItem_[0]?.payment_method_cash) {
      setpaymentmethod('ONLINE');
    } else if (!CartProductItem_[0]?.payment_method_online && CartProductItem_[0]?.payment_method_cash) {
      setpaymentmethod('COD');
    }
  }, [CartProductItem_?.length > 0]);

  useEffect(() => {
    // Check the available payment options and set the default payment method
    // if(CartProductItem_[0]?.payment_method_online && CartProductItem_[0]?.payment_method_cash){
    //   setpaymentmethod(false);
    // }
    // console.log("aa111")
    if (FinalAmout_BranchDetails?.payment_method_online && !FinalAmout_BranchDetails?.payment_method_cash) {
      setpaymentmethod('ONLINE');
    } else if (!FinalAmout_BranchDetails?.payment_method_online && FinalAmout_BranchDetails?.payment_method_cash) {
      setpaymentmethod('COD');
    }
  }, [FinalAmout_BranchDetails]);



  function handalCheckOut(event) {
    event.preventDefault();
    // setloader(true);
    setcheckOutEroor(null);

    if (TableNo) {
      if (paymentmethod === false) {
        setcheckOutEroor("Please select payment mode");
        return;
      } else if (Terms_and_conditions === false) {
        setcheckOutEroor("Please accept terms and conditions");
        return;
      } else {
        setloader(true);
        let item = new FormData();

        if (email) {
          item.append("email", email);
        }
        // if (cart_id) {
        //   item.append("cart_id", cart_id);
        // }

        item.append("table_number", TableNo);
        item.append("want_to_recieve_offers", want_to_recieve_offers);
        item.append(
          "discount_amount",
          Number(FinalAmout_BranchDetails?.discount_amount || 0)
        );
        if (paymentmethod) {
          item.append("payment_method", String(paymentmethod));
        }
        item.append(
          "total_amount",
          Number(FinalAmout_BranchDetails?.remaining_amount || 0)
        );
        item.append("branch_id", FinalAmout_BranchDetails?.branchData?._id);
        if (DineInData != null) {
          item.append("order_prep_interval", DineInData?.PreTime);
        }

        // console.log("passdata", Object.fromEntries(item.entries()));
        // return

        if (paymentmethod === "ONLINE") {
          api
            .post(`/client/order/addFinalDineInOrder`, item)
            .then((response) => {
              // console.log("response.data", response?.data?.data?.paymentRes?.RedirectUrl);
              let checkOut_storeData = {
                date:
                  cartList_?.length > 0
                    ? cartList_[0]?.orderItemDetails?.length > 0
                      ? moment(
                        cartList_[0]?.orderItemDetails[0]?.created_at
                      ).format("DD-MM-YYYY")
                      : ""
                    : "",
                time:
                  cartList_?.length > 0
                    ? cartList_[0]?.orderItemDetails?.length > 0
                      ? moment(
                        cartList_[0]?.orderItemDetails[0]?.created_at
                      ).format("h:mm a")
                      : ""
                    : "",
                orderType: "dinein",
                total:
                  FinalAmout_BranchDetails?.final_amount_after_discount > 0
                    ? FinalAmout_BranchDetails?.final_amount_after_discount
                    : "0",
                orderId: response?.data?.data?.unique_code,
                order_id: response?.data?.data?.unique_code,
              };

              let itemPrint = {
                order_id: FinalAmout_BranchDetails !== "" &&
                  FinalAmout_BranchDetails?.data.length > 0
                  ? FinalAmout_BranchDetails?.data[0]?._id : undefined
              }

              localStorage.setItem('itemPrint', itemPrint?.order_id);

              // console.log("itemprinte" ,itemPrint)
              // return

              // const tableData = response?.data?.data?.paymentRes?.RedirectUrl;

              // console.log("response" ,response)
              const redirectUrl = response.data.data?.paymentRes?.redirectUrl || response.data.data?.paymentRes?.RedirectUrl;
              if (redirectUrl) {
                setCheckoutData(checkOut_storeData);
                checkout();
                setloader(false);
                setTimeout(() => {
                  sessionStorage.removeItem("cart_id");
                  sessionStorage.clear();
                }, 100);
                window.open(
                  `${redirectUrl}`,
                  "_self"
                );
              } else {
              }
            })
            .catch((err) => {
              setloader(false);
              setcheckOutEroor(err?.response?.data?.error?.message);
            });


        } else {
          setloader(true);
          api
            .post(`/client/order/addFinalDineInOrder`, item)
            .then((response) => {

              let itemPrint = {
                order_id: FinalAmout_BranchDetails !== "" &&
                  FinalAmout_BranchDetails?.data.length > 0
                  ? FinalAmout_BranchDetails?.data[0]?._id : undefined
              }
    
              api.post("/client/order/print-order-summary", itemPrint).then((res) => {
                // console.log("res" ,res);
              }).catch((err) => {
    
              })

              // console.log("response.data", response?.data);
              let checkOut_storeData = {
                date:
                  cartList_?.length > 0
                    ? cartList_[0]?.orderItemDetails?.length > 0
                      ? moment(
                        cartList_[0]?.orderItemDetails[0]?.created_at
                      ).format("DD-MM-YYYY")
                      : ""
                    : "",
                time:
                  cartList_?.length > 0
                    ? cartList_[0]?.orderItemDetails?.length > 0
                      ? moment(
                        cartList_[0]?.orderItemDetails[0]?.created_at
                      ).format("h:mm a")
                      : ""
                    : "",
                orderType: "dinein",
                total:
                  FinalAmout_BranchDetails?.final_amount_after_discount > 0
                    ? FinalAmout_BranchDetails?.final_amount_after_discount
                    : "0",
                orderId: response?.data?.data?.unique_code,
                order_id: response?.data?.data?.unique_code,
                TableNo: TableNo,
                branchName: BranchName_,
              };
              setCheckoutData(checkOut_storeData);
              checkout();
              setloader(false);
              setcheckOutEroor(null);
              sessionStorage.removeItem("cart_id");
              sessionStorage.clear();
              // console.log("checkOut_storeData" ,checkOut_storeData)
              history.push({
                pathname: `/success_dinein`,
                state: checkOut_storeData,
              });
              sessionStorage.setItem("checkOut_storeData", JSON.stringify(checkOut_storeData));
            })
            .catch((err) => {
              setloader(false);
              setcheckOutEroor(err?.response?.data?.error?.message);
            });


          
        }
      }
    } else {
      if (full_name === "") {
        setcheckOutEroor("Please enter your name");
        return;
      } else if (!validateEmail(email)) {
        setcheckOutEroor("The entered email is invalid.");
        return;
      } else if (phone_number === "") {
        setcheckOutEroor("Please enter your phone number");
        return;
      } else if (paymentmethod === false) {
        setcheckOutEroor("Please select payment mode");
        return;
      } else if (Terms_and_conditions === false) {
        setcheckOutEroor("Please accept terms and conditions");
        return;
      } else {
        if (paymentmethod === "ONLINE") {
          setloader(true);
        }

        let item = new FormData();

        item.append("full_name", full_name);
        item.append("email", email);
        item.append("phone_number", phone_number);
        item.append("want_to_recieve_offers", want_to_recieve_offers);
        if (cart_id) {
          item.append("cart_id", cart_id);
        }
        if (cartList_?.order_type) {
          item.append(
            "table_number",
            cartList_?.order_type === "delivery"
              ? "0"
              : cartList_?.order_type === "pickup"
                ? "0"
                : TableNo
          );
        }
        if (order_notes) {
          item.append("order_notes", order_notes);
        }
        if (paymentmethod) {
          item.append("payment_method", String(paymentmethod));
        }
        if (cartList_?.delivery_cost) {
          item.append("delivery_cost", Number(cartList_?.delivery_cost));
        }
        // if (Number(cartList_?.total_amount) + Number(totalCost)) {
        item.append(
          "total_amount",
          Number(cartList_?.total_amount) + Number(totalCost)
            ? Number(cartList_?.total_amount) + Number(totalCost)
            : Number(0)
        );
        // }

        // console.log("passdata", Object.fromEntries(item.entries()));
        // return

        if (paymentmethod === "ONLINE") {
          api
            .post(`/client/order/add`, item)
            .then((response) => {
              // console.log("response.data", response.data);
              let checkOut_storeData = {
                date: cartList_?.order_date,
                time: cartList_?.order_time,
                orderType: cartList_?.order_type,
                total:
                  Number(cartList_?.total_amount) + Number(totalCost)
                    ? Number(cartList_?.total_amount) + Number(totalCost)
                    : Number(0),
                orderId: response?.data?.data?.order_no,
                order_id: response?.data?.data?.order_id,
              };



              const tableData = response.data.data?.paymentRes?.redirectUrl;
              console.log("response", tableData)
              // localStorage.setItem("hostedCheckoutId" ,tableData?.paymentRes?.hostedCheckoutId)
              const redirectUrl = response.data.data?.paymentRes?.redirectUrl || response.data.data?.paymentRes?.RedirectUrl;

              if (redirectUrl) {
                console.log("check")
                setCheckoutData(checkOut_storeData);
                checkout();
                setloader(false);
                setTimeout(() => {
                  sessionStorage.removeItem("cart_id");
                  sessionStorage.clear();
                }, 100);
                window.open(`${redirectUrl}`, "_self");
              } else {
              }
            })
            .catch((err) => {
              setloader(false);
              setcheckOutEroor(err?.response?.data?.error?.message);
            });
        } else {
          setloader(false);
          Swal.fire({
            text: `Are you sure you want to order from ${CartProductItem_[0]?.branch_name_EN} ?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Order it !",
          }).then((result) => {
            if (result.isConfirmed) {
              setloader(true);
              api
                .post(`/client/order/add`, item)
                .then((response) => {
                  // console.log("response.data", response.data.data);
                  let checkOut_storeData = {
                    date: cartList_?.order_date,
                    time: cartList_?.order_time,
                    orderType: cartList_?.order_type,
                    total:
                      Number(cartList_?.total_amount) + Number(totalCost)
                        ? Number(cartList_?.total_amount) + Number(totalCost)
                        : Number(0),
                    orderId: response?.data?.data?.order_no,
                    order_id: response?.data?.data?.order_id,
                  };
                  setCheckoutData(checkOut_storeData);
                  checkout();
                  setloader(false);
                  setcheckOutEroor(null);
                  sessionStorage.removeItem("cart_id");
                  sessionStorage.clear();
                  history.push({
                    pathname: `/success`,
                    state: checkOut_storeData,
                  });
                })
                .catch((err) => {
                  setloader(false);
                  setcheckOutEroor(err?.response?.data?.error?.message);
                });
            } else {
              setloader(false);
              setcheckOutEroor(null);
            }
          });
        }
      }
    }
  }

  return (
    <div ref={scrollRef}>
      <CommonHeader />
      <div style={{ backgroundColor: "rgb(240, 238, 237)", minHeight: "100vh" }}>
        <br />
        {TableNo ? (
          <>
            {DisplayDineI &&
              <Container className="my-5">
                <Row className="justify-content-sm-center">
                  <Card style={{ backgroundColor: "#F0EEED", border: 0 }}>
                    <Container>
                      <Card className="mt-0 mb-2">
                        <Card.Body>
                          <Row className="justify-content-center">
                            <Col className="text-center">
                              <div className="gift_card_hover">
                                <img
                                  loading="lazy"
                                  alt="gift_card_image"
                                  className="gift_card_img"
                                  src="/assets/media/my/cart.png"
                                  style={{
                                    display: "-webkit-fill-available",
                                  }}
                                />
                              </div>
                              <div className="main_header text-center mt-3">
                                Your cart is empty
                              </div>

                              <Button
                                variant="dark"
                                type="button"
                                onClick={() =>
                                  history.push(
                                    `/${BranchName_}/menu?no=${TableNo}&sessionId=${sessionId}`
                                  )
                                }
                                style={{
                                  width:
                                    isMobile === true
                                      ? "189px"
                                      : isTablet === true
                                        ? "36%"
                                        : "19%",
                                  backgroundColor:
                                    customRes?.button_colour ?? "#dc3545",
                                  border: 0,
                                }}
                                className="custom_hover my-2"
                              >
                                <strong
                                  style={{
                                    color:
                                      customRes?.non_highlighted_text ??
                                      "white",
                                  }}
                                >
                                  Back to home
                                </strong>
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Container>
                  </Card>
                </Row>
              </Container>}
            {cartList_?.length > 0 &&
              <Container>
                <>
                  <Row className="justify-content-sm-center">
                    {!isMobile && (
                      <RightSideSvg />
                    )}

                    <Col lg={8} sx={8} md={8} className="mb-3">
                      <Card style={{ border: 0, borderRadius: 0 }}>
                        <Card.Body>
                          {cartList_?.length > 0
                            ? cartList_?.map((main1) =>
                              main1?.orderItemDetails?.map(
                                (main, index) => (
                                  <React.Fragment>
                                    <Row key={index}>
                                      <Col xs={2} lg={1} md={1} sm={1} className="g-0">
                                        <img
                                          loading="lazy"
                                          alt="Product_image"
                                          src={main?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
                                          style={{
                                            width: "80%",
                                            float: "right",
                                            objectFit: "cover",
                                            height: "40px",
                                          }}
                                        />
                                      </Col>
                                      <Col xs={6} lg={7} md={7} sm={7}>
                                        <h6 className="cart_product_name">
                                          {main?.quantity}x {main?.name_EN}
                                          {main?.is_pickup === true &&
                                            <span className='mx-2'>
                                              <img alt="" loading='lazy' style={{ height: 19 }} src="/assets/media/avatars/take-away1 1.png" />
                                            </span>}
                                          {main?.is_custom === true &&
                                            <span className='mx-1'>
                                              <img alt="" loading='lazy' style={{ height: 19 }} src="/assets/media/avatars/CustomDish.png" />
                                            </span>
                                          }
                                        </h6>

                                        <React.Fragment>
                                          <p
                                            className="cart_product_chf notranslate"
                                            style={{ marginBottom: "0px" }}
                                          >
                                            {currencyLabel}{" "}
                                            {Number(
                                              main?.base_price
                                            ).toFixed(2)}
                                          </p>
                                        </React.Fragment>
                                      </Col>

                                      <Col
                                        xs={4}
                                        lg={4}
                                        md={4}
                                        sm={4}
                                        className="text-end"
                                      >
                                        <p
                                          className="cart_product_chf notranslate"
                                          style={{ marginBottom: "0px" }}
                                        >
                                          {currencyLabel}{" "}
                                          {Number(
                                            main?.total_price
                                              ? main?.total_price
                                              : 0
                                          ).toFixed(2)}
                                        </p>
                                      </Col>
                                    </Row>
                                    <hr />
                                  </React.Fragment>
                                )
                              )
                            )
                            : "No Product Item Found"}
                          <Row className="text-end mb-2">
                            <Col lg={12} md={12} sx={12} sm={12}>
                              <Container
                                className="pointer"
                                onClick={() =>
                                  history.push(
                                    `/${BranchName_}/menu?no=${TableNo}&sessionId=${sessionId}`
                                  )
                                }
                              >
                                <Card.Text className="add_more_items">
                                  <AddCircleOutlineIcon
                                    style={{
                                      color: "rgba(48, 50, 59, 0.73)",
                                    }}
                                    className="mx-3"
                                  />
                                  Add More items
                                </Card.Text>
                              </Container>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col lg={4} sx={4} md={8}>
                      <Card style={{ border: 0, borderRadius: 0, zIndex: 999 }}>
                        <Card.Body>
                          <Row>
                            <Col>
                              <h4
                                className="branch_name"
                                style={{ marginBottom: "0px" }}
                              >
                                {FinalAmout_BranchDetails && FinalAmout_BranchDetails?.branchData?.branch_name_EN}
                              </h4>
                              <small className="branch_address">
                                {FinalAmout_BranchDetails?.branchData?.address ?? ""}
                              </small>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col>
                              <div className="deails_fro mt-2">
                                <img
                                  alt="Logo"
                                  loading="lazy"
                                  src="/assets/media/my/menu.png"
                                  style={{
                                    height: "25px",
                                    marginRight: 10,
                                  }}
                                />
                                {cartList_?.order_type === "delivery"
                                  ? "Delivery"
                                  : cartList_?.order_type === "pickup"
                                    ? "Pickup"
                                    : "Dine In"}{" "}
                                ,{"  "}

                                {/* {console.log("saasa" ,FinalAmout_BranchDetails?.branchData?.branchTimeZone)} */}
                                {cartList_?.length > 0 &&
                                  cartList_[0]?.orderItemDetails?.length > 0 &&
                                  moment(cartList_[0]?.orderItemDetails[0]?.created_at).tz(FinalAmout_BranchDetails?.branchData?.branchTimeZone).format('MMMM Do YYYY, h:mm a')
                                  // moment(cartList_[0]?.orderItemDetails[0]?.created_at).format("MMMM Do YYYY, h:mm a")
                                }

                              </div>

                              {cartList_?.order_type === "delivery" && (
                                <div className="mt-2 deails_fro">
                                  <img
                                    loading="lazy"
                                    alt="Logo"
                                    src="/assets/media/my/delivery.png"
                                    style={{
                                      height: "25px",
                                      marginRight: 10,
                                    }}
                                  />
                                  {"Deliver to :-"}{" "}
                                  {cartList_?.address_line2
                                    ? cartList_?.address_line2
                                    : ""}{" "}
                                  {cartList_?.address_line1
                                    ? cartList_?.address_line1
                                    : ""}
                                </div>
                              )}
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col>
                              <Form>
                                <Form.Group
                                  className={TableNo ? "mb-1" : "mb-3"}
                                >
                                  <Form.Control
                                    type="email"
                                    value={email}
                                    style={{
                                      height: "45px",
                                      color: "#5a5a5a",
                                      borderRadius: "2px",
                                      border: "1px solid rgb(212 209 209)",
                                      // boxShadow : '0 0 0 0.1rem rgba(13,110,253,.25)'
                                    }}
                                    onChange={(e) => setemail(e.target.value)}
                                    placeholder={"Enter your email "}
                                  />
                                </Form.Group>
                                <Form.Text id="passwordHelpBlock" muted>
                                  To receive the order receipt, please provide
                                  your email.
                                </Form.Text>
                              </Form>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form
                                onSubmit={
                                  FinalAmout_BranchDetails?.discount_amount > 0
                                    ? Remove_Promocode
                                    : Check_Promocode
                                }
                              >
                                <InputGroup className={"mb-1 mt-2"}>
                                  <Form.Control
                                    required
                                    style={{
                                      height: "45px",
                                      color: "#5a5a5a",
                                      borderRadius: "2px",
                                      border: "1px solid rgb(212 209 209)",
                                    }}
                                    placeholder={"Promo Code"}
                                    disabled={
                                      FinalAmout_BranchDetails?.discount_amount >
                                        0
                                        ? true
                                        : false
                                    }
                                    value={promocode}
                                    onChange={(e) =>
                                      setpromocode(e.target.value)
                                    }
                                  />
                                  {FinalAmout_BranchDetails?.discount_amount >
                                    0 ? (
                                    <React.Fragment>
                                      {RemovePromocodeLoader === true ? (
                                        <Box>
                                          <CircularProgress
                                            size={40}
                                            thickness={5}
                                          />
                                        </Box>
                                      ) : (
                                        <Button
                                          variant="danger"
                                          id="promocode"
                                          type="submit"
                                        >
                                          Remove
                                        </Button>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {ApplyPromocodeLoader === true ? (
                                        <Box>
                                          <CircularProgress
                                            size={40}
                                            thickness={5}
                                          />
                                        </Box>
                                      ) : (
                                        <Button
                                          variant="secondary"
                                          id="promocode"
                                          type="submit"
                                        >
                                          Apply
                                        </Button>
                                      )}
                                    </React.Fragment>
                                  )}
                                </InputGroup>
                                <div className="col-md-12">
                                  {" "}
                                  {errorDivpromo}{" "}
                                </div>
                                {TableNo ? (
                                  ""
                                ) : (
                                  <>
                                    {showordernote === true ? (
                                      <InputGroup>
                                        <InputGroup.Text>
                                          <img
                                            loading="lazy"
                                            alt="Logo"
                                            src="/assets/media/my/notes.png"
                                            style={{
                                              height: "50px",
                                            }}
                                          />
                                        </InputGroup.Text>

                                        <Form.Control
                                          as="textarea"
                                          style={{
                                            color: "#5a5a5a",
                                            borderRadius: "2px",
                                            border:
                                              "1px solid rgb(212 209 209)",
                                          }}
                                          placeholder={"Order Notes"}
                                          aria-label="Order Comments"
                                          value={order_notes}
                                          onChange={(e) =>
                                            setorder_notes(e.target.value)
                                          }
                                        />
                                      </InputGroup>
                                    ) : null}
                                  </>
                                )}
                              </Form>
                            </Col>
                          </Row>
                          <hr />
                          <form onSubmit={handalCheckOut}>
                            <Row>
                              <Col xs={12} lg={12}>
                                <h6 className="order_summery mb-3">
                                  <b>
                                    {"Order Summary"}
                                  </b>
                                </h6>
                              </Col>
                              <Col xs={6} lg={6}>
                                <span>Total item amount</span>
                              </Col>
                              <Col xs={6} lg={6}>
                                <span style={{ float: "right" }}>
                                  {currencyLabel}{" "}
                                  {Number(
                                    FinalAmout_BranchDetails?.final_amount || 0).toFixed(2)}
                                </span>
                              </Col>
                              {FinalAmout_BranchDetails?.discount_amount > 0 && (
                                <React.Fragment>
                                  <Col xs={6} lg={6}>
                                    <span style={{ color: "green" }}>
                                      <b>Total Discount</b>
                                    </span>
                                  </Col>
                                  <Col xs={6} lg={6}>
                                    <span
                                      style={{ float: "right", color: "green" }}
                                    >
                                      <b>
                                        {currencyLabel}{" "}
                                        {Number(
                                          FinalAmout_BranchDetails?.discount_amount
                                        ).toFixed(2)}
                                      </b>
                                    </span>
                                  </Col>
                                </React.Fragment>
                              )}
                              {FinalAmout_BranchDetails?.data?.length > 0 &&
                                FinalAmout_BranchDetails?.data[0]?.is_discount_applied === true && (
                                  <React.Fragment>
                                    <Col xs={8} lg={8}>
                                      <span style={{ color: "green" }}>
                                        <b>Additional Discount Amount</b>
                                      </span>
                                    </Col>
                                    <Col xs={4} lg={4}>
                                      <span
                                        style={{ float: "right", color: "green" }}
                                      >
                                        <b>
                                          {currencyLabel}{" "}
                                          {Number(
                                            FinalAmout_BranchDetails?.data[0]?.final_additional_discount_amount
                                          ).toFixed(2)}
                                        </b>
                                      </span>
                                    </Col>
                                  </React.Fragment>
                                )}
                            </Row>
                            <hr />
                            <Row>
                              <Col xs={6} lg={6}>
                                <h6 className="to_pay">
                                  <b>
                                    {"To Pay"}
                                  </b>
                                </h6>
                              </Col>
                              <Col xs={6} lg={6}>
                                <h5
                                  className="to_pay"
                                  style={{ float: "right" }}
                                >
                                  <b>
                                    {currencyLabel}{" "}
                                    {Number(FinalAmout_BranchDetails?.remaining_amount || 0).toFixed(2)}
                                  </b>
                                </h5>
                              </Col>
                            </Row>
                            <hr />

                            {Number(FinalAmout_BranchDetails?.remaining_amount || 0).toFixed(2) === 0 ?
                              (
                                ""
                              ) : (
                                <React.Fragment>
                                  <Row>
                                    <Col xs={12} lg={12} md={12}>
                                      <h5 className="to_pay">
                                        <b>
                                          {"Payment Mode"}
                                        </b>
                                      </h5>
                                    </Col>

                                    <Col
                                      xs={!FinalAmout_BranchDetails?.payment_method_online ? 12 : 4}
                                      lg={!FinalAmout_BranchDetails?.payment_method_online ? 12 : 4}
                                      md={!FinalAmout_BranchDetails?.payment_method_online ? 12 : 4}
                                      className="text-start"
                                    >
                                      <Form.Group className="my-2">
                                        {FinalAmout_BranchDetails?.payment_method_online ===
                                          true ? (
                                          <Form.Check
                                            inline
                                            value="ONLINE"
                                            // checked={paymentmethod === "ONLINE"}
                                            checked={paymentmethod === "ONLINE" || (!FinalAmout_BranchDetails?.payment_method_cash)}
                                            onChange={(e) =>
                                              setpaymentmethod(e.target.value)
                                            }
                                            name="payment_option"
                                            type="radio"
                                            label="Online"
                                          />
                                        ) : null}
                                      </Form.Group>
                                    </Col>
                                    <Col
                                      xs={8}
                                      lg={8}
                                      md={8}
                                      className="text-start"
                                    >
                                      <Form.Group className="my-2">
                                        {FinalAmout_BranchDetails?.payment_method_cash ===
                                          true ? (
                                          <Form.Check
                                            inline
                                            name="payment_option"
                                            type="radio"
                                            value="COD"
                                            // checked={paymentmethod === "COD"}
                                            checked={paymentmethod === "COD" || (!FinalAmout_BranchDetails?.payment_method_cash)}
                                            onChange={(e) =>
                                              setpaymentmethod(e.target.value)
                                            }
                                            label="Pay at Counter"
                                          />
                                        ) : null}
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <hr />
                                </React.Fragment>
                              )}

                            <Row>
                              <Col>
                                <Form.Group className="mb-3">
                                  <Form.Check
                                    type="checkbox"
                                    // required
                                    value={Terms_and_conditions}
                                    label={(
                                      <span>
                                        I confirm that I am 16 years or older
                                        and accept{" "}
                                        <Link
                                          target="_blank"
                                          rel="noreferrer"
                                          onClick={() => window.open(`${cartList_?.items?.length > 0 && cartList_?.items[0]?.doc_url}`)}
                                        >
                                          terms and conditions.
                                        </Link>
                                      </span>
                                    )
                                    }
                                    onChange={(e) =>
                                      setTerms_and_conditions(
                                        !Terms_and_conditions
                                      )
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Check
                                    type="checkbox"
                                    checked={want_to_recieve_offers}
                                    // required
                                    value={want_to_recieve_offers}
                                    label={(
                                      <span>
                                        Email me with news and offers.
                                      </span>
                                    )
                                    }
                                    onChange={(e) =>
                                      setwant_to_recieve_offers(
                                        !want_to_recieve_offers
                                      )
                                    }
                                  />
                                </Form.Group>
                                <div className="col-md-12">
                                  {" "}
                                  {checkOutEroorDiv}{" "}
                                </div>
                                <div className="text-center">
                                  {loader === false ? (
                                    <Button
                                      variant="dark"
                                      type="submit"
                                      size="lg"
                                      style={{
                                        width: "100%",
                                        backgroundColor:
                                           customRes?.button_colour ?? "#dc3545",
                                        border: 0,
                                      }}
                                      className="custom_hover"
                                    >
                                      <strong
                                        style={{
                                          color:
                                            customRes?.non_highlighted_text ??
                                            "white",
                                        }}
                                      >
                                        Pay {currencyLabel}{" "}
                                        {Number(FinalAmout_BranchDetails?.remaining_amount || 0).toFixed(2)}
                                      </strong>
                                    </Button>
                                  ) : (
                                    <Box>
                                      <CircularProgress
                                        size={50}
                                        thickness={5}
                                      />
                                    </Box>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </Card.Body>
                      </Card>
                      {!isMobile && <LeftSideSvg />}
                    </Col>
                  </Row>
                  <br />
                </>
              </Container>}
          </>
        ) : (
          <React.Fragment>
            {cart_id == null ? (
              <Container
                className="mt-5"
                style={{
                  display: cart_id == null ? "show" : "show",
                }}
              >
                <Row className="justify-content-sm-center">
                  <Card style={{ backgroundColor: "#F0EEED", border: 0 }}>
                    <Container>
                      <Card className="mt-0 mb-2">
                        <Card.Body>
                          <Row className="justify-content-center">
                            <Col className="text-center">
                              <div className="gift_card_hover">
                                <img
                                  loading="lazy"
                                  alt="gift_card_image"
                                  className="gift_card_img"
                                  src="/assets/media/my/cart.png"
                                  style={{
                                    display: "-webkit-fill-available",
                                  }}
                                />
                              </div>
                              <div className="main_header text-center mt-3">
                                Your cart is empty
                              </div>

                              <Button
                                variant="dark"
                                type="button"
                                onClick={() => history.push(`/`)}
                                style={{
                                  width:
                                    isMobile === true
                                      ? "189px"
                                      : isTablet === true
                                        ? "36%"
                                        : "19%",
                                  backgroundColor:
                                    customRes?.button_colour ?? "#dc3545",
                                  border: 0,
                                }}
                                className="custom_hover my-2"
                              >
                                <strong
                                  style={{
                                    color:
                                      customRes?.non_highlighted_text ??
                                      "white",
                                  }}
                                >
                                  Back to home
                                </strong>
                              </Button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Container>
                  </Card>
                </Row>
              </Container>
            ) : (
              <Container>
                <React.Fragment>
                  <Row className="justify-content-sm-center">
                    <Col lg={8} md={12} sx={8} className="mb-3">
                      {!isMobile && <RightSideSvg />}
                      <Card style={{ border: 0, borderRadius: 0 }}>
                        <Card.Body>
                          {CartProductItem_.length > 0
                            ? CartProductItem_.map((main, index) => (
                              <React.Fragment>
                                <Row key={index}>
                                  <Col xs={2} lg={2} md={2} className="g-0">
                                    <img
                                      loading="lazy"
                                      alt="Product_image"
                                      src={main?.image_url || "/assets/media/avatars/WhatsApp Image 2023-07-21 at 3.17.53 PM.jpeg"}
                                      style={{
                                        maxWidth: "70%",
                                        float: "right",
                                      }}
                                    />
                                  </Col>
                                  <Col xs={6} lg={6} md={6}>
                                    <h6 className="cart_product_name">
                                      {main?.name_EN}
                                    </h6>
                                    <div
                                      className="cart_product_discription"
                                    >
                                      {main?.options?.length > 0 && main?.options?.map((main22, index22) => (
                                        <div className="options_" key={index22}>
                                          + {main22?.name}
                                        </div>
                                      ))}
                                    </div>
                                    <React.Fragment>
                                      {main?.custom_property?.length > 0 &&
                                        main?.custom_property?.map(
                                          (main2, index) => (
                                            <div
                                              className="cart_product_discription"
                                              key={index}
                                            >
                                              {main2.ingredient}
                                            </div>
                                          )
                                        )
                                      }
                                    </React.Fragment>
                                  </Col>

                                  <Col
                                    xs={4}
                                    lg={4}
                                    md={4}
                                    className="text-end"
                                  >
                                    
                                    <p
                                      className="cart_product_chf notranslate"
                                      style={{ marginBottom: "0px" }}
                                    >
                                      {currencyLabel} {Number(main?.total_price).toFixed(2)}
                                    </p>
                                    <Col
                                      xs={6}
                                      lg={12}
                                      md={12}
                                      className="text-end"
                                    >
                                      <RemoveCircleOutlineIcon
                                        onClick={(e) =>
                                          decrement_quantity(
                                            e,
                                            // main?.product_id,
                                            main?._id,
                                            main?.quantity
                                          )
                                        }
                                        className="removeCircleOutlineIcon pointer"
                                        style={{
                                          marginRight: "0px",
                                          marginLeft: "0px",
                                          color: "rgba(48, 50, 59, 0.73)",
                                        }}
                                      />
                                      {/* &nbsp; */}
                                      <button
                                        style={{
                                          border: 0,
                                          marginLeft: "-3px",
                                          marginTop: "0px",
                                        }}
                                        className="btn btn-outline product_price"
                                      >
                                        {main?.quantity}
                                      </button>{" "}
                                      {/* &nbsp; */}
                                      <AddCircleOutlineIcon
                                        onClick={(e) =>
                                          increment_quantity(
                                            e,
                                            // main?.product_id,
                                            main?._id,
                                            main?.quantity
                                          )
                                        }
                                        className="addCircleOutlineIcon pointer"
                                        style={{
                                          marginLeft: "-6px",
                                          color: "rgba(48, 50, 59, 0.73)",
                                        }}
                                      />
                                    </Col>
                                  </Col>



                                </Row>
                                <hr />

                                {/* {CartProductItem_.length > 1 ? <hr /> : ""} */}
                              </React.Fragment>
                            ))
                            : " No Product Item Found"}
                          <Row className="text-end mb-2">
                            <Col lg={12} md={12} sx={12}>
                              <Container
                                className="pointer"
                                onClick={() =>
                                  history.push(`/${BranchName_}/menu`)
                                }
                              >
                                <Card.Text className="add_more_items">
                                  <AddCircleOutlineIcon
                                    style={{
                                      color: "rgba(48, 50, 59, 0.73)",
                                    }}
                                    className="mx-3"
                                  />
                                  {"Add More items"}
                                </Card.Text>
                              </Container>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4} md={12} sx={4}>
                      <Card style={{ border: 0, borderRadius: 0, zIndex: 999 }}>
                        <Card.Body>
                          <Row>
                            <Col>
                              <h4
                                className="branch_name"
                                style={{ marginBottom: "0px" }}
                              >
                                {CartProductItem_[0]?.branch_name_EN && CartProductItem_[0]?.branch_name_EN}
                              </h4>
                              <small className="branch_address">
                                {CartProductItem_[0]?.branch_address
                                  ? CartProductItem_[0]?.branch_address
                                  : ""}
                              </small>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col>
                              <div className="deails_fro mt-2">
                                <img
                                  loading="lazy"
                                  alt="Logo"
                                  src="/assets/media/my/menu.png"
                                  style={{
                                    height: "25px",
                                    marginRight: 10,
                                  }}
                                />
                                {cartList_?.order_type === "delivery"
                                  ? "Delivery"
                                  : cartList_?.order_type === "pickup"
                                    ? "Pickup"
                                    : "Dine In"}{" "}
                                ,{"  "}
                                {cartList_?.order_date
                                  ? moment(cartList_?.order_date).format(
                                    "MMM Do YY"
                                  )
                                  : ""}{" "}
                                {cartList_?.order_time
                                  ? cartList_?.order_time
                                  : ""}
                              </div>

                              {cartList_?.order_type === "delivery" ? (
                                <div className="mt-2 deails_fro">
                                  <img
                                    loading="lazy"
                                    alt="Logo"
                                    src="/assets/media/my/delivery.png"
                                    style={{
                                      height: "25px",
                                      marginRight: 10,
                                    }}
                                  />
                                  {"Deliver to :-"}{" "}
                                  {cartList_?.address_line2
                                    ? cartList_?.address_line2
                                    : ""}{" "}
                                  {cartList_?.address_line1
                                    ? cartList_?.address_line1
                                    : ""}
                                </div>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col>
                              <Form>
                                <Form.Group className="mb-3">
                                  <Form.Control
                                    type="text"
                                    value={full_name}
                                    style={{
                                      height: "45px",
                                      color: "#5a5a5a",
                                      borderRadius: "2px",
                                      border: "1px solid rgb(212 209 209)",
                                    }}
                                    onChange={(e) =>
                                      setfull_name(e.target.value)
                                    }
                                    placeholder={"Enter your full name *"}
                                  />
                                </Form.Group>

                                <Form.Group className={"mb-3"}>
                                  <Form.Control
                                    type="email"
                                    value={email}
                                    style={{
                                      height: "45px",
                                      color: "#5a5a5a",
                                      borderRadius: "2px",
                                      border: "1px solid rgb(212 209 209)",
                                      // boxShadow : '0 0 0 0.1rem rgba(13,110,253,.25)'
                                    }}
                                    onChange={(e) => setemail(e.target.value)}
                                    placeholder={
                                      language === "it"
                                        ? TableNo
                                          ? "Inserisci la tua email completa "
                                          : "Inserisci la tua email completa *"
                                        : language === "de"
                                          ? TableNo
                                            ? "Geben Sie Ihre vollständige E-Mail-Adresse ein "
                                            : "Geben Sie Ihre vollständige E-Mail-Adresse ein *"
                                          : language === "fr"
                                            ? TableNo
                                              ? "Entrez votre email complet "
                                              : "Entrez votre email complet *"
                                            : language === "AR"
                                              ? TableNo
                                                ? "أدخل بريدك الإلكتروني "
                                                : "أدخل بريدك الإلكتروني *"
                                              : TableNo
                                                ? "Enter your email "
                                                : "Enter your email *"
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="mb-1">
                                  <Form.Control
                                    type="text"
                                    value={phone_number}
                                    style={{
                                      height: "45px",
                                      color: "#5a5a5a",
                                      borderRadius: "2px",
                                      border: "1px solid rgb(212 209 209)",
                                    }}
                                    onChange={(e) =>
                                      setphone_number(e.target.value)
                                    }
                                    placeholder={"Enter your phone *"}
                                    className="filterme"
                                  />
                                </Form.Group>
                              </Form>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col>
                              <Form
                                onSubmit={
                                  cartList_?.discount_amount > 0
                                    ? Remove_Promocode
                                    : Check_Promocode
                                }
                              >
                                <InputGroup
                                  className={TableNo ? "mb-1 mt-2" : "mb-3"}
                                >
                                  <Form.Control
                                    required
                                    style={{
                                      height: "45px",
                                      color: "#5a5a5a",
                                      borderRadius: "2px",
                                      border: "1px solid rgb(212 209 209)",
                                    }}
                                    placeholder={"Promo Code"}
                                    disabled={
                                      cartList_?.discount_amount > 0
                                        ? true
                                        : false
                                    }
                                    value={promocode}
                                    onChange={(e) =>
                                      setpromocode(e.target.value)
                                    }
                                  />
                                  {cartList_?.discount_amount > 0 ? (
                                    <React.Fragment>
                                      {RemovePromocodeLoader === true ? (
                                        <Box>
                                          <CircularProgress
                                            size={40}
                                            thickness={5}
                                          />
                                        </Box>
                                      ) : (
                                        <Button
                                          variant="danger"
                                          id="promocode"
                                          type="submit"
                                        >
                                          Remove
                                        </Button>
                                      )}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      {ApplyPromocodeLoader === true ? (
                                        <Box>
                                          <CircularProgress
                                            size={40}
                                            thickness={5}
                                          />
                                        </Box>
                                      ) : (
                                        <Button
                                          variant="secondary"
                                          id="promocode"
                                          type="submit"
                                        >
                                          Apply
                                        </Button>
                                      )}
                                    </React.Fragment>
                                  )}
                                </InputGroup>
                                <div className="col-md-12">
                                  {" "}
                                  {errorDivpromo}{" "}
                                </div>
                                {TableNo ? (
                                  ""
                                ) : (
                                  <>
                                    {showordernote === true ? (
                                      <InputGroup>
                                        <InputGroup.Text>
                                          <img
                                            loading="lazy"
                                            alt="Logo"
                                            src="/assets/media/my/notes.png"
                                            style={{
                                              height: "50px",
                                            }}
                                          />
                                        </InputGroup.Text>

                                        <Form.Control
                                          as="textarea"
                                          style={{
                                            color: "#5a5a5a",
                                            borderRadius: "2px",
                                            border:
                                              "1px solid rgb(212 209 209)",
                                          }}
                                          placeholder={"Order Notes"}
                                          aria-label="Order Comments"
                                          value={order_notes}
                                          onChange={(e) =>
                                            setorder_notes(e.target.value)
                                          }
                                        />
                                      </InputGroup>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </Form>
                            </Col>
                          </Row>
                          <hr />
                          <form onSubmit={handalCheckOut}>
                            <Row>
                              {cartList_?.chargeInfo?.charges?.length > 0 ? (
                                <React.Fragment>
                                  <Col xs={12} lg={12}>
                                    <h6 className="order_summery mb-3">
                                      <b>
                                        {"Extra Charges"}
                                      </b>
                                    </h6>
                                  </Col>
                                  <Col xs={12} lg={12}>
                                    <h6 className="order_summery mb-3">
                                      <b>
                                        {language === "it"
                                          ? cartList_?.chargeInfo
                                            ?.charges_heading_name_IT
                                            ? cartList_?.chargeInfo
                                              ?.charges_heading_name_IT
                                            : cartList_?.chargeInfo
                                              ?.charges_heading_name_EN
                                          : language === "de"
                                            ? cartList_?.chargeInfo
                                              ?.charges_heading_name_DE
                                              ? cartList_?.chargeInfo
                                                ?.charges_heading_name_DE
                                              : cartList_?.chargeInfo
                                                ?.charges_heading_name_EN
                                            : language === "fr"
                                              ? cartList_?.chargeInfo
                                                ?.charges_heading_name_FR
                                                ? cartList_?.chargeInfo
                                                  ?.charges_heading_name_FR
                                                : cartList_?.chargeInfo
                                                  ?.charges_heading_name_EN
                                              : language === "AR"
                                                ? cartList_?.chargeInfo
                                                  ?.charges_heading_name_AR
                                                  ? cartList_?.chargeInfo
                                                    ?.charges_heading_name_AR
                                                  : cartList_?.chargeInfo
                                                    ?.charges_heading_name_EN
                                                : cartList_?.chargeInfo
                                                  ?.charges_heading_name_EN}
                                      </b>
                                    </h6>
                                  </Col>

                                  <Col xs={8} md={8} lg={8}>
                                    {cartList_?.chargeInfo?.charges?.map(
                                      (main, index) => (
                                        <React.Fragment key={index}>
                                          <Form.Check
                                            // inline
                                            name="payment_option"
                                            type="checkbox"
                                            // value="COD"
                                            // required
                                            // onChange={(e) => setpaymentmethod(e.target.value)}
                                            onChange={(event) =>
                                              handleChange(main, event)
                                            }
                                            label={main?.name_EN}
                                          />
                                        </React.Fragment>
                                      )
                                    )}
                                  </Col>
                                  <Col
                                    xs={4}
                                    md={4}
                                    lg={4}
                                    className="text-end"
                                  >
                                    {cartList_?.chargeInfo?.charges?.map(
                                      (main, index) => (
                                        <div key={index}>
                                          {currencyLabel} {Number(main.price).toFixed(2)}
                                        </div>
                                      )
                                    )}
                                  </Col>
                                  <hr className="mt-2" />
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </Row>

                            <Row>
                              <Col xs={12} lg={12}>
                                <h6 className="order_summery mb-3">
                                  <b>
                                    {"Order Summary"}
                                  </b>
                                </h6>
                              </Col>
                              <Col xs={6} lg={6}>
                                <span>Total item amount</span>
                              </Col>
                              <Col xs={6} lg={6}>
                                <span
                                  style={{ float: "right" }}
                                  className="notranslate"
                                >
                                  {/* CHF {cartList_?.total_amount_without_VAT} */}
                                  {currencyLabel}{" "}
                                  {Number(
                                    cartList_?.total_amount_without_Discount
                                  ).toFixed(2)}
                                </span>
                              </Col>
                              {cartList_?.VAT_charges !== null &&
                                cartList_?.VAT_charges !== undefined ? (
                                <React.Fragment>
                                  <Col xs={6} lg={6}>
                                    <span>Tax Charges</span>
                                  </Col>
                                  <Col xs={6} lg={6}>
                                    <span style={{ float: "right" }}>
                                      {currencyLabel} {cartList_?.VAT_charges.toFixed(2)}
                                    </span>
                                  </Col>
                                </React.Fragment>
                              ) : null}
                              {cartList_?.delivery_cost > 0 && (
                                <React.Fragment>
                                  <Col xs={6} lg={6}>
                                    <span>Delivery Fees</span>
                                  </Col>
                                  <Col xs={6} lg={6}>
                                    <span style={{ float: "right" }}>
                                      {currencyLabel}{" "}
                                      {Number(cartList_?.delivery_cost).toFixed(
                                        2
                                      )}
                                    </span>
                                  </Col>
                                </React.Fragment>
                              )}

                              {cartList_?.discount_amount > 0 && (
                                <React.Fragment>
                                  <Col xs={6} lg={6}>
                                    <span style={{ color: "green" }}>
                                      <b>Total Discount</b>
                                    </span>
                                  </Col>
                                  <Col xs={6} lg={6}>
                                    <span
                                      style={{ float: "right", color: "green" }}
                                    >
                                      <b>
                                        {currencyLabel}{" "}
                                        {Number(
                                          cartList_?.discount_amount
                                        ).toFixed(2)}
                                      </b>
                                    </span>
                                  </Col>
                                </React.Fragment>
                              )}
                            </Row>
                            <hr />
                            <Row>
                              <Col xs={6} lg={6}>
                                <h6 className="to_pay">
                                  <b>
                                    {"To Pay"}
                                  </b>
                                </h6>
                              </Col>
                              <Col xs={6} lg={6}>
                                <h5
                                  className="to_pay notranslate"
                                  style={{ float: "right" }}
                                >
                                  <b>
                                    {currencyLabel}{" "}
                                    {Number(
                                      Number(cartList_?.total_amount) +
                                      Number(totalCost ? totalCost : 0)
                                    ).toFixed(2)
                                      ? Number(
                                        Number(cartList_?.total_amount) +
                                        Number(totalCost ? totalCost : 0)
                                      ).toFixed(2)
                                      : Number(0).toFixed(2)}
                                  </b>
                                </h5>
                              </Col>
                            </Row>
                            <hr />

                            {Number(
                              Number(cartList_?.total_amount) +
                              Number(totalCost ? totalCost : 0)
                            ).toFixed(2) === 0 ? (
                              ""
                            ) : (
                              <React.Fragment>
                                <Row>
                                  <Col xs={5} lg={6} md={6}>
                                    <h5 className="to_pay">
                                      <b>
                                        {"Payment Mode"}
                                      </b>
                                    </h5>
                                  </Col>
                                  <Col
                                    xs={7}
                                    lg={6}
                                    md={6}
                                    className="text-end"
                                    style={{ marginRight: "-14px" }}
                                  >
                                    <Form.Group>
                                      {CartProductItem_[0]?.payment_method_online === true && (
                                        <Form.Check
                                          inline
                                          value="ONLINE"
                                          checked={paymentmethod === "ONLINE" || (!CartProductItem_[0]?.payment_method_cash)}
                                          onChange={(e) => setpaymentmethod(e.target.value)}
                                          name="payment_option"
                                          type="radio"
                                          label="Online"
                                        />
                                      )}
                                      {CartProductItem_[0]?.payment_method_cash === true && (
                                        <Form.Check
                                          inline
                                          name="payment_option"
                                          type="radio"
                                          value="COD"
                                          checked={paymentmethod === "COD" || (!CartProductItem_[0]?.payment_method_online)}
                                          onChange={(e) => setpaymentmethod(e.target.value)}
                                          label="Cash"
                                        />
                                      )}
                                    </Form.Group>

                                  </Col>
                                </Row>
                                <hr />
                              </React.Fragment>
                            )}

                            <Row>
                              <Col>
                                <Form.Group className="mb-2">
                                  <Form.Check
                                    type="checkbox"
                                    // required
                                    value={Terms_and_conditions}
                                    label={
                                      (
                                        <span>
                                          I confirm that I am 16 years or older
                                          and accept{" "}
                                          <Link
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={() => window.open(`${cartList_?.items?.length > 0 && cartList_?.items[0]?.doc_url}`)}
                                          >
                                            terms and conditions.
                                          </Link>
                                        </span>
                                      )
                                    }
                                    onChange={(e) =>
                                      setTerms_and_conditions(
                                        !Terms_and_conditions
                                      )
                                    }
                                  />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Check
                                    type="checkbox"
                                    checked={want_to_recieve_offers}
                                    // required
                                    value={want_to_recieve_offers}
                                    label={<span>
                                      Email me with news and offers.
                                    </span>}
                                    onChange={(e) =>
                                      setwant_to_recieve_offers(
                                        !want_to_recieve_offers
                                      )
                                    }
                                  />
                                </Form.Group>
                                <div className="col-md-12">
                                  {" "}
                                  {checkOutEroorDiv}{" "}
                                </div>
                                <div className="text-center">
                                  {loader === false ? (
                                    <Button
                                      variant="dark"
                                      type="submit"
                                      size="lg"
                                      style={{
                                        width: "100%",
                                        backgroundColor:
                                          customRes?.button_colour ?? "#dc3545",
                                        border: 0,
                                      }}
                                      className="custom_hover"
                                    >
                                      <strong
                                        style={{
                                          color:
                                            customRes?.non_highlighted_text ??
                                            "white",
                                        }}
                                      >
                                        Pay {" "}
                                        <span className="notranslate">{currencyLabel} {Number(
                                          Number(cartList_?.total_amount) +
                                          Number(totalCost ? totalCost : 0)
                                        ).toFixed(2)
                                          ? Number(
                                            Number(cartList_?.total_amount) +
                                            Number(
                                              totalCost ? totalCost : 0
                                            )
                                          ).toFixed(2)
                                          : Number(0).toFixed(2)}</span>
                                      </strong>
                                    </Button>
                                  ) : (
                                    <Box>
                                      <CircularProgress
                                        size={50}
                                        thickness={5}
                                      />
                                    </Box>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </form>
                        </Card.Body>
                      </Card>
                      {!isMobile && (
                        <LeftSideSvg />
                      )}
                    </Col>
                  </Row>
                  <br />
                </React.Fragment>
              </Container>
            )}
          </React.Fragment>
        )}
        <Footer />
      </div>
      <ToastContainer />
    </div>
  );
}

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  menuList: state.menu.menuList,
  branchdeliverysettingsdataData: state.branch.branchdeliverysettingsdataData,
  branchDineInTimeData: state.branch.branchDineInTimeData,
  branchdetailsbyid: state.branch.branchdetailsbyid,
  charges: state.order.charges,
  branchId: state.branch.branchId,
  branchName: state.branch.branchName,
  type: state.branch.type,
  showordernote: state.order.showordernote,
  deliverycosterror: state.order.deliverycosterror,
  deliverycost: state.order.deliverycost,
  language: state.branch.language,
  branchPrimaryLanguage: state.branch.branchPrimaryLanguage,
  branchLanguageData: state.branch.branchLanguageData,
  customRes: state.custom.customRes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProductList: (data) => dispatch(setProductList(data)),
    checkout: () => dispatch(checkout()),
    getCharges: () => dispatch(getCharges()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setType: (type) => dispatch(setType(type)),
    setCheckoutData: (data) => dispatch(setCheckoutData(data)),
    checkOrderNotes: () => dispatch(checkOrderNotes()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
    setDeliveryError: (error) => dispatch(setDeliveryError(error)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setBranchPrimaryLanguage: (language) =>
      dispatch(setBranchPrimaryLanguage(language)),
    getMenuList: () => dispatch(getMenuList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCartPage);
