import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InputGroup from "react-bootstrap/InputGroup";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Select from "react-select";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TooltipMui from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../CommonApi/axios";
import {
  clearChargesData,
  clearProductList,
  getBranch,
  getBranchDetails,
  getBranchTime,
  getCustomData,
  getMenuList,
  setBranchId,
  setBranchList,
  setBranchName,
  getOrderCost,
  setOrderCostData,
  setBranchTimeData,
  setCustome,
  setDeliveryCost,
  setLanguage,
  setProductList,
  setType,
  setOrderData,
} from "../../Store";
import moment from "moment";
import $ from "jquery";
import { ModalFooter } from "react-bootstrap";
import { Start, colourStylesHeader } from "../HomePage/selectstyle";
import { googleMapsApiKey } from "../../CommonApi/googelApiKey";
window.jquery = window.$ = $;

function RestrictedMenuPopup(props) {
  /////////////////////////////////////// Redux ///////////////////////////////////////

  const { language , customRes} = props;

  // console.log("props", props ?.BranchDetails_)
          
  let sessionBranchID = sessionStorage.getItem("sessionBranchID");
  let is_restricted = sessionStorage.getItem("is_restricted");

  /////////////////////////////////////// Redux ///////////////////////////////////////
  const [show, setShow] = useState( false);

  const QrCodeScanAndRedirect = sessionStorage.getItem("QrCodeScanAndRedirect");
//   console.log("QrCodeScanAndRedirect" ,QrCodeScanAndRedirect)
  const item_value = JSON.parse(sessionStorage.getItem("items"));
  const Delivery_detailes1 = JSON.parse(sessionStorage.getItem("Delivery_detailes"));
  const Pickup_detailes1 = JSON.parse(sessionStorage.getItem("Pickup_detailes"));

  useEffect(() => {
    if (Delivery_detailes1) {
      const updatedItems = { ...item_value, ...Delivery_detailes1 };
      sessionStorage.setItem("items", JSON.stringify(updatedItems));
      sessionStorage.removeItem("Delivery_detailes");
      sessionStorage.removeItem("Pickup_detailes");
    }
  }, [Delivery_detailes1]);

  useEffect(() => {
    if (Pickup_detailes1) {
      const updatedItems = { ...item_value, ...Pickup_detailes1 };
      sessionStorage.setItem("items", JSON.stringify(updatedItems));
      sessionStorage.removeItem("Delivery_detailes");
      sessionStorage.removeItem("Pickup_detailes");
    }
  }, [Pickup_detailes1]);

  useEffect(() => {
    if (QrCodeScanAndRedirect !== null) {
      setShow(false);
    }
  }, [QrCodeScanAndRedirect !== null]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);
  const [Delivery, setDelivery] = React.useState(-1);
  const [Pickup, setPickup] = React.useState(-1);
  const [isAvailable, setIsAvailable] = React.useState(true);
  const [BranchName_, setBranchName_] = useState("");
  const [CutomerPickup, setCutomerPickup] = useState("");

  const handleChange = (event, newValue) => {
    /////////////// Delivery Data Clear ///////////////
    setdelivery_order_dateA("");
    setdelivery_order_time("");
    setdelivery_order_timeA("");
    setaddress_line1("");
    setaddress_line2("");
    setLat("");
    setLng("");
    setErrorPromo(null);
    setIndexForDelievryDate("")

    /////////////////// Pickup Data Clear ///////////
    setpickup_order_dateA("");
    setpickup_order_time("");
    setpickup_order_timeA("");
    setpickup_order_date("");
    setpickup_order_time("");
    setError_pickup(null);
    setIndexForPickupDate("")
    setValue(newValue);
  };

  const [PreTime, setPreTime] = useState("23");
  const [address_line1, setaddress_line1] = useState("");
  const [address_line2, setaddress_line2] = useState("");
  const [delivery_order_date, setdelivery_order_date] = useState("");
  const [delivery_order_dateA, setdelivery_order_dateA] = useState("");
  const [delivery_order_time, setdelivery_order_time] = useState("");
  const [delivery_order_timeA, setdelivery_order_timeA] = useState("");
  const [pickup_order_date, setpickup_order_date] = useState("");
  const [pickup_order_dateA, setpickup_order_dateA] = useState("");
  const [pickup_order_time, setpickup_order_time] = useState("");
  const [pickup_order_timeA, setpickup_order_timeA] = useState("");

  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [pincode, setpincode] = useState("");

  const [error, setError] = useState(null);
  const errorDiv = error && (
    <div className="text-center alert alert-danger">{error}</div>
  );

  const [error_pickup, setError_pickup] = useState(null);
  const errorDiv_pickup = error_pickup && (
    <div className="text-center alert alert-danger">{error_pickup}</div>
  )

  function Delivery_detailes(event) {
    event.preventDefault();
    setError(null);

    // if (!validateEmail(email)) {
    //   setError("The entered email is invalid.");
    //   return;
    // }
    if (address_line1 === "") {
      setError("Pleace Select your address location");
      return;
    } else if (lat === "" && lng === "") {
      setError("Pleace Select your address location");
      return;
    } else if (delivery_order_date === null) {
      setError("Pleace select order date");
      return;
    } else if (delivery_order_time ==="") {
      setError("Pleace select your order time");
      return;
    } else {
      setError(null);

      // const value = delivery_order_date;

      // const formattedValue = value.replace('TODAY', moment().format('YYYY-MM-DD'));

      let item = {
        address_line1: address_line1,
        address_line2: address_line2,
        lat: String(lat),
        lng: String(lng),
        order_date:  delivery_order_date,
        order_time: delivery_order_time,
        order_type: "delivery",
        branch_id: sessionBranchID,
        pincode: String(pincode),
        PreTime: PreTime,
        delivery_order_dateA: delivery_order_dateA,
        delivery_order_timeA: delivery_order_timeA,
      };

      // console.log("item" ,item);
      // return

      sessionStorage.setItem("Delivery_detailes", JSON.stringify(item));
      sessionStorage.setItem("QrCodeScanAndRedirect", true);
      sessionStorage.removeItem("Pickup_detailes");
      sessionStorage.removeItem("DineIn_detailes");
      sessionStorage.removeItem("cart_id");
      // history.push(`/${BranchName_}/menu`);
      setaddress_line1("");
      setaddress_line2("");
      setLat("");
      setLng("");
      setdelivery_order_date("");
      setdelivery_order_time("");
      window.location.reload(true);
    }
  }

  function Pickup_detailes(event) {
    event.preventDefault();

    setError_pickup(null);

    if (pickup_order_date === "") {
      setError_pickup("Pleace select order date");
      return;
    } else if (pickup_order_time === "") {
      setError_pickup("Pleace select your order time");
      return;
    } else {
      setError_pickup(null);
      let item = {
        order_date: pickup_order_date,
        order_time: pickup_order_time,
        order_type: "pickup",
        branch_id: sessionBranchID,
        PreTime: PreTime,
        pickup_order_dateA: pickup_order_dateA,
        pickup_order_timeA: pickup_order_timeA,
      };

      sessionStorage.setItem("Pickup_detailes", JSON.stringify(item));
      sessionStorage.removeItem("Delivery_detailes");
      sessionStorage.removeItem("DineIn_detailes");
      sessionStorage.removeItem("cart_id");
      sessionStorage.setItem("QrCodeScanAndRedirect", true);
      // history.push(`/${BranchName_}/menu`);
      setpickup_order_date("");
      setpickup_order_time("");
      window.location.reload(true);
    }
  }

  function handleChangeAddress(address) {
    setaddress_line1(address);
  }

  const handleSelect = async (address) => {
    if (address !== "" && lat !== "") {
      setError(null);
    }
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    setaddress_line1(address);
    setLat(latLng.lat);
    setLng(latLng.lng);
    const address_ = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng.lat},${latLng.lng}&key=${googleMapsApiKey}`;
    fetch(address_)
      .then((res) => res.json())
      .then((resJson) => {
        const results = resJson?.results;
        let address = results[0].address_components;
        let zipcode = address[address.length - 1].long_name;
        getDeliveryCost(latLng.lat, latLng.lng, zipcode);
        // console.log("sas", latLng.lat, latLng.lng, zipcode);
        setpincode(zipcode);
      })
      .catch((error) => {});
  };

  const [errorpromo, setErrorPromo] = useState(null);
  const errorDivpromo = errorpromo ? errorpromo : "";

  function getDeliveryCost(latitude, longitude, pincode) {
    setErrorPromo(null);
    const data = {
      branch_id: sessionBranchID,
      // order_amount: Number(totalAmount),
      lat: latitude.toString(),
      long: longitude.toString(),
      pincode: pincode,
      order_type: "delivery",
    };
    api
      .post(`/client/order/checkForDelivery`, data)
      .then((res) => {
        setErrorPromo(null);
      })
      .catch((err) => {
        if (err.response) {
          setErrorPromo("Does not deliver this location");
        }
      });
  }

  const [DeliveryDays ,setDeliveryDays] = useState([]);
  const [DeliveryTimeList,setDeliveryTimeList] = useState([]);
  const [PickupTimeList ,setPickupTimeList ] = useState([]);
  const [PickupDays , setPickupDays] = useState([]);
  const [IndexForDelievryDate ,setIndexForDelievryDate] = useState("");
  const [IndexForPickupDate , setIndexForPickupDate] = useState("");

  useEffect(()=>{
      if(is_restricted == null){
      if(QrCodeScanAndRedirect === null){
          const Delivery__ =  props?.BranchDetails_?.is_delivery
          const Pickup__ =  props?.BranchDetails_?.is_pickup
          // console.log("Delivery__" ,Delivery__ ,"Pickup__" ,Pickup__ )
            setShow(props?.BranchDetails_?.resData?.is_restricted == true ? true : false)
            setIsAvailable(
              Delivery__ === false &&
                Pickup__ === false
                ? false
                : true
            );
            if (
              value === 1 &&
              Pickup__ === false &&
              Delivery__
            ) {
              setValue(0);
            }
            setCutomerPickup(
              Delivery__ === false &&
                Pickup__ === true
                ? 5
                : ""
            );
            setDelivery(
              Delivery__ === true ? 0 : -1
            );
            setPickup(
              Pickup__ === true ? 1 : -1
            );
            // setbranch_Id(props?.BranchDetails_?.resData?._id);
            setError(null);
            setBranchName_(props?.BranchDetails_?.resData?.branch_name_EN);

            /////////////// Delivery Data Clear ///////////////
            setdelivery_order_dateA("");
            setdelivery_order_time("");
            setdelivery_order_timeA("");
            setaddress_line1("");
            setaddress_line2("");
            setLat("");
            setLng("");
            setErrorPromo(null);

            /////////////////// Pickup Data Clear ///////////
            setpickup_order_dateA("");
            setpickup_order_time("");
            setpickup_order_timeA("");
            setpickup_order_date("");
            setpickup_order_time("");
            setError_pickup(null);
      }else{
      }
      }
},[props?.BranchDetails_ && QrCodeScanAndRedirect === null])

  useEffect(() => {
    if (sessionBranchID && Delivery === 0) {
      if(show === true){
        let item = {
          type: "delivery",
          branch_id: sessionBranchID,
          language: language.toUpperCase(),
        };
        // console.log("dsas_item" ,item)
        api
          .post(`/client/order/branchtimefilter`, item)
          .then((res) => {
            // setbranchTime(res.data.data);
            setDeliveryDays(res.data.data?.find(item => Array.isArray(item.dayList)) ? res.data.data?.find(item => Array.isArray(item.dayList)) : []);
            const dayList = res?.data?.data?.find(item => Array.isArray(item.timeList)) ? res?.data?.data?.find(item => Array.isArray(item.timeList)) : [];
            const timeList = dayList.timeList;   
            // console.log('timeList' ,timeList) 
            setDeliveryTimeList(timeList ? timeList : []);
            // console.log("delivery_data", res.data.data);
          })
          .catch((err) => {
            if (err.response) {
              console.log(err.response?.data?.error?.message);
            }
          });
      }   
      }
  }, [Delivery === 0]);

  useEffect(() => {
    if (sessionBranchID && Pickup === 1) {
      if(show === true){
          if(value === 1){
            let item = {
              type: "pickup",
              branch_id: sessionBranchID,
              language: language.toUpperCase(),
            };
            api
              .post(`/client/order/branchtimefilter`, item)
              .then((res) => {
                // setbranchTimePickup(res.data.data);
                setPickupDays(res.data.data?.find(item => Array.isArray(item.dayList)) ? res.data.data?.find(item => Array.isArray(item.dayList)) : []);
                const dayList = res?.data?.data?.find(item => Array.isArray(item.timeList)) ? res?.data?.data?.find(item => Array.isArray(item.timeList))  : [] ;
                const timeList = dayList.timeList;   
                // console.log('daylist' ,res.data.data?.find(item => Array.isArray(item.dayList))) 
                setPickupTimeList(timeList ? timeList : []);
                // console.log("pickTime", res.data.data);
              })
              .catch((err) => {
                if (err.response) {
                  console.log(err.response?.data?.error?.message);
                }
              });
          } 
      }   
    }
  }, [Pickup === 1 && value === 1]);

  useEffect(() => {
    if (sessionBranchID && Pickup === 1) {
      if(show === true){
        if(Delivery === -1 && Pickup === 1){
          let item = {
            type: "pickup",
            branch_id: sessionBranchID,
            language: language.toUpperCase(),
          };
          api
            .post(`/client/order/branchtimefilter`, item)
            .then((res) => {
              // setbranchTimePickup(res.data.data);
              setPickupDays(res.data.data?.find(item => Array.isArray(item.dayList)) ? res.data.data?.find(item => Array.isArray(item.dayList)) : []);
              const dayList = res?.data?.data?.find(item => Array.isArray(item.timeList)) ? res?.data?.data?.find(item => Array.isArray(item.timeList))  : [] ;
              const timeList = dayList.timeList;   
              // console.log('daylist' ,res.data.data?.find(item => Array.isArray(item.dayList))) 
              setPickupTimeList(timeList ? timeList : []);
              // console.log("pickTime", res.data.data);
            })
            .catch((err) => {
              if (err.response) {
                console.log(err.response?.data?.error?.message);
              }
            });
        }
      }
    }  
  }, [Delivery === -1 && Pickup === 1]);

  const selectOption = {
    menuPosition: "fixed",
    isSearchable: false,
    inputFocusOnClick: false,
    menuPortalTarget: document.body,
    styles: colourStylesHeader,
    placeholder: "Select",
  };

  return (
    <React.Fragment>
        <Modal
          centered
          show={show}
          style={{ overflow: "hidden" }}
      //     onHide={handleClose}
      //     closeButton
        >
          <Container>
            <Row>
              <Col>
                <Box sx={{ width: "100%" }}>
                  <Modal.Header  style={{ border: 0 }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        padding: 0,
                        border: 0,
                        marginTop: -1.5,
                      }}
                    >
                      {isAvailable ? (
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                        >
                          {Delivery === 0 && (
                            <Tab
                              label={"Delivery"}
                              className="delivery_tab"
                              {...a11yProps(0)}
                            ></Tab>
                          )}
                          {Pickup === 1 && (
                            <Tab
                              label={"Pickup"}
                              className="pickup_tab"
                              {...a11yProps(1)}
                            />
                          )}
                        </Tabs>
                      ) : (
                        <>Sorry</>
                      )}
                    </Box>
                  </Modal.Header>
                  {isAvailable ? (
                    <div>
                      <div style={{ marginTop: -16 }}>
                        {CutomerPickup === 5 ? (
                          <Form onSubmit={Pickup_detailes}>
                            <React.Fragment>
                              <Modal.Body>
                                <Form.Group>
                                  {CutomerPickup === 5 && (
                                    <Form.Label className="order_food">
                                      <React.Fragment>
                                        Pickup order from {BranchName_}
                                      </React.Fragment>
                                    </Form.Label>
                                  )}
                                </Form.Group>

                                <React.Fragment>
                                  <React.Fragment>
                                    <Row>
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        className="mt-0"
                                      >
                                        <Form.Label className="order_food">
                                          {"Pickup Date"}
                                          {Start}
                                        </Form.Label>
                                        <Select
                                          {...selectOption}
                                          options={
                                            PickupDays?.dayList &&
                                            PickupDays?.dayList?.map((day) => ({
                                              label: day.label,
                                              value: day.label,
                                              id: day.id
                                            }))
                                          }
                                          value={pickup_order_dateA}

                                          onChange={(opt) => {

                                            const index = PickupDays?.dayList?.findIndex(day => day.label === opt.label);

                                            if (opt.value.includes("TODAY")) {
                                              setpickup_order_date(
                                                moment().format()
                                              );
                                            } else if (opt.value.includes("TOMMORROW")) {
                                              const tomorrowDate = moment().add(1, 'day');
                                              setpickup_order_date(
                                                tomorrowDate.format()
                                              );
                                            } else {
                                              setpickup_order_date(
                                                moment(opt.value).format()
                                              );
                                            }
                                            setIndexForPickupDate(index)
                                            setpickup_order_time("");
                                            setpickup_order_timeA("");
                                            setpickup_order_dateA(opt);
                                          }}
                                        />
                                      </Form.Group>
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        className="mt-0"
                                      >
                                        <Form.Label className="order_food">
                                          {"Pickup Time"}
                                          {Start}
                                        </Form.Label>
                                        <Select
                                          {...selectOption}
                                          options={
                                            PickupTimeList[IndexForPickupDate] &&
                                            PickupTimeList[IndexForPickupDate]?.map((time) => ({
                                              label: time,
                                              value: time,
                                            }))
                                          }
                                          value={pickup_order_timeA}
                                          onChange={(opt) => {
                                            setpickup_order_time(opt.label);
                                            setpickup_order_timeA(opt);
                                          }}
                                        />
                                      </Form.Group>
                                    </Row>
                                    <div className="col-md-12 mt-3">
                                      {" "}
                                      {errorDiv_pickup}{" "}
                                    </div>
                                  </React.Fragment>
                                </React.Fragment>

                              </Modal.Body>
                              <ModalFooter>
                                <Container>
                                  <Row>
                                    <Col className="text-center">
                                      <button
                                        type="submit"
                                        style={{
                                          backgroundColor:
                                            customRes?.button_colour ??
                                            "#dc3545",
                                          border: 0,
                                        }}
                                        className="proceed_button text-center custom_hover"
                                      >
                                        <strong
                                          style={{
                                            color:
                                              customRes?.non_highlighted_text ??
                                              "white",
                                          }}
                                        >
                                          {"PROCEED"}
                                        </strong>
                                      </button>
                                    </Col>
                                  </Row>
                                </Container>
                              </ModalFooter>
                            </React.Fragment>
                          </Form>
                        ) : (
                          <Form
                            onSubmit={
                              value === 0
                                ? Delivery_detailes
                                : value === 1
                                  ? Pickup_detailes
                                  : ""
                            }
                          >
                            <React.Fragment>
                              <Modal.Body>
                                <Form.Group>
                                  <Form.Label className="order_food">
                                    {value === 0 && (
                                      <React.Fragment>
                                        Delivery order from {BranchName_}
                                      </React.Fragment>
                                    )}
                                    {value === 1 && (
                                      <React.Fragment>
                                        Pickup order from {BranchName_}
                                      </React.Fragment>
                                    )}
                                  </Form.Label>

                                  {value === 0 && (
                                    <>
                                      <PlacesAutocomplete
                                        value={address_line1}
                                        onChange={handleChangeAddress}
                                        onSelect={handleSelect}
                                      >
                                        {({
                                          getInputProps,
                                          suggestions,
                                          getSuggestionItemProps,
                                          loading,
                                        }) => (
                                          <div>
                                            <Form.Group>
                                              <InputGroup className="mb-3">
                                                <Form.Control
                                                  style={{
                                                    height: "45px",
                                                    color: "#5a5a5a",
                                                    borderRadius: "2px",
                                                    border:
                                                      "1px solid rgb(212 209 209)",
                                                    // boxShadow : '0 0 0 0.1rem rgba(13,110,253,.25)'
                                                  }}
                                                  // onSelect={handleSelect}
                                                  // value={address_line1}
                                                  // onChange={handleChangeAddress}
                                                  {...getInputProps({
                                                    placeholder:
                                                      "Search Places  ... ",
                                                    className:
                                                      "location-search-input",
                                                  })}
                                                />
                                                <TooltipMui title="Current Location">
                                                  <InputGroup.Text id="basic-addon2">
                                                    <LocationOnIcon />
                                                  </InputGroup.Text>
                                                </TooltipMui>
                                              </InputGroup>
                                            </Form.Group>

                                            <div className="autocomplete-dropdown-container">
                                              {loading && <div>Loading...</div>}
                                              {suggestions.map(
                                                (suggestion, index) => {
                                                  const className =
                                                    suggestion.active
                                                      ? "suggestion-item--active"
                                                      : "suggestion-item";
                                                  // inline style for demonstration purpose
                                                  const style =
                                                    suggestion.active
                                                      ? {
                                                        backgroundColor:
                                                          "#C2DEDC",
                                                        cursor: "pointer",
                                                      }
                                                      : {
                                                        backgroundColor:
                                                          "#ffffff",
                                                        cursor: "pointer",
                                                      };
                                                  return (
                                                    <div
                                                      key={index}
                                                      {...getSuggestionItemProps(
                                                        suggestion,
                                                        {
                                                          className,
                                                          style,
                                                        }
                                                      )}
                                                    >
                                                      <div
                                                        // onKeyDown={onKeyDown}
                                                        value={address_line1}
                                                        onClick={
                                                          handleChangeAddress
                                                        }
                                                        onSelect={handleSelect}
                                                      >
                                                        {suggestion.description}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </PlacesAutocomplete>
                                      {errorDivpromo && (
                                        <div
                                          className="text-start my-2"
                                          style={{
                                            color: "#FF0000",
                                            alignSelf: "start",
                                            fontSize: 14,
                                            marginLeft: 10,
                                          }}
                                        >
                                          {errorDivpromo}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </Form.Group>
                                {value === 0 && (
                                  <Form.Group
                                    className="mb-1"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Control
                                      style={{
                                        height: "45px",
                                        color: "#5a5a5a",
                                        borderRadius: "2px",
                                        border: "1px solid rgb(212 209 209)",
                                      }}
                                      type="text"
                                      placeholder={"Address line"}
                                      value={address_line2}
                                      onChange={(e) =>
                                        setaddress_line2(e.target.value)
                                      }
                                    />
                                  </Form.Group>
                                )}

                                <React.Fragment>
                                  {value === 0 ? (
                                    <React.Fragment>
                                      <Row>
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          className="my-2"
                                        >
                                          <Form.Label className="order_food">
                                            {"Delivery Date"}
                                            {Start}
                                          </Form.Label>
                                          <Select
                                           {...selectOption}
                                            value={delivery_order_dateA}
                                            options={
                                              DeliveryDays?.dayList &&
                                              DeliveryDays?.dayList?.map((day) => ({
                                                label: day.label,
                                                value: day.label,
                                                id: day.id
                                              }))
                                            }
                                            onChange={(opt) => {

                                              const index = DeliveryDays?.dayList?.findIndex(day => day.label === opt.label);

                                              if (opt.value.includes("TODAY")) {
                                                setdelivery_order_date(
                                                  moment().format()
                                                );
                                              } else if (opt.value.includes("TOMMORROW")) {
                                                const tomorrowDate = moment().add(1, 'day');
                                                setdelivery_order_date(
                                                  tomorrowDate.format()
                                                );
                                              } else {
                                                setdelivery_order_date(
                                                  moment(opt.value).format()
                                                );
                                              }

                                              setdelivery_order_dateA(opt);
                                              setdelivery_order_time("");
                                              setdelivery_order_timeA("");
                                              setIndexForDelievryDate(index);

                                            }}
                                          />
                                        </Form.Group>
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          className="my-2"
                                        >
                                          <Form.Label className="order_food">
                                            {"Delivery Time"}
                                            {Start}
                                          </Form.Label>
                                          <Select
                                            {...selectOption}
                                            options={
                                              DeliveryTimeList[IndexForDelievryDate] &&
                                              DeliveryTimeList[IndexForDelievryDate]?.map((time) => ({
                                                label: time,
                                                value: time,
                                              }))
                                            }
                                            onChange={(opt) => {
                                              setdelivery_order_time(
                                                opt.label
                                              );
                                              setdelivery_order_timeA(opt);
                                            }}
                                            value={delivery_order_timeA}
                                          />
                                        </Form.Group>
                                      </Row>
                                      <div className="col-md-12">
                                        {" "}
                                        {errorDiv}{" "}
                                      </div>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <Row>
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          className="mt-0"
                                        >
                                          <Form.Label className="order_food">
                                            {"Pickup Date"}
                                            {Start}
                                          </Form.Label>
                                          <Select
                                           {...selectOption}
                                            options={
                                              PickupDays?.dayList &&
                                              PickupDays?.dayList?.map((day) => ({
                                                label: day.label,
                                                value: day.label,
                                                id: day.id
                                              }))
                                            }
                                            value={pickup_order_dateA}
                                            onChange={(opt) => {
                                              const index = PickupDays?.dayList?.findIndex(day => day.label === opt.label);
                                              if (opt.value.includes("TODAY")) {
                                                setpickup_order_date(
                                                  moment().format()
                                                );
                                              } else if (opt.value.includes("TOMMORROW")) {
                                                const tomorrowDate = moment().add(1, 'day');
                                                setpickup_order_date(
                                                  tomorrowDate.format()
                                                );
                                              } else {
                                                setpickup_order_date(
                                                  moment(opt.value).format()
                                                );
                                              }

                                              setIndexForPickupDate(index);
                                              setpickup_order_time("");
                                              setpickup_order_timeA("");
                                              setpickup_order_dateA(opt);
                                            }}
                                          />
                                        </Form.Group>
                                        <Form.Group
                                          as={Col}
                                          md="6"
                                          className="mt-0"
                                        >
                                          <Form.Label className="order_food">
                                            {"Pickup Time"}
                                            {Start}
                                          </Form.Label>
                                          <Select
                                           {...selectOption}
                                            options={
                                              PickupTimeList[IndexForPickupDate] &&
                                              PickupTimeList[IndexForPickupDate]?.map((time) => ({
                                                label: time,
                                                value: time,
                                              }))
                                            }
                                            value={pickup_order_timeA}
                                            onChange={(opt) => {
                                              setpickup_order_time(opt.label);
                                              setpickup_order_timeA(opt);
                                            }}
                                          />
                                        </Form.Group>
                                      </Row>
                                      <div className="col-md-12 mt-3">
                                        {" "}
                                        {errorDiv_pickup}{" "}
                                      </div>
                                    </React.Fragment>
                                  )}
                                </React.Fragment>
                              </Modal.Body>
                              <ModalFooter>
                                <Container>
                                  <Row>
                                    <Col className="text-center">
                                      <button
                                        disabled={value === 0 && errorpromo === "Does not deliver this location"}
                                        type="submit"
                                        style={{
                                          backgroundColor: customRes?.button_colour ?? "#dc3545",
                                          border: 0,
                                        }}
                                        className="proceed_button text-center custom_hover"
                                      >
                                        <strong
                                          style={{
                                            color: customRes?.non_highlighted_text ?? "white",
                                          }}
                                        >
                                          {"PROCEED"}
                                        </strong>
                                      </button>
                                    </Col>
                                  </Row>
                                </Container>
                              </ModalFooter>
                            </React.Fragment>
                          </Form>
                        )}
                      </div>
                    </div>
                  ) : (
                    <Modal.Body>
                      <Row className="text-center apologies">
                        <Col>
                          {"Apologies, We aren’t providing delivery or pick up at the moment. We will start accepting orders after sometime till then Enjoy your meal at the restaurant."}
                        </Col>
                      </Row>
                    </Modal.Body>
                  )}
                </Box>
              </Col>
            </Row>
          </Container>
        </Modal>
        <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  language: state.branch.language,
  type: state.branch.type,
  branchList: state.branch.branchList,
  branchId: state.branch.branchId,
  branchTime: state.branch.branchTime,
  customRes: state.custom.customRes,
  customUrl: state.custom.customUrl,
  deliverycosterror: state.order.deliverycosterror,
  branchwelcomemessageData: state.branch.branchwelcomemessageData,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (language) => dispatch(setLanguage(language)),
    setType: (type) => dispatch(setType(type)),
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
    getBranch: () => dispatch(getBranch()),
    getBranchTime: () => dispatch(getBranchTime()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setBranchTimeData: (data) => dispatch(setBranchTimeData(data)),
    setBranchList: (data) => dispatch(setBranchList(data)),
    getMenuList: () => dispatch(getMenuList()),
    clearProductList: () => dispatch(clearProductList()),
    clearChargesData: () => dispatch(clearChargesData()),
    setDeliveryCost: (value) => dispatch(setDeliveryCost(value)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedMenuPopup);
