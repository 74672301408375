import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "react-responsive";
import api from "../../CommonApi/axios";
import {
  clearChargesData,
  clearProductList,
  getBranch,
  getBranchDetails,
  getBranchTime,
  getCustomData,
  getMenuList,
  setBranchId,
  setBranchList,
  setBranchName,
  getOrderCost,
  setOrderCostData,
  setBranchTimeData,
  setCustome,
  setDeliveryCost,
  setLanguage,
  setType,
} from "../../Store";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import RightSvg from "../HomePage/Common/RightSvg";
import LiftSvg from "../HomePage/Common/LiftSvg";
import Loading from "../CommonComponet/Loading/Loading";
import { showSuccessNotificationCustome } from "../CommonComponet/Swal/Swal";

function DineInRedirectPage(props) {
  /////////////////////////////////////// Redux ///////////////////////////////////////

  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const {
    customRes,
  } = props;

  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const TableNo = query.get("no");

  //   console.log("TableNo" , TableNo)
  const history = useHistory();
  const [branc_not, setbranc_not] = useState(false);
  const [BranchID, setBranchID] = useState("")

  const { poId } = useParams();

  var urlBranchID = decodeURIComponent(poId)

  useEffect(() => {
    api
      .get(`/client/order/getBranchId/${urlBranchID}`)
      .then((res) => {
        setBranchID(res?.data?.data?._id);
        sessionStorage.setItem("sessionBranchID", res?.data?.data?._id);
        // console.log("tabals", res?.data?.data);
      })
      .catch((err) => {
        // console.log(err?.response?.data?.error?.message);
        if (err?.response?.data?.error?.message) {
          setbranc_not(true)
          if (err.response?.data?.error?.message === undefined || err.response?.data?.error?.message) {
            showSuccessNotificationCustome("We're sorry, but orders cannot be placed at this time as the branch is currently inactive.", history)
          }
        }
      });
  }, [urlBranchID]);


  // let sessionBranchID = sessionStorage.getItem("sessionBranchID");
  useEffect(() => {
    tableStatusUpdate();
  }, [BranchID && TableNo])


  //   const [DineInTimeData_ ,setDineInTimeData_] = useState("")

  function tableStatusUpdate() {
    if (BranchID && TableNo) {
      api
        .patch(`/client/cart/openTable/${BranchID}/${TableNo}`)
        .then((res) => {
          setbranc_not(res?.data?.unique_code ? true : false);
          //   console.log("tableStatusUpdate",tableData);
          if (res?.data?.unique_code) {
            history.push(`/${urlBranchID}/menu?no=${TableNo}&sessionId=${res?.data?.unique_code}`)
          }
          //   setDineInTimeData_(moment(res?.data?.dine_in_start).format('h:mm a'))
        })
        .catch((err) => {
          setbranc_not(true);
        });
    }
  }


  return (
    <React.Fragment>
      <div
        style={{
          background: "radial-gradient(76.08% 76.08% at 50.00% 50.00%, rgba(255, 185, 34, 0.96) 0%, rgba(255, 185, 34, 0.00) 100%)",
          minHeight: "100vh",
          overflowX: "hidden",
        }}
      >
        <CommonHeader />
        <br />
        <br />
        <Row className="justify-content-sm-center">
          <Col xs={1} sm={1} lg={1} md={1} >
            {!isMobile && (
              <RightSvg color={customRes?.button_colour} />
            )}
          </Col>

          <Col xs={10} lg={10} md={10}>
            {branc_not === false && (
              <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Body>
                  <Container>
                    <Row className="justify-content-sm-center">
                      <React.Fragment>
                        <h3 className="heading mb-3 mt-2">
                          Please wait a moment
                        </h3>
                        <div className="text-center">
                          <Loading type={"HomePage"} />
                        </div>
                      </React.Fragment>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            )}
          </Col>
          <Col xs={1} sm={1} lg={1} md={1}>
            {!isMobile && (
              <LiftSvg color={customRes?.button_colour} />
            )}</Col>
        </Row>

        <ToastContainer />
        <br />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  language: state.branch.language,
  type: state.branch.type,
  branchList: state.branch.branchList,
  branchId: state.branch.branchId,
  branchTime: state.branch.branchTime,
  customRes: state.custom.customRes,
  customUrl: state.custom.customUrl,
  deliverycosterror: state.order.deliverycosterror,
  branchwelcomemessageData: state.branch.branchwelcomemessageData,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (language) => dispatch(setLanguage(language)),
    setType: (type) => dispatch(setType(type)),
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
    getBranch: () => dispatch(getBranch()),
    getBranchTime: () => dispatch(getBranchTime()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setBranchTimeData: (data) => dispatch(setBranchTimeData(data)),
    setBranchList: (data) => dispatch(setBranchList(data)),
    getMenuList: () => dispatch(getMenuList()),
    clearProductList: () => dispatch(clearProductList()),
    clearChargesData: () => dispatch(clearChargesData()),
    setDeliveryCost: (value) => dispatch(setDeliveryCost(value)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DineInRedirectPage);

