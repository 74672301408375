import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import { Card, Col, InputGroup, Row } from "react-bootstrap";
import Select from "react-select";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import "./giftcard.css";
import api from "../../CommonApi/axios";
import $ from "jquery";
import {
  clearChargesData,
  clearProductList,
  getBranch,
  getBranchDetails,
  getBranchTime,
  getCustomData,
  getMenuList,
  setBranchId,
  setBranchList,
  setBranchName,
  getOrderCost,
  setOrderCostData,
  setBranchTimeData,
  setCustome,
  setDeliveryCost,
  setLanguage,
  setProductList,
  setType,
} from "../../Store";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { connect } from "react-redux";
import Badge from '@mui/material/Badge';
import useError from "../CommonComponet/Error/useError";
import { useIsMobile, useIsTablet } from "../CommonComponet/MediaQueries/mediaQueries";
import { extractHostname } from "../CommonComponet/UrlUtils/urlUtils";
import { validateEmail } from "../CommonComponet/Email/Email";
import CommonHeader from "../../Comman/CommonHeader.js/CommonHeader";
import Footer from "../../Comman/Footer";
import { getCurrencyLabel } from "../CommonComponet/currencyUtils/currencyUtils";
import { applyFilterMeHandlers } from "../CommonComponet/jqueryUtils/jqueryUtils";
import { Errormessage } from "../CommonComponet/Error/Errormessage";

window.jquery = window.$ = $;

function NewGiftCard(props) {

  const { error, showError, clearError } = useError();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const {customRes} = props;

  useEffect(() => {
    applyFilterMeHandlers()
  }, [])

  // console.log('branchList' ,branchList)

  const colourStylesHeader = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      borderRadius: 0,
      height: 45
    }),
  };



  ///////////////////////////// Branch List ///////////////////////

  const hostname = extractHostname();
  const bbbb = "mcd.mypreview.xyz";

  useEffect(() => {
    getBranchList();
  }, []);
  const [user, setuser] = useState([]);
  const [branch_id, setbranch_id] = useState("");
  const [branch_id2, setbranch_id2] = useState("");

  function getBranchList() {
    let item = {
      url: hostname,
      language: "EN",
    };
    api
      .post(`/client/order/branchlist`, item)
      .then((res) => {
        const options = res.data.data.map((item) => ({
          label: item.branch_name_EN,
          value: item.branch_id,
          currency: item?.currency
        }));
        setuser(options);
      })
      .catch((err) => {
        console.log(err.response?.data?.error?.message);
      });
  }

  ////////////////////////// Voucher List ////////////////////////////

  const [VoucheList, setVoucheList] = useState([]);

  useEffect(() => {
    setVoucheList([]);
    if (branch_id !== "") {
      let item = {
        branch_id: branch_id,
      };
      api
        .post(`/client/available_voucher/List`, item)
        .then((res) => {
          const tableData = res.data.data;
          // const result = tableData.filter((word) => word?.header_image);
          setVoucheList(tableData);
        })
        .catch((err) => {
          console.log(err.response?.data?.error?.message);
        });
    }
  }, [branch_id]);


  const [client_name, setclient_name] = useState("");
  const [client_mobile_number, setclient_mobile_number] = useState("");
  const [client_email, setclient_email] = useState("");
  const [voucherAmount, setVoucherAmount] = useState("")
  const [voucherAmount_custome, setvoucherAmount_custome] = useState("")
  const [VoucherId, setVoucherId] = useState("");
  const [VoucherTitle, setVoucherTitle] = useState("");
  const [salutation_title, setsalutation_title] = useState("");
  const [voucher_validity, setvoucher_validity] = useState("");
  const [voucher_validity_type, setvoucher_validity_type] = useState("");
  const [percentage_discount, setpercentage_discount] = useState("");

  const [message, setmessage] = useState("");


  const [imgData, setImgData] = useState(null);
  const [image, setimage] = useState("");


  const handleFileChange = (header_image) => {
    if (header_image) {
      setimage(header_image);
      setImgData(header_image);
    }
  };

  const [loader, setloader] = useState(false);

  const cardRef = useRef(null);

  function handalSubmit(event) {
    event.preventDefault();
    clearError(null);

    if (branch_id === "") {
      showError("Please select branch");
      return;
    }
    else if (VoucherId === "") {
      showError("Please select voucher type");
      return;
    }
    else if (voucherAmount === "") {
      showError("Please select gift card amount");
      return;
    }
    else if (client_name === "") {
      showError("Please enter your full name");
      return;
    }
    else if (client_email === "") {
      showError("Please enter your email address");
      return;
    } else if (!validateEmail(client_email)) {
      showError("The entered email is invalid.");
      return;
    } else if (client_mobile_number === "") {
      showError("Please enter your phone number");
      return;
    } else {
      const item = {
        client_name: client_name,
        client_mobile_number: client_mobile_number,
        client_email: client_email,
        voucher_amount: voucherAmount?.value === "Custom" ? voucherAmount_custome : voucherAmount?.value,
        message: message,
        discounted_amount: PayCHF
      };
      // console.log("item", item);
      // return
      setloader(true);
      api
        .post(`/client/purchase_voucher/${VoucherId}`, item)
        .then((response) => {
          clearError(null);
          // const tableData = response?.data?.data;
          const redirectUrl = response.data.data?.paymentRes?.redirectUrl || response.data.data?.paymentRes?.RedirectUrl;
          if (redirectUrl) {
            setloader(false);
            window.open(`${redirectUrl}`, "_self");
          }
        })
        .catch((err) => {
          setloader(false);
          showError(err?.response?.data?.error?.message)
        });
    }
  }

  // console.log("branch_id2", branch_id2)


  const currencyLabel = getCurrencyLabel(branch_id2 && branch_id2?.currency);

  const PayCHF0 = Number(voucherAmount?.value === "Custom" ? voucherAmount_custome : voucherAmount?.value).toFixed(2)
  const PayCHF1 = Number(percentage_discount > 0) && Number(PayCHF0 * percentage_discount).toFixed(2) / Number(100)
  const PayCHF = Number(PayCHF0 - PayCHF1).toFixed(2)

  // console.log("PayCHF" ,PayCHF)

  const backgroundImageClass = isMobile
    ? "background-image_mobile"
    : isTablet
      ? "background-image_table"
      : "background-image";


  const HR = <Container><hr style={{ border: "1px solid rgb(191 192 195)", opacity: 0.2 }} /></Container>
  const START = <span style={{ color: "red" }}> * </span>

  return (
    <React.Fragment>
      <CommonHeader />
      <div style={{ backgroundColor: "#F0EEED", minHeight: "100vh" }}>
        <br />
        <Row className={isMobile ? "mx-1" : isTablet ? "mx-1" : "mx-5"}>
          <Col lg={4} md={5} xs={12} className="my-2">
            <Card ref={cardRef} style={{ border: 0, borderRadius: "3px", borderRadius: "19px 19px 19px 19px" }} className="card-container">
              <Card.Body className="p-0">
                <Card.Img
                  loading="lazy"
                  className={backgroundImageClass}
                  variant="top"
                  src={image || "/assets/media/my/Rectangle 15.png"} style={{
                    display: "-webkit-fill-available",
                    // objectFit : "cover",
                    borderRadius: "19px 19px 0px 0px",
                    borderBottom: "16px solid #8C0B04"
                  }} />
              </Card.Body>
              <Card.Body
                style={{ marginTop: 0, position: "relative" }}
              >
                <img src="/assets/media/my/Ri.png"
                  loading="lazy"
                  style={{
                    display: "-webkit-fill-available",
                    marginTop: 0,
                    zIndex: 999,
                    position: "absolute",
                    left: "0",
                    right: "0",
                    marginLeft: "auto",
                    maxWidth: isMobile ? "100px" : isTablet ? "110px" : "140px",
                    width: "100%",
                    top: isMobile ? "-46px" : isTablet ? "-43px" : "-59px"

                    // marginTop : isMobile ? "-34px" : isTablet ? "-45px" : "-61px",
                    // borderRadius : "19px 19px 0px 0px"
                  }} />
                <Row>
                  <Card.Title className="gift_voucher">
                    {VoucherTitle ? VoucherTitle : "Gift Voucher"}
                  </Card.Title>
                </Row>
                <Row>
                  <Card.Title className="gift_voucher_branch">
                    {branch_id2?.label ? branch_id2?.label : ""}
                  </Card.Title>
                </Row>
                <Row className="mobile_row">
                  <Col className="dear_name" lg={7} md={6} xs={6}>
                    {salutation_title ? salutation_title : "Dear"} , {client_name ? client_name : "Your Name"}
                  </Col>

                  <Col className="CHF_" lg={5} md={6} xs={6}>
                    {currencyLabel} {Number(PayCHF) > 0 ? Number(PayCHF || 0).toFixed(2) : 0.00}
                  </Col>
                </Row>
                <Row className="my-1">
                  <Col lg={6} md={6} xs={7} className="your_message">{message ? message?.substring(0, 250) : "your message..."} </Col>
                  <Col lg={6} md={6} xs={5} >
                    <Card.Text className="validity">
                      Code: XXXX-XXXX
                      {voucher_validity &&
                        <div className={"validity"}>
                          Valid till <span className="span_">{voucher_validity} {voucher_validity_type}</span>
                        </div>}
                    </Card.Text>
                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </Col>

          <Col lg={8} md={7} xs={12} className="my-2 mb-4">
            <Card style={{ border: 0, borderRadius: 0 }}>
              <Card.Body >
                <Form onSubmit={handalSubmit}>
                  <Row>
                    <Col lg={12} md={12} xs={12}>
                      <Card.Title className="gitf_card_title"> Gift Card </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted common_line">Choose the style and enter the gift card details here</Card.Subtitle>
                    </Col>
                    <Col lg={4} md={4} xs={12} className="text-end">
                    </Col>
                    {HR}
                    <Col lg={5} md={12} xs={12}>
                      <Card.Title className="common_tittel">Branch Name {START}</Card.Title>
                      {/* <Card.Subtitle className="mb-2 text-muted common_line">This voucher will be for the branch</Card.Subtitle> */}
                    </Col>
                    <Col lg={7} md={12} xs={12}>
                      <Form.Group className="my-2">
                        <Select
                          isSearchable={false}
                          blurInputOnSelect={true}
                          styles={colourStylesHeader}
                          className="search-line"
                          placeholder="Select Branch"
                          options={user}
                          value={branch_id2}
                          onChange={(opt) => {
                            setbranch_id(opt.value);
                            setbranch_id2(opt);
                            setVoucherAmount("")
                          }}
                        />
                      </Form.Group>
                    </Col>
                    {branch_id ?
                      <React.Fragment>
                        <Container>
                          <hr style={{ border: "1px solid rgb(191 192 195)" }} />
                        </Container>
                        <Col lg={5} md={12} xs={12}>
                          <Card.Title className="common_tittel">Voucher Theme {START}</Card.Title>
                        </Col>
                        <Col lg={7} md={12} xs={12}>
                          <Row>
                            <Col lg={1} md={1} xs={1}> </Col>
                            <Col lg={10} md={10} xs={10}>
                              <Form.Group>
                                {VoucheList.length > 0 ? (
                                  <Splide
                                    options={{
                                      perPage: isMobile ? 1 : isTablet ? 2 : 3,
                                      perMove: 1,
                                      gap: isMobile ? '1rem' : isTablet ? '2rem' : '',
                                    }}
                                    aria-label="React Splide Example"
                                  >
                                    {VoucheList.map((main, index) => (
                                      <SplideSlide
                                        key={index}
                                        onClick={() => {
                                          setVoucherId(main?._id);
                                          setVoucherTitle(main?.voucher_title);
                                          setsalutation_title(main?.salutation_title);
                                          setvoucher_validity_type(main?.voucher_validity_type);
                                          setvoucher_validity(main?.voucher_validity);
                                          handleFileChange(main?.header_image);
                                          setpercentage_discount(main?.percentage_discount);
                                        }}
                                      >
                                        <Row>
                                          <Col
                                            className="discount"
                                          >
                                            <Card
                                              style={{
                                                height: isMobile ? "90px" : "85px",
                                                width: isMobile ? "70%" : "130px",
                                                border:
                                                  VoucherId === main?._id
                                                    ? `4px solid ${customRes?.button_colour ?? "#dc3545"}`
                                                    : 0,
                                              }}
                                            >
                                              {main?.header_image ? (
                                                <React.Fragment>
                                                  <img
                                                    loading="lazy"
                                                    style={{
                                                      height: "100%",
                                                      // position : "absolute",
                                                      width: "100%",
                                                      objectFit: "cover",
                                                      borderRadius: VoucherId === main?._id ? "0px" : "12px",
                                                    }}
                                                    src={main?.header_image}
                                                    alt={"Voucher Image"}
                                                  />
                                                  {main?.percentage_discount == 0 ? "" :
                                                    <Badge
                                                      color="secondary" badgeContent={`- ${main?.percentage_discount}%`} />}
                                                  {VoucherId === main?._id ? (
                                                    <div className="positioner">
                                                      <CheckIcon
                                                        style={{
                                                          color: customRes?.non_highlighted_text ?? "white",
                                                          backgroundColor: customRes?.button_colour ?? "#dc3545",
                                                        }}
                                                        className="icon"
                                                      />
                                                    </div>
                                                  ) : null}
                                                </React.Fragment>
                                              ) : (
                                                <img
                                                  loading="lazy"
                                                  style={{
                                                    height: "100%",
                                                    width: "100%",
                                                    objectFit: "cover",
                                                    backgroundColor: "#bab4b4",
                                                    borderRadius: VoucherId === main?._id ? "0px" : "12px",
                                                  }}
                                                  src={main?.header_image}
                                                  alt={"No voucher image found"}
                                                />
                                              )}
                                            </Card>
                                          </Col>
                                        </Row>
                                      </SplideSlide>
                                    ))}
                                  </Splide>
                                ) :
                                  <React.Fragment>
                                    {isMobile || isTablet ? <h6 className="text-center">
                                      <InfoIcon style={{ color: "#ff6a2c" }} />  Currently there is no voucher available in the branch.
                                    </h6> :
                                      <h5 className="text-center">
                                        <InfoIcon style={{ color: "#ff6a2c" }} />  Currently there is no voucher available in the branch.
                                      </h5>}
                                  </React.Fragment>}

                              </Form.Group></Col>
                            <Col lg={1} md={1} xs={1}> </Col>
                          </Row>
                        </Col>
                      </React.Fragment> : ""}
                      {HR}

                    <Col lg={5} md={12} xs={12}>
                      <Card.Title className="common_tittel">Gift Card Amount {START}</Card.Title>
                    </Col>
                    <Col lg={7} md={12} xs={12}>
                      <Form.Group className="my-2">
                        <Select
                          isSearchable={false}
                          blurInputOnSelect={true}
                          styles={colourStylesHeader}
                          className="search-line"
                          placeholder="Select Gift Card Amount"
                          value={voucherAmount}
                          options={
                            [
                              { label: `${currencyLabel} 25.00`, value: '25' },
                              { label: `${currencyLabel} 50.00`, value: '50' },
                              { label: `${currencyLabel} 75.00`, value: '75' },
                              { label: `${currencyLabel} 105.00`, value: '100' },
                              { label: 'Custom', value: 'Custom' },

                            ]
                          }
                          onChange={(opt) => {
                            setVoucherAmount(opt);
                          }}
                        />
                      </Form.Group>
                      {voucherAmount?.value === "Custom" &&
                        <Form.Group className="my-4">
                          <InputGroup >
                            <InputGroup.Text id="basic-addon1">
                              <strong className="mx-2">{currencyLabel}</strong>  </InputGroup.Text>
                            <Form.Control
                              className="filterme"
                              required
                              placeholder="Enter Amount"
                              aria-describedby="basic-addon1"
                              value={voucherAmount_custome}
                              onChange={(e) => setvoucherAmount_custome(e.target.value.replace(/[^0-9]/g, ""))}
                            />
                          </InputGroup>
                        </Form.Group>}
                    </Col>
                    {Number(percentage_discount) > 0 ? Number(PayCHF) > 0 &&
                      <React.Fragment>
                        <Col lg={5} md={12} xs={12}>
                          <Card.Title className="common_tittel">Payable Amount ({currencyLabel})</Card.Title>
                          {/* <Card.Subtitle className="mb-2 text-muted common_line">This will be displayed on the Gift card .</Card.Subtitle> */}
                        </Col>
                        <Col lg={7} md={12} xs={12}>
                          <h5 style={{ marginTop: "12px" }}>
                            {Number(PayCHF).toFixed(2)}
                          </h5>

                        </Col>
                      </React.Fragment> : null}
                      {HR}
                    <Col lg={5} md={12} xs={12}>
                      <Card.Title className="common_tittel">Receiver’s Name {START}</Card.Title>
                    </Col>
                    <Col lg={7} md={12} xs={12}>
                      <Form.Group className="my-2">
                        <Form.Control
                          className="text_input_contact"
                          placeholder="Enter your name"
                          aria-describedby="basic-addon1"
                          value={client_name}
                          onChange={(e) => setclient_name(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    {HR}
                    <Col lg={5} md={12} xs={12}>
                      <Card.Title className="common_tittel">Your Message (Optional) </Card.Title>
                    </Col>
                    <Col lg={7} md={12} xs={12}>
                      <Form.Group className="my-2">
                        <Form.Control
                          as="textarea"
                          style={{
                            height: "100px",
                            color: "#5a5a5a",
                            borderRadius: "2px",
                            border: "1px solid rgb(212 209 209)",
                          }}
                          placeholder="Enter your message"
                          value={message?.substring(0, 250)}
                          onChange={(e) => setmessage(e.target.value)}
                        />
                      </Form.Group>
                      {message?.length >= 250 ?
                        <Form.Label style={{ color: message?.length >= 250 ? "red" : "" }} className="message_upto">
                          Message upto 250 characters
                        </Form.Label> :
                        <Form.Label style={{ color: message?.length >= 250 ? "red" : "" }} className="message_upto">
                          Message upto 250 characters  {message?.length > 0 ? ", number of characters remaining" : ""} {message?.length > 0 ? 250 - Number(message?.length) : ""}
                        </Form.Label>}
                    </Col>
                    {HR}
                    <Col lg={5} md={12} xs={12}>
                      <Card.Title className="common_tittel">Receiver's Email {START}</Card.Title>
                    </Col>
                    <Col lg={7} md={12} xs={12}>
                      <Form.Group className="my-2">
                        <Form.Control
                          placeholder="Enter your email"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={client_email}
                          className="text_input_contact"
                          onChange={(e) => setclient_email(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    {HR}
                    <Col lg={5} md={21} xs={12}>
                      <Card.Title className="common_tittel">Receiver's Phone Number {START}</Card.Title>
                    </Col>
                    <Col lg={7} md={12} xs={12}>
                      <Form.Group className="my-2">
                        <Form.Control
                          className="text_input_contact filterme"
                          placeholder="Enter your phone number"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          value={client_mobile_number}
                          onChange={(e) => setclient_mobile_number(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    {HR}
                    <Errormessage error={error} />
                    <Col className="text-center">
                      {loader == false ? (
                        <button
                          style={{
                            borderRadius: 15,
                            backgroundColor:
                              customRes?.button_colour ?? "#dc3545",
                            height: "40px",
                            border: 0,
                          }}
                          className="text-center custom_hover"
                          type="submit"
                        // onClick={() => history.push("/CartPage")}
                        >
                          <strong
                            style={{
                              color:
                                customRes?.non_highlighted_text ?? "white",
                            }}
                          >
                            <span className="buy_gift_card mx-3">Buy Gift Card</span>
                          </strong>
                        </button>
                      ) : (
                        <Box>
                          <CircularProgress size={50} thickness={5} />
                        </Box>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  language: state.branch.language,
  type: state.branch.type,
  branchList: state.branch.branchList,
  branchId: state.branch.branchId,
  branchTime: state.branch.branchTime,
  customRes: state.custom.customRes,
  customUrl: state.custom.customUrl,
  deliverycosterror: state.order.deliverycosterror,
  branchwelcomemessageData: state.branch.branchwelcomemessageData,
  orderData: state.order.orderData,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (language) => dispatch(setLanguage(language)),
    setType: (type) => dispatch(setType(type)),
    setCustome: (url) => dispatch(setCustome(url)),
    getCustomData: () => dispatch(getCustomData()),
    getBranch: () => dispatch(getBranch()),
    getBranchTime: () => dispatch(getBranchTime()),
    setBranchId: (id) => dispatch(setBranchId(id)),
    setBranchName: (name) => dispatch(setBranchName(name)),
    setBranchTimeData: (data) => dispatch(setBranchTimeData(data)),
    setBranchList: (data) => dispatch(setBranchList(data)),
    getMenuList: () => dispatch(getMenuList()),
    clearProductList: () => dispatch(clearProductList()),
    clearChargesData: () => dispatch(clearChargesData()),
    setDeliveryCost: (value) => dispatch(setDeliveryCost(value)),
    getBranchDetails: () => dispatch(getBranchDetails()),
    setOrderCostData: (data) => dispatch(setOrderCostData(data)),
    getOrderCost: () => dispatch(getOrderCost()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewGiftCard);
